import { Body, Box, Button, Heading, Icon } from "@biom3/react";
import Image from "next/image";
import Link from "next/link";
import { Trans, useTranslation } from "react-i18next";

import { MODAL_WIDTH } from "@/constants";
import {
  AddressMismatchError,
  SwitchChainError,
} from "@/hooks/useAssetTransfer";

export const TransferFailureModalContent = ({
  onConfirm,
  error,
}: {
  onConfirm: () => void;
  error: Error;
}) => {
  const { t } = useTranslation();

  const renderHeading = () => {
    if (error instanceof AddressMismatchError) {
      return t("asset_transfer_failure_account_mismatch_title");
    }
    if (error instanceof SwitchChainError) {
      return t("asset_transfer_failure_chain_switch_title");
    }
    return t("weve_hit_a_glitch");
  };

  const imageSrc = () => {
    if (error instanceof SwitchChainError) return "/switchNetwork.png";
    return "/errorState.svg";
  };

  const renderDescription = () => {
    if (error instanceof AddressMismatchError) {
      return t("asset_transfer_failure_account_mismatch_description", {
        walletEnding: (error as AddressMismatchError).wallet?.slice(-5),
      });
    }
    if (error instanceof SwitchChainError) {
      return (
        <Trans
          i18nKey={["asset_transfer_failure_chain_switch_description"]}
          components={{
            networkLink: (
              <Body
                sx={{ color: "base.color.accent.1", textDecoration: "none" }}
                rc={
                  <Link
                    target="_blank"
                    href={
                      "https://docs.immutable.com/docs/zkevm/architecture/chain-config/#network-information"
                    }
                  />
                }
              />
            ),
            linkIcon: (
              <Icon
                icon="JumpTo"
                sx={{
                  width: "base.icon.size.200",
                  position: "relative",
                  top: "3px",
                }}
              />
            ),
          }}
        />
      );
    }
    return <Trans i18nKey="asset_transfer_failure_description" />;
  };

  return (
    <Box
      sx={{
        background: "var(--on-dark-base-color-neutrals-800, #1F1F1F)",
        borderRadius: "base.borderRadius.x8",
        overflow: "hidden",
        width: {
          default: "calc(100dvw - 32px)",
          medium: MODAL_WIDTH,
          large: "480px",
        },
        height: "auto",
      }}
    >
      <Box
        sx={{
          height: "305px",
          d: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Image
          src={imageSrc()}
          alt="Error State"
          width={233}
          height={275}
          fill={false}
        />
      </Box>
      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          background: "base.color.brand.2",
          p: "base.spacing.x7",
        }}
      >
        <Heading size="small">{renderHeading()}</Heading>
        <Body
          sx={{
            fontSize: "base.text.body.medium.regular.fontSize",
            mt: "base.spacing.x4",
            mb: "base.spacing.x6",
          }}
        >
          {renderDescription()}
        </Body>
        <Button
          size="large"
          variant="tertiary"
          onClick={onConfirm}
          sx={{ mt: "base.spacing.x6" }}
        >
          {t("ok_got_it")}
        </Button>
      </Box>
    </Box>
  );
};
