import { getOrderKey } from "@/context";
import type {
  BestOffer,
  Bid,
  Erc20Token,
  ItemDetails,
  Listing,
  PricingDisplayInfo,
  ZkEvmItemV2,
} from "@/types";
import {
  convertTokenAmount,
  getFormattedNetPrice,
  getPrice,
  timeAgo,
} from "@/utils/util";
import type { orderbook } from "@imtbl/sdk";
import { useMemo } from "react";

interface MappedCollectionItem {
  item: ItemDetails;
  isLinkedAsset: boolean;
  balance: number;
  rarity: string | number | undefined;
  pricingInfo: PricingDisplayInfo | undefined;
  listing: Listing | undefined;
  bestOffer: BestOffer | undefined;
}

interface UseMappedCollectionItemsOptions {
  tokens: Map<string, Erc20Token>;
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => string;
  t: (key: string) => string;
  hiddenBidIds?: Set<string>;
  bidEnabled?: boolean;
  activeOrders: Map<string, orderbook.Order[]>;
  walletAddress?: string;
  filterHiddenBids?: (bids: Bid[] | undefined) => Bid[];
}

export const useMappedCollectionItems = (
  items: ItemDetails[],
  options: UseMappedCollectionItemsOptions,
): MappedCollectionItem[] => {
  const {
    tokens,
    getFormattedPrice,
    t,
    hiddenBidIds,
    bidEnabled,
    activeOrders,
    walletAddress,
    filterHiddenBids,
  } = options;

  return useMemo(() => {
    return items.map((item) => {
      const v2Item =
        item.discriminator === "ZKEVM_ITEM_V2"
          ? (item as ZkEvmItemV2)
          : undefined;

      let pricingInfo: PricingDisplayInfo | undefined;
      let listing: Listing | undefined;
      let bestOffer: BestOffer | undefined;
      let rarity: string | number | undefined;

      if (v2Item) {
        rarity = v2Item.metadata?.rarity;
        pricingInfo = {
          floorPrice:
            getPrice(
              v2Item.marketData?.floor_listing,
              tokens,
              getFormattedPrice,
            ) ?? "",
          lastSoldDate: v2Item.marketData?.last_sold
            ? timeAgo(t, v2Item.marketData.last_sold.date)
            : undefined,
          lastSoldPrice: v2Item.marketData?.last_sold
            ? getPrice(
                v2Item.marketData.last_sold.pricing_data,
                tokens,
                getFormattedPrice,
              )
            : undefined,
        };
      }

      const isERC1155 = item.tokenType === "ERC1155";
      if (v2Item?.bids && v2Item.bids.length > 0 && bidEnabled) {
        const visibleBids = filterHiddenBids
          ? filterHiddenBids(v2Item.bids)
          : v2Item.bids.filter((bid) => !hiddenBidIds?.has(bid.id));

        if (visibleBids.length > 0) {
          let bestBid: Bid;

          // For ERC1155 pick bid with the highest unit price
          if (isERC1155) {
            bestBid = visibleBids.reduce((prevBid, currentBid) => {
              const prevUnitPrice = BigInt(prevBid.unit_price ?? "0");
              const currentUnitPrice = BigInt(currentBid.unit_price ?? "0");
              return currentUnitPrice > prevUnitPrice ? currentBid : prevBid;
            });
          } else {
            bestBid = visibleBids[0];
          }

          const token = tokens.get(bestBid.token_address);
          const tokenAmount = convertTokenAmount(
            bestBid.amount,
            token?.decimals ?? 18,
          );

          bestOffer = {
            id: bestBid.id,
            offeredPrice: token
              ? `${tokenAmount.isApproximate ? "~" : ""}${token.symbol} ${
                  tokenAmount.value
                }`
              : bestBid.amount,
            fiatPrice: getFormattedPrice(
              token?.symbol,
              token?.decimals,
              bestBid.amount,
            ),
            netPrice: getFormattedNetPrice(bestBid, tokens),
          };
        }
      }

      const orders =
        activeOrders.get(getOrderKey(item.collection, item.tokenId)) ?? [];
      if (orders.length > 0) {
        const order = orders[0];
        if (order.type === "LISTING") {
          const token =
            order.buy[0].type === "NATIVE"
              ? { decimals: 18, symbol: "IMX" }
              : tokens.get(order.buy[0].contractAddress);
          const tokenAmount = convertTokenAmount(
            order.buy[0].amount,
            token?.decimals ?? 18,
          );
          listing = {
            id: order.id,
            askingPrice: token
              ? `${tokenAmount.isApproximate ? "~" : ""}${token.symbol} ${
                  tokenAmount.value
                }`
              : order.buy[0].amount,
            fiatPrice: token
              ? getFormattedPrice(
                  token.symbol,
                  token.decimals,
                  order.buy[0].amount,
                )
              : "",
          };
        }
      }

      const isLinkedAsset = item.owner !== walletAddress;
      const balance = item.balance ? Number.parseInt(item.balance) : 1;

      return {
        item,
        isLinkedAsset,
        balance,
        rarity,
        pricingInfo,
        listing,
        bestOffer,
      };
    });
  }, [
    items,
    tokens,
    getFormattedPrice,
    t,
    hiddenBidIds,
    bidEnabled,
    activeOrders,
    walletAddress,
    filterHiddenBids,
  ]);
};
