import { BAD_IMAGE_URL } from "@/constants";
import { useAnalytics } from "@/context";
import { useAssetImport } from "@/context/AssetImportProvider";
import { useFetchWallets } from "@/hooks";
import { useValidConnectors } from "@/hooks/useValidConnectors";
import {
  type ChainAddress,
  type ItemDetails,
  type TokenID,
  type Wallet,
  WalletTypes,
} from "@/types";
import { Box, Checkbox, MenuItem, Modal, Stack } from "@biom3/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserRejectedRequestError } from "viem";
import { useAccount, useConnect } from "wagmi";
import { TransferFailureModalContent, TransferModal } from "../asset-transfer";
import { SelectConnectorModal } from "../asset-transfer/SelectConnectorModal";
import { ImportToolbar } from "./ImportToolbar";

export type ItemSelection = Map<TokenID, ItemDetails>;

const ZK_MAX_ITEMS = Number.MAX_SAFE_INTEGER;
const IMX_MAX_ITEMS = 100;

type ImportCollectionItems = {
  address: ChainAddress | undefined;
  items: ItemDetails[] | undefined;
  network: "starkEx" | "zkEvm" | undefined;
  onSelectionCountChange?: (count: number) => void;
};
export const ImportCollectionItems = ({
  address,
  items,
  network,
  onSelectionCountChange,
}: ImportCollectionItems) => {
  const { track } = useAnalytics();
  const { endImport, activeImport } = useAssetImport();
  const { back } = useRouter();
  const [selectionState, setSelectionState] = useState<ItemSelection>(
    new Map<TokenID, ItemDetails>(),
  );
  const allItemsSelected = useMemo(
    () => selectionState.size !== 0 && selectionState.size === items?.length,
    [selectionState, items],
  );
  const selectionMaxItems = useMemo(
    () => (network === "starkEx" ? IMX_MAX_ITEMS : ZK_MAX_ITEMS),
    [network],
  );

  useEffect(() => {
    onSelectionCountChange?.(selectionState.size);
  }, [onSelectionCountChange, selectionState]);

  const { data: wallets } = useFetchWallets();
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [selectConnectorModalVisible, setSelectConnectorModalVisible] =
    useState(false);
  const { onlyWalletConnectAvailable, validConnectors } = useValidConnectors();
  const [autoConnectError, setAutoConnectError] = useState<Error | null>(null);
  const { connectors, connectAsync } = useConnect();
  const { isConnected } = useAccount();
  const source = useMemo(
    () =>
      wallets?.find(
        (wallet: Wallet) =>
          wallet.address === activeImport?.importAddress &&
          wallet.type !== WalletTypes.PASSPORT,
      ),
    [wallets, activeImport],
  );

  const connector = useMemo(
    () =>
      source
        ? connectors.find((connector) => source.type === connector.id)
        : undefined,
    [source, connectors],
  );

  const [activeConnector, setActiveConnector] = useState(connector);

  const selectAllClick = useCallback(() => {
    if (allItemsSelected) {
      track({
        screen: "Collection",
        userJourney: "AssetTransfer",
        action: "Pressed",
        controlType: "Button",
        control: "DeselectAll",
        extras: {
          flowId: activeImport?.trackingId,
        },
      });
      setSelectionState(new Map<TokenID, ItemDetails>());
    } else {
      track({
        screen: "Collection",
        userJourney: "AssetTransfer",
        action: "Pressed",
        controlType: "Button",
        control: "SelectAll",
        extras: {
          flowId: activeImport?.trackingId,
        },
      });
      const selectedItems = new Map<TokenID, ItemDetails>();
      for (const item of items || []) {
        if (selectedItems.size >= selectionMaxItems) break;
        selectedItems.set(item.tokenId, item);
      }
      setSelectionState(selectedItems);
    }
  }, [track, allItemsSelected, activeImport, items, selectionMaxItems]);

  const handleSelectionChange = (item: ItemDetails) => {
    const selectedItems = new Map(selectionState);
    if (selectedItems.has(item.tokenId)) {
      track({
        screen: "Collection",
        userJourney: "AssetTransfer",
        action: "Pressed",
        controlType: "Checkbox",
        control: "DeselectItem",
        extras: {
          flowId: activeImport?.trackingId,
        },
      });
      selectedItems.delete(item.tokenId);
    } else if (selectedItems.size < selectionMaxItems) {
      track({
        screen: "Collection",
        userJourney: "AssetTransfer",
        action: "Pressed",
        controlType: "Checkbox",
        control: "SelectItem",
        extras: {
          flowId: activeImport?.trackingId,
        },
      });
      selectedItems.set(item.tokenId, item);
    }
    setSelectionState(selectedItems);
  };

  const connectWalletConnect = useCallback(async () => {
    try {
      const connector = validConnectors[0];
      if (!isConnected) {
        await connectAsync({ connector });
      }
      setActiveConnector(connector);
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error instanceof UserRejectedRequestError) {
          console.error("User rejected the connection request");
        } else {
          setAutoConnectError(error);
          console.error("Error connecting to wallet:", error);
        }
      }
    }
  }, [validConnectors, connectAsync, isConnected]);

  return (
    <Box sx={{ d: "flex", flexDirection: "column", flex: 1 }}>
      <ImportToolbar
        allSelected={allItemsSelected}
        importCount={selectionState.size > 0 ? selectionState.size : undefined}
        cancelClick={() => {
          track({
            screen: "Collection",
            userJourney: "AssetTransfer",
            action: "Pressed",
            controlType: "Button",
            control: "Cancel",
            extras: {
              flowId: activeImport?.trackingId,
            },
          });
          endImport?.();
          back();
        }}
        importClick={async () => {
          track({
            screen: "Collection",
            userJourney: "AssetTransfer",
            action: "Pressed",
            controlType: "Button",
            control: "Import",
            extras: {
              itemCount: selectionState.size,
              flowId: activeImport?.trackingId,
            },
          });
          if (activeConnector) {
            setImportModalVisible(true);
          } else if (onlyWalletConnectAvailable) {
            await connectWalletConnect();
            setImportModalVisible(true);
          } else {
            setSelectConnectorModalVisible(true);
          }
        }}
        selectAllClick={selectAllClick}
      />
      <Stack>
        {items?.map((item, index) => (
          <MenuItem
            key={index || item.tokenId}
            onClick={() => handleSelectionChange(item)}
            selected={selectionState.has(item.tokenId)}
            sx={{
              height: "72px",
              background: "base.color.translucent.emphasis.100",
            }}
          >
            <MenuItem.Label>{item.name}</MenuItem.Label>
            <MenuItem.FramedImage imageUrl={item.image ?? BAD_IMAGE_URL} />
            <Checkbox
              checked={selectionState.has(item.tokenId)}
              sx={{ mr: "base.spacing.x6" }}
            />
          </MenuItem>
        ))}
      </Stack>
      {address && activeConnector && source && (
        <TransferModal
          onCloseModal={() => {
            setImportModalVisible(false);
          }}
          collectionAddress={address}
          visible={importModalVisible}
          items={Array.from(selectionState.values())}
          source={source}
          connector={activeConnector}
        />
      )}
      {!connector && (
        <SelectConnectorModal
          visible={selectConnectorModalVisible}
          onClose={() => {
            setSelectConnectorModalVisible(false);
            setImportModalVisible(true);
          }}
          setConnector={setActiveConnector}
        />
      )}
      {autoConnectError && (
        <Modal visible={!!autoConnectError}>
          <Modal.Content>
            <TransferFailureModalContent
              onConfirm={() => setAutoConnectError(null)}
              error={autoConnectError}
            />
          </Modal.Content>
        </Modal>
      )}
    </Box>
  );
};
