import { useWindowSizeStore } from "@biom3/react";

import { breakpoint } from "@/pages/_app";

const useScreenWidth = () => {
  const { state: width } = useWindowSizeStore((store) => store.width);
  return width;
};

export function useIsSmallScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < breakpoint.medium;
}

export function useIsMediumScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < breakpoint.large;
}
