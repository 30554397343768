import { Box } from "@biom3/react";
import type { ComponentProps, ReactNode } from "react";

export function RoundedBox({
  children,
  testId = "rounded-box",
  sx,
}: {
  testId?: string;
  children: ReactNode;
  sx?: ComponentProps<typeof Box>["sx"];
}) {
  return (
    <Box
      testId={testId}
      sx={{
        borderRadius: "16px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
