import { Box, Heading, MenuItem } from "@biom3/react";
import Image from "next/image";
import { useTranslation } from "react-i18next";

import { appConfig } from "@/constants";
import { useAnalytics } from "@/context";
import type { Game, Marketplace } from "@/types";

import { ActionModal } from "../modal/ActionModal";

export type MarketplaceModalOrigin = "Buy" | "Sell" | "EmptyState" | "ItemList";

type ModalProps = {
  visible: boolean;
  origin: MarketplaceModalOrigin;
  dismissModal: () => void;
  game?: Game;
  address?: string;
  collectionName?: string;
  network?: string;
};

const marketplaces: Marketplace[] = [
  {
    name: "Token Trove",
    url: "https://tokentrove.com",
    caption: "Immutable zkEVM and Immutable X",
    imageUrl: `${appConfig.ASSET_BASE_URL}/marketplaces/token-trove.svg`,
  },
  {
    name: "AtomicHub",
    url: "https://imx-zkevm.atomichub.io/market?blockchain=imx-zkevm-mainnet&order=desc&sort=created&symbol=IMX%2CETH%2CUSDC#sales",
    caption: "Immutable zkEVM and X",
    imageUrl: `${appConfig.ASSET_BASE_URL}/marketplaces/atomic-hub.svg`,
  },
  {
    name: "Sphere",
    url: "https://sphere.market/immutable",
    caption: "Immutable zkEVM only",
    imageUrl: `${appConfig.ASSET_BASE_URL}/marketplaces/sphere.svg`,
  },
];
const getMarketplaceUrl = (
  marketplace: Marketplace,
  isIMX: boolean,
  game?: Game,
  collectionName?: string,
  address?: string,
) => {
  const environment = appConfig.ENVIRONMENT;
  if (environment === "sandbox") {
    return marketplace.url;
  }

  const query = encodeURIComponent(
    game?.context?.searchQuery?.toLowerCase() ||
      collectionName?.toLowerCase() ||
      "",
  );

  switch (marketplace.name) {
    case "Token Trove":
      return game?.context?.tokenTroveUri ?? marketplace.url;
    case "Sphere":
      return query
        ? `https://sphere.market/search?q=${query}&chain=immutable`
        : address
          ? `https://sphere.market/immutable/collection/${address}`
          : marketplace.url;
    case "AtomicHub": {
      const blockchain = isIMX ? "imx-mainnet" : "imx-zkevm-mainnet";
      return query
        ? `https://atomichub.io/explorer?search=${query}&blockchain=${blockchain}`
        : marketplace.url;
    }
    default:
      return marketplace.url;
  }
};

const MarketPlaceModal = ({
  visible,
  origin,
  dismissModal,
  game,
  network,
  address,
  collectionName,
}: ModalProps) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();

  const isIMX =
    game?.context?.network === "imx-mainnet" || network === "starkEx";

  const closeModal = () => {
    track({
      userJourney: "Collections",
      screen: "MarketPlaceModal",
      control: "Click",
      controlType: "Button",
      action: "Pressed",
    });
    dismissModal();
  };

  const openMarketplace = (marketplace: Marketplace) => {
    const url = getMarketplaceUrl(
      marketplace,
      isIMX,
      game,
      collectionName,
      address,
    );

    if (url) {
      window.open(url, "_blank");
    }

    track({
      userJourney: "Collections",
      screen: `${origin}MarketPlace`,
      control: "Click",
      controlType: "Button",
      action: "Pressed",
      extras: {
        url,
        showingGameContext: !!game,
        gameName: game?.name,
      },
    });
    dismissModal();
  };

  return (
    <ActionModal isModalOpen={visible} onClose={closeModal}>
      <Heading weight="bold" sx={{ px: "base.spacing.x3" }}>
        {t("marketplace_buy_sell")}
      </Heading>
      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          gap: "base.spacing.x2",
        }}
      >
        {marketplaces
          .filter((marketplace) => marketplace.name !== "Sphere" || !isIMX)
          .map((marketplace) => (
            <MenuItem
              key={marketplace.name}
              emphasized
              size={["xSmall", "xSmall", "small"]}
              onClick={() => openMarketplace(marketplace)}
            >
              <MenuItem.FramedImage
                use={
                  <Image
                    src={marketplace.imageUrl}
                    alt={marketplace.name}
                    width={48}
                    height={48}
                  />
                }
              />
              <MenuItem.Label>{marketplace.name}</MenuItem.Label>
              <MenuItem.Caption>{marketplace.caption}</MenuItem.Caption>
              <MenuItem.IntentIcon icon="JumpTo" />
            </MenuItem>
          ))}
      </Box>
    </ActionModal>
  );
};

export { MarketPlaceModal, type ModalProps };
