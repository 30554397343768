import {
  AspectRatioImage,
  Body,
  ButtCon,
  Button,
  CountdownTimer,
  Heading,
  Stack,
} from "@biom3/react";
import type { AnalyticsType } from "@cms/context";
import type { Content } from "@prismicio/client";
import NextLink from "next/link";

type PromotionTileProps = {
  game: Content.GameDocument;
  countdown?: string | null;
  imageUrl?: string | null;
  title?: string | null;
  caption?: string | null;
  description?: string | null;
  primaryCta?: { url: string; text?: string; target?: string };
  secondaryCta?: { url: string; text?: string; target?: string };
  shareCta?: { url: string; target?: string };
  analytics: AnalyticsType;
};
export function PromotionTile({
  game,
  countdown,
  imageUrl,
  title,
  caption,
  description,
  primaryCta,
  secondaryCta,
  shareCta,
  analytics,
}: PromotionTileProps) {
  const onShareClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "PromotionShareClicked",
      controlType: "Button",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        shareUrl: shareCta?.url,
        shareTarget: shareCta?.target,
      },
    });
  };

  const onSecondaryCtaClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "PromotionSecondaryClicked",
      controlType: "Button",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        secondaryUrl: secondaryCta?.url,
        secondaryTarget: secondaryCta?.target,
        secondaryText: secondaryCta?.text,
      },
    });
  };

  const onPrimaryCtaClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "PromotionPimaryClicked",
      controlType: "Button",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        primaryUrl: primaryCta?.url,
        primaryTarget: primaryCta?.target,
        primaryText: primaryCta?.text,
      },
    });
  };

  const withSingleCta =
    (primaryCta?.text && !secondaryCta?.text) ||
    (!primaryCta?.text && secondaryCta?.text);

  return (
    <Stack
      direction={{
        default: "column",
        large: "row",
      }}
      alignItems="stretch"
      sx={{
        p: "base.spacing.x4",
        bg: "base.color.translucent.emphasis.100",
        backdropFilter: "base.frost.400",
        brad: "base.borderRadius.x10",
      }}
      gap="base.spacing.x8"
    >
      <Stack sx={{ pos: "relative" }}>
        {imageUrl && (
          <AspectRatioImage
            imageUrl={imageUrl}
            aspectRatio="4/3"
            responsiveSizes={[640]}
            relativeImageSizeInLayout="334px"
            sx={{ brad: "base.borderRadius.x6", w: "100%", h: "100%" }}
          />
        )}
        {countdown && (
          <CountdownTimer
            futureTarget={new Date(countdown)}
            variant="inverse"
            sx={{
              pos: "absolute",
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          />
        )}
      </Stack>
      <Stack sx={{ flex: 1 }} gap="base.spacing.x6">
        <Stack sx={{ minh: "base.spacing.x35" }} gap="0px">
          <Heading
            // biome-ignore lint/a11y/useHeadingContent: <explanation>
            rc={<h4 />}
            size="medium"
            weight="bold"
            sx={{ pr: "base.spacing.x8" }}
          >
            {title}
          </Heading>
          {caption && (
            <Body sx={{ c: "base.color.text.body.secondary" }}>{caption}</Body>
          )}
          {description && (
            <Body sx={{ mt: "base.spacing.x3" }}>{description}</Body>
          )}
        </Stack>

        <Stack
          gap="base.spacing.x4"
          justifyContent="space-between"
          direction={{ default: "column", large: "row" }}
          sx={{
            mt: "auto",
            ...(withSingleCta ? { justifyContent: "flex-end" } : {}),
          }}
        >
          {secondaryCta?.text && (
            <Button
              variant="tertiary"
              sx={{
                ...(withSingleCta
                  ? {
                      flex: { default: 1, xxLarge: 0.5 },
                    }
                  : { flex: { default: "1 1 auto", large: "1 1 0%" } }),
              }}
              rc={
                <NextLink
                  onClick={onSecondaryCtaClick}
                  target={secondaryCta.target}
                  href={{ pathname: secondaryCta.url }}
                  referrerPolicy="no-referrer"
                />
              }
            >
              {secondaryCta.text}
            </Button>
          )}
          {primaryCta?.text && (
            <Button
              variant="secondary"
              sx={{
                ...(withSingleCta
                  ? {
                      flex: { default: 1, xxLarge: 0.5 },
                    }
                  : { flex: { default: "1 1 auto", large: "1 1 0%" } }),
              }}
              rc={
                <NextLink
                  onClick={onPrimaryCtaClick}
                  target={primaryCta?.target}
                  href={{ pathname: primaryCta.url }}
                  referrerPolicy="no-referrer"
                />
              }
            >
              {primaryCta.text}
            </Button>
          )}
        </Stack>

        {shareCta && (
          <ButtCon
            sx={{
              pos: "absolute",
              top: "base.spacing.x4",
              right: "base.spacing.x4",
            }}
            icon="SocialShare"
            variant="tertiary"
            size="small"
            rc={
              <NextLink
                href={{ pathname: shareCta.url }}
                onClick={onShareClick}
                target={shareCta.target}
                referrerPolicy="no-referrer"
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
}
