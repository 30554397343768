import { Box, ButtCon, Stack, useGetSubcomponentChild } from "@biom3/react";
import { useRouter } from "next/router";
import type { ReactNode } from "react";

import { AppToolbarLeftSlot } from "@/components/core/AppToolbarLeftSlot";
import { AppToolbarRightSlot } from "@/components/core/AppToolbarRightSlot";
import { useIsMediumScreen } from "@/hooks";

const AppToolbar = ({
  children,
  showBackBtn,
}: {
  children?: ReactNode;
  showBackBtn?: boolean;
}) => {
  const router = useRouter();
  const isMediumScreenMode = useIsMediumScreen();

  const leftSlot = useGetSubcomponentChild(children, AppToolbarLeftSlot);
  const rightSlot = useGetSubcomponentChild(children, AppToolbarRightSlot);
  const stackFlexDir = isMediumScreenMode ? "column" : "row";

  return (
    <Box
      testId="AppToolbar"
      sx={{
        top: "0px",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          d: "flex",
          flexShrink: 0,
          flexDirection: "column",
          overflow: "hidden",
          pos: "relative",
        }}
      >
        <Stack
          sx={{
            flexDirection: stackFlexDir,
            justifyContent: "space-between",
            alignItems: isMediumScreenMode ? "start" : "center",
            pb: "base.spacing.x4",
            gap: "base.spacing.x4",
          }}
        >
          {showBackBtn && (
            <ButtCon
              icon="ArrowBackward"
              variant="tertiary"
              size="small"
              onClick={() => router.back()}
            />
          )}
          {leftSlot}
          {rightSlot}
        </Stack>
      </Box>
    </Box>
  );
};

export { AppToolbar, AppToolbarLeftSlot, AppToolbarRightSlot };
