import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { appConfig } from "@/constants";
import type { WalletGems, WalletGemsResponse } from "@/types";

const getWalletGems = async (walletAddress: string) => {
  const res = await axios.get(
    `${appConfig.IMMUTABLE_BASE_URI}/v1/rewards/gems/${walletAddress}`,
  );
  return res.data as WalletGemsResponse;
};

export const useGetWalletGems = (walletAddress: string | undefined) =>
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  useQuery<any, any, WalletGems, any>({
    queryKey: ["wallet/gems/", walletAddress],
    refetchOnWindowFocus: true,
    enabled: !!walletAddress,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    queryFn: () => getWalletGems(walletAddress!),
    select: (data): WalletGems => ({
      lastGemGameClaim: new Date(data.result.last_gem_game_claim),
      gems: data.result.gems,
      walletAddress: data.result.wallet_address,
    }),
  });
