import type { RecommendedQuestType } from "@components/RecommendedQuests/RecommendedQuests";
import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";

type Layer3Flag =
  | { questLink: string; endDate?: string; enabled: true }
  | { enabled: false };

type RecommmendedQuestsFlag =
  | {
      enabled: true;
      quests: RecommendedQuestType[];
    }
  | { enabled: false };

export type BooleanFlag =
  | "testFlag"
  | "useDataDrivenMainQuests"
  | "wishlist"
  | "questAndGemsPage";

type BooleanFlags = Record<BooleanFlag, boolean | undefined>;

// Flag values are undefined until they've been loaded
export type Flags = {
  testLayer3Flag: Layer3Flag | undefined;
  gemsPoolMastersLayer3Neww: Layer3Flag | undefined;
  gemsRecommendedQuests: RecommmendedQuestsFlag | undefined;
} & BooleanFlags;

export function useFlag<T extends keyof Flags>(flag: T): Flags[T] {
  const flags = useLDFlags<Flags>();
  return flags[flag];
}
