import { Box, Stack, useTheme } from "@biom3/react";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";

import { Header, SideMenu } from "@/components";
import { type DashboardAnalyticsJourneyType, useAnalytics } from "@/context";
import { useAssetImport } from "@/context/AssetImportProvider";
import { useIsSmallScreen } from "@/hooks";

type TrackingEvent = {
  screen: string;
  userJourney: DashboardAnalyticsJourneyType;
  extras?: Record<string, unknown> | undefined;
};

type TrackingEventRef = {
  [K in keyof TrackingEvent]: TrackingEvent[K] | null;
};

type Props = PropsWithChildren & TrackingEvent;

export function Layout({ children, screen, extras, userJourney }: Props) {
  const { base } = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const isSmallScreenMode = useIsSmallScreen();
  const { page } = useAnalytics();
  const { endImport } = useAssetImport();

  const menuRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<TrackingEventRef>({
    screen: null,
    userJourney: null,
    extras: null,
  });

  const isMenuClicked = (event: MouseEvent) =>
    menuRef?.current?.contains(event.target as Node);
  const isHeaderClicked = (event: MouseEvent) =>
    headerRef?.current?.contains(event.target as Node);

  useEffect(() => {
    const ref = trackRef.current;
    const isNewPage = screen !== ref.screen || userJourney !== ref.userJourney;

    if (isNewPage) {
      page({ screen, userJourney, extras });
      trackRef.current = {
        screen,
        userJourney,
      };
    }
  }, [page, screen, userJourney, extras]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleClickListener = (event: MouseEvent) => {
      if (!isMenuClicked(event) && !isHeaderClicked(event)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  }, []);

  return (
    <Box testId="Layout" sx={{ h: "100%" }}>
      <Stack
        gap="0px"
        sx={{
          h: "100%",
          m: "0 auto",
          pos: "relative",
          bg: "base.color.translucent.inverse.800",
          overflow: "hidden",
          pt: {
            default: `calc(80px + ${base.spacing.x2})`,
            medium: `calc(80px + ${base.spacing.x4})`,
          },
        }}
        testId="Layout__innerContainer"
      >
        <Header
          domRef={headerRef}
          onMenuClick={() => {
            setMenuVisible(!menuVisible);
          }}
          onTitleClick={() => {
            setMenuVisible(false);
          }}
        />
        <Box
          sx={{
            flex: 1,
            d: "flex",
            flexDirection: ["column", "row"],
            h: "100%",
          }}
          testId="Layout__innerContainer__content"
        >
          {(menuVisible || !isSmallScreenMode) && (
            <SideMenu
              fullWidth={isSmallScreenMode}
              domRef={menuRef}
              onItemClick={() => {
                endImport?.();
                setMenuVisible(false);
              }}
            />
          )}
          <Stack
            sx={{
              d: menuVisible && isSmallScreenMode ? "none" : "flex",
              flexGrow: 1,
              px: {
                default: "base.spacing.x2",
                medium: "base.spacing.x4",
              },
              overflow: "auto",
            }}
            direction="row"
            gap="0px"
            testId="Layout__innerContainer__content__inner"
          >
            {children}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
