import { Box, type DeeplyNestedSx } from "@biom3/react";
import type { ReactNode } from "react";

type ModalContentWrapperProps = {
  children?: ReactNode;
  sx?: DeeplyNestedSx;
};

export const ModalContentLayout = ({
  children,
  sx,
}: ModalContentWrapperProps) => (
  <Box
    sx={{
      ...{
        display: "flex",
        flexDirection: "column",
        p: "base.spacing.x6",
        gap: "var(--spacing-x-8, 32px)",
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);
