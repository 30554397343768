import type { Metadata, URL } from "@/types";

interface ItemResponseJSON {
  balance: string;
  collection_name: string;
  created_at: string;
  image: string;
  image_url: URL;
  description: string;
  metadata: Record<string, string | number | boolean | string[]>;
  name: string;
  network: string;
  owner: string;
  status: string;
  token_id: string;
  token_type: string;
  updated_at: string;
}

export const filteredMetadataAttributes = [
  "name",
  "description",
  "image_url",
  "image",
];

export const itemMetadataTransformer = (
  data: Partial<ItemResponseJSON>,
): Metadata[] => {
  const metadata: Metadata[] = [];

  if (data.metadata && data.metadata !== undefined) {
    Object.keys(data.metadata).map((key: string) => {
      if (
        !filteredMetadataAttributes.includes(key) &&
        Object.keys(data?.metadata || {}).includes(key)
      ) {
        const metadataValue = data?.metadata?.[key];
        if (Array.isArray(metadataValue)) {
          metadata.push({ key: key, value: metadataValue.join(", ") });
        } else if (typeof metadataValue !== "object") {
          switch (key.toLowerCase()) {
            case "rarity":
              metadata.unshift({ key: key, value: metadataValue });
              break;
            default:
              metadata.push({ key: key, value: metadataValue });
              break;
          }
        }
      }
    });
  }
  return metadata;
};
