import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { generateNonce } from "siwe";
import {
  type Connector,
  useConnect,
  useSignTypedData,
  useSwitchChain,
} from "wagmi";
import { walletConnect } from "wagmi/connectors";

import { useAnalytics, usePassportProvider } from "@/context";
import { useAccessToken } from "@/hooks/useAccessToken";
import {
  type LinkWalletErrorCode,
  type Wallet,
  WalletTypes,
  type Wallets,
} from "@/types";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
} from "@/utils/request";
import { toSimpleAddressFormat } from "@/utils/util";

const convertWalletType = (type: string): WalletTypes => {
  switch (type) {
    case "MetaMask":
    case "metaMaskSDK":
      return WalletTypes.METAMASK;
    case "Passport":
      return WalletTypes.PASSPORT;
    default:
      return type as WalletTypes;
  }
};

export class LinkError extends Error {
  connectedAddress: `0x${string}` | undefined;
  code: LinkWalletErrorCode | undefined;

  constructor(
    msg: string,
    connectedAddress: `0x${string}` | undefined,
    code: LinkWalletErrorCode,
  ) {
    super(msg);
    this.connectedAddress = connectedAddress;
    this.code = code;
    Object.setPrototypeOf(this, LinkError.prototype);
  }
}

export function useFetchWallets(isEnabled = true): {
  data: Wallet[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const getAccessToken = useAccessToken();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, Wallet[], any>({
    queryKey: ["wallets"],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return [];
      return authorizedGet("/passport-profile/v1/wallets", accessToken);
    },
    enabled: isEnabled,
    select: (data) =>
      data.map((wallet: Wallets) => {
        // legacy wallet types
        const walletType = convertWalletType(wallet.type);
        const res = {
          address: wallet.address,
          name: wallet.name
            ? wallet.name
            : `Wallet ${toSimpleAddressFormat(wallet.address)}`,
          type: walletType,
        };
        return res;
      }),
  });
}

export function useLinkWallet() {
  const { connectAsync } = useConnect();
  const { switchChainAsync } = useSwitchChain();
  const { walletAddress } = usePassportProvider();
  const { signTypedDataAsync } = useSignTypedData();
  const queryClient = useQueryClient();
  const getAccessToken = useAccessToken();
  const { track } = useAnalytics();

  const link = async ({
    connector,
    linkedWallets,
    onLinkingRequest,
  }: {
    connector: Connector;
    linkedWallets?: Wallet[];
    onLinkingRequest: () => void;
  }) => {
    if (!walletAddress)
      return Promise.reject(
        new LinkError(
          "No Passport wallet address available",
          undefined,
          "UNAUTHORISED",
        ),
      );
    let userAddress: `0x${string}` | undefined = undefined;
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let chainId;
    try {
      const connectResult = await connectAsync({ connector });
      userAddress = connectResult.accounts[0];
      chainId = connectResult.chainId;
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.name === "ConnectorAlreadyConnectedError") {
          const accounts = await connector.getAccounts();
          userAddress = accounts[0];
          chainId = await connector.getChainId();
        } else {
          return Promise.reject(
            new LinkError(e.message, undefined, "CONNECT_FAILED"),
          );
        }
      }
    }
    if (
      linkedWallets?.find(
        (wallet) =>
          wallet.address.toLocaleLowerCase() ===
          userAddress?.toLocaleLowerCase(),
      )
    ) {
      return Promise.reject(
        new LinkError("Wallet already linked.", undefined, "ALREADY_LINKED"),
      );
    }

    try {
      if (chainId !== 1) {
        track({
          screen: "Linking",
          userJourney: "Wallets",
          control: "SwitchChain",
          controlType: "Effect",
          extras: { chainId: chainId },
        });
        // There is a bug with WalletConnect (and MetaMask mobile) when switching chain that prevents follow-up requests
        // from emitting a result e.g. signature further down. Ask for a manual chain change instead.
        if (connector.type === walletConnect.type) {
          return Promise.reject(
            new LinkError(
              "Switch chain for WalletConnect.",
              undefined,
              "NETWORK_FAILED",
            ),
          );
        }
        await switchChainAsync({ connector, chainId: 1 });
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(
        new LinkError("Failed to switch chain.", undefined, "NETWORK_FAILED"),
      );
    }

    const nonce = generateNonce();
    let sig: string;
    try {
      sig = await signTypedDataAsync({
        types: {
          EIP712Domain: [
            {
              name: "chainId",
              type: "uint256",
            },
          ],
          LinkWallet: [
            {
              name: "walletAddress",
              type: "address",
            },
            {
              name: "immutablePassportAddress",
              type: "address",
            },
            {
              name: "condition",
              type: "string",
            },
            {
              name: "nonce",
              type: "string",
            },
          ],
        },
        primaryType: "LinkWallet",
        domain: {
          chainId: BigInt(1),
        },
        message: {
          walletAddress: userAddress ?? ("" as `0x${string}`),
          immutablePassportAddress: walletAddress,
          condition:
            "I agree to link this wallet to my Immutable Passport account.",
          nonce: nonce,
        },
        connector,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e);
        if (
          connector.type === walletConnect.type &&
          e.name === "SwitchChainError"
        ) {
          return Promise.reject(
            new LinkError(
              "Your wallet is currently connected to another network. Please switch networks to link your wallet.",
              userAddress,
              "NETWORK_FAILED",
            ),
          );
        }
      }

      return Promise.reject(
        new LinkError(
          "Unable to get signature.",
          userAddress,
          "SIGNATURE_UNVERIFIED",
        ),
      );
    }

    onLinkingRequest();

    const accessToken = await getAccessToken();
    if (!accessToken)
      return Promise.reject(
        new LinkError("Invalid access token", undefined, "UNAUTHORISED"),
      );

    return authorizedPost(
      "/passport-profile/v2/linked-wallets",
      {
        type: connector.id,
        signature: sig,
        wallet_address: userAddress,
        nonce,
      },
      accessToken,
    ).catch((err) =>
      Promise.reject(
        new LinkError(err.message, userAddress, err.response?.data?.code),
      ),
    );
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, any, any, any>({
    mutationFn: link,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
    },
  });
}

export function useUnlinkWallet() {
  const getAccessToken = useAccessToken();
  const queryClient = useQueryClient();

  const unlink = async (wallet: string | undefined) => {
    const accessToken = await getAccessToken();
    if (wallet === undefined || accessToken === undefined) return;
    return authorizedDelete(
      `/passport-profile/v1/linked-wallets/${wallet}`,
      accessToken,
    );
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, any, any, any>({
    mutationFn: unlink,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
    },
  });
}

type MutationParams = {
  address: string | undefined;
  name: string;
};

export function useNameWallet() {
  const getAccessToken = useAccessToken();
  const queryClient = useQueryClient();

  const editName = async (req: MutationParams) => {
    const accessToken = await getAccessToken();
    if (req.address === undefined || accessToken === undefined) return;
    return authorizedPatch(
      `/passport-profile/v1/linked-wallets/${req.address}`,
      { name: req.name },
      accessToken,
    );
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, any, any, any>({
    mutationFn: editName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
    },
  });
}
