import { useImmutableProvider } from "@/context";
import { appConfig } from "@constants";
import { blockchainData, checkout, config, type passport } from "@imtbl/sdk";
import { blockchainDataOverrides } from "imtbl-sdk-dev-env";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

type SdkModuleContext = {
  blockchainData: blockchainData.BlockchainData;
  checkout: checkout.Checkout;
  passport: passport.Passport;
};

const baseConfig = new config.ImmutableConfiguration({
  environment: appConfig.IMMUTABLE_SDK_ENVIRONMENT,
});

export const SdkModuleContext = createContext<SdkModuleContext | undefined>(
  undefined,
);

export const SdkModuleProvider = ({ children }: PropsWithChildren) => {
  const { passportClient } = useImmutableProvider();
  const [blockchainDataModule] = useState<SdkModuleContext["blockchainData"]>(
    new blockchainData.BlockchainData({
      baseConfig,
      overrides:
        appConfig.CHAIN_NAME === "imtbl-zkevm-devnet"
          ? blockchainDataOverrides()
          : undefined,
    }),
  );

  const [checkoutModule] = useState<SdkModuleContext["checkout"]>(
    new checkout.Checkout({
      baseConfig,
      bridge: { enable: false },
      onRamp: { enable: true },
      swap: { enable: false },
      passport: passportClient,
    }),
  );

  return (
    <SdkModuleContext.Provider
      value={{
        blockchainData: blockchainDataModule,
        checkout: checkoutModule,
        passport: passportClient,
      }}
    >
      {children}
    </SdkModuleContext.Provider>
  );
};

export const useSdkModule = () => {
  const context = useContext(SdkModuleContext);
  if (context === null || context === undefined) {
    throw new Error("useSdkConfig must be used within a SdkModuleProvider");
  }

  return context;
};
