import { Body, Box, Button, Heading, Icon } from "@biom3/react";
import Image from "next/image";
import Link from "next/link";
import { Trans, useTranslation } from "react-i18next";

import { MODAL_WIDTH } from "@/constants";
import { getZkEvmExplorerUrl } from "@/utils/util";

export const TransferTransactionModalContent = ({
  status,
  onImportMore,
  onDone,
  hash,
}: {
  status: "progress" | "success";
  onImportMore: () => void;
  onDone: () => void;
  hash?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      key="in-progress-modal-content"
      sx={{
        background: "var(--on-dark-base-color-neutrals-800, #1F1F1F)",
        borderRadius: "base.borderRadius.x8",
        boxShadow: "base.shadow.400",
        width: {
          default: "calc(100dvw - 32px)",
          medium: MODAL_WIDTH,
          large: "480px",
        },
        height: "auto",
      }}
    >
      <Box
        sx={{
          height: "305px",
          d: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          src="/transferSuccessModal.svg"
          alt="Transfer Success"
          width={180}
          height={200}
        />
      </Box>
      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          background: "base.color.brand.2",
          p: "base.spacing.x7",
        }}
      >
        <Heading size="small">{t(`asset_transfer_${status}_title`)}</Heading>
        <Body
          sx={{
            fontSize: "base.text.body.medium.regular.fontSize",
            mt: "base.spacing.x4",
          }}
        >
          <Trans
            i18nKey={[`asset_transfer_${status}_description`]}
            components={{
              transactionLink: (
                <Body
                  sx={{ color: "base.color.accent.1", textDecoration: "none" }}
                  rc={
                    <Link
                      target="_blank"
                      href={`${getZkEvmExplorerUrl()}/tx/${hash}`}
                    />
                  }
                />
              ),
            }}
          />
          &nbsp;
          <Icon
            icon="JumpTo"
            sx={{
              width: "base.icon.size.200",
              position: "relative",
              top: "3px",
            }}
          />
        </Body>
        <Button
          size="large"
          variant="primary"
          onClick={onImportMore}
          sx={{ mt: "base.spacing.x6" }}
        >
          {t("choose_more_to_import")}
        </Button>
        <Button
          size="large"
          variant="tertiary"
          onClick={onDone}
          sx={{ mt: "base.spacing.x4" }}
        >
          {t("im_done")}
        </Button>
      </Box>
    </Box>
  );
};
