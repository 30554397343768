import { checkout } from "@imtbl/sdk";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { getCheckoutConfig } from "./CheckoutProvider";
import { useImmutableProvider } from "./ImmutableProvider";

export type CommerceContext = {
  commerceWidget:
    | checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>
    | undefined;
};

const commerceContext = createContext<CommerceContext | null>(null);

export function CommerceProvider({ children }: PropsWithChildren) {
  const [commerceWidget, setCommerceWidget] =
    useState<checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>>();

  const { passportClient } = useImmutableProvider();

  const environment = appConfig.ENVIRONMENT;

  useEffect(() => {
    (async () => {
      const checkoutSDK = new checkout.Checkout({
        passport: passportClient,
        ...getCheckoutConfig(environment),
      });
      const widgetsFactory = await checkoutSDK.widgets({
        config: {
          theme: checkout.WidgetTheme.DARK,
        },
      });

      const commerceWidget = widgetsFactory.create(
        checkout.WidgetType.IMMUTABLE_COMMERCE,
        {},
      );
      setCommerceWidget(commerceWidget);
    })();
  }, [environment, passportClient]);

  return (
    <commerceContext.Provider value={{ commerceWidget }}>
      {children}
    </commerceContext.Provider>
  );
}

export const useCommerce = () => {
  const context = useContext(commerceContext);
  if (!context) {
    throw new Error("useCommerce must be used within a CommerceProvider");
  }
  return context;
};
