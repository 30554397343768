import {
  type StandardAnalyticsActions,
  type StandardAnalyticsControlTypes,
  createAnalytics,
} from "@imtbl/react-analytics";

import { appConfig } from "@/constants";
import type { QuestTileType } from "@/domain/gems-game/domain";
import { useEffect, useState } from "react";

type Overrides = {
  control?: string;
  controlType?: StandardAnalyticsControlTypes;
};

export type DashboardAnalyticsJourneyType =
  | "Profile"
  | "Activity"
  | "Balances"
  | "Collections"
  | "Games"
  | "Wallets"
  | "AssetTransfer"
  | "Rewards"
  | "Onboarding"
  | "Gems"
  | "Split"
  | "Listing"
  | "Bid"
  | "Connect"
  | "Disconnect"
  | "Claim"
  | "Ecosystem"
  | "Quest"
  | UserJourneyQuest
  | QuestsNavigationJourney
  | WishlistUserJourney;

export type UserJourneyQuest =
  | "TombOfHeroesQuest"
  | "TradeZkEvmBonus"
  | "TradeZkEvmIMXBonus"
  | "IlluviumBonus"
  | "BabySharkBonus"
  | "HuntersOnChainBonus"
  | "MedievalEmpiresBonus"
  | "MedievalEmpiresLevel8Bonus"
  | "SpaceNationWhitelistSpots"
  | "SpaceNationLayer3Quest"
  | "CallOfTheVoydRaffle"
  | "SetUpPassportQuest"
  | "PoolMastersQuest"
  | "TreeverseQuest"
  | "CounterFireQuest"
  | "RavenQuestQuest"
  | "SomnisBonus"
  | "GoGPromoTileJadey"
  | "PoolMastersManualQuest"
  | "ParadiseTycoonQuest"
  | "ImmortalRisingQuest"
  | "RebelBotsQuest";

export type WishlistUserJourney = "BadMadRobots";

type QuestsNavigationJourney =
  | "FeaturedQuests"
  | "MainQuests"
  | "SideQuests"
  | "PromoTiles";

export type Component =
  | "ConnectionProvider"
  | "Claim"
  | "Dashboard"
  | "GameShowcase"
  | "QuestTile"
  | "QuestTileModal"
  | "HowItWorksModal"
  | "QuestsNavigation"
  | "PromoTile"
  | "RecommendedQuests"
  | "WishlistTile";

export type DashboardAnalyticsScreenType = string;
export type DashboardAnalyticsControlType = string;

export type DashboardAnalyticsBaseType = {
  userJourney: DashboardAnalyticsJourneyType;
  screen: DashboardAnalyticsScreenType;
};

export const { AnalyticsProvider, useAnalytics } = createAnalytics<
  DashboardAnalyticsJourneyType,
  DashboardAnalyticsScreenType,
  DashboardAnalyticsControlType,
  StandardAnalyticsControlTypes | "Effect" | "Card" | "Modal" | "Group",
  StandardAnalyticsActions | "Changed" | "Closed"
>({
  writeKey: appConfig.SEGMENT_KEY,
  appName: "PassportDashboard",
});

export function usePageEventOnce(
  props: {
    screen: Component;
    userJourney: DashboardAnalyticsJourneyType;
  } & Record<string, string>,
) {
  const analytics = useAnalytics();
  const [eventAlreadySent, setEventAlreadySent] = useState(false);

  useEffect(() => {
    if (eventAlreadySent) return;
    setEventAlreadySent(true);
    analytics.page(props);
  }, [analytics, eventAlreadySent, props]);
}

export function useQuestAnalytics(
  userJourney: UserJourneyQuest,
  tileType: QuestTileType,
) {
  const analytics = useAnalytics();

  const trackCtaClick = ({
    control = "PlayGame",
    controlType = "Button",
  }: Overrides) =>
    analytics.track({
      screen: "QuestTile",
      userJourney,
      action: "Pressed",
      control,
      controlType,
      extras: {
        tileType,
      },
    });

  const trackRulesCtaClick = ({
    control = "PlayGame",
    controlType = "Button",
  }: Overrides) =>
    analytics.track({
      screen: "QuestTileModal",
      userJourney,
      action: "Pressed",
      control,
      controlType,
      extras: {
        tileType,
      },
    });

  const trackRulesSecondaryClick = ({
    control = "SecondaryAction",
    controlType = "Link",
  }: Overrides = {}) => {
    // For existing events we overwrite the control to be the same, and use 'ExternalLink' for all new events
    // Once MedievalEmpires quests over it can be removed
    if (
      userJourney === "MedievalEmpiresLevel8Bonus" ||
      userJourney === "MedievalEmpiresBonus"
    ) {
      control = "BuyBoosterPacks";
    }
    return analytics.track({
      screen: "QuestTileModal",
      userJourney,
      action: "Pressed",
      control,
      controlType,
      extras: {
        tileType,
      },
    });
  };

  const trackRulesSupportClick = ({
    control = "SupportAction",
    controlType = "Link",
  }: Overrides = {}) => {
    return analytics.track({
      screen: "QuestTileModal",
      userJourney,
      action: "Pressed",
      control,
      controlType,
    });
  };

  const trackOpenRulesClick = () =>
    analytics.track({
      screen: "QuestTile",
      userJourney,
      action: "Pressed",
      control: "OpenRules",
      controlType: "Button",
      extras: {
        tileType,
      },
    });

  const trackCloseRulesClick = () =>
    analytics.track({
      screen: "QuestTileModal",
      userJourney,
      action: "Pressed",
      control: "CloseRules",
      controlType: "Button",
      extras: {
        tileType,
      },
    });

  return {
    trackCtaClick,
    trackRulesCtaClick,
    trackOpenRulesClick,
    trackCloseRulesClick,
    trackRulesSecondaryClick,
    trackRulesSupportClick,
  };
}

export function useWishlistAnalytics(userJourney: WishlistUserJourney) {
  const analytics = useAnalytics();

  const trackConnectWalletClick = () =>
    analytics.track({
      screen: "WishlistTile",
      userJourney,
      action: "Pressed",
      control: "ConnectWallet",
      controlType: "Button",
    });

  const trackAddToWishlistClick = () =>
    analytics.track({
      screen: "WishlistTile",
      userJourney,
      action: "Pressed",
      control: "AddToWishlist",
      controlType: "Button",
    });

  return {
    trackConnectWalletClick,
    trackAddToWishlistClick,
  };
}
