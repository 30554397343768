import type { UrlObject } from "node:url";
import { useRouter } from "next/router";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useAnalytics } from "./AnalyticsProvider";

export type ImportOrigin =
  | "CollectionsEmptyState"
  | "ActionMenuLinkWallet"
  | "ExternalWallets"
  | "ActionMenu"
  | "CollectionCard"
  | "ImportSuccess"
  | "ItemDetails";

export type ActiveAssetImport = {
  importAddress: string;
  trackingId: string;
};

export const AssetImportContext = createContext<{
  activeImport: ActiveAssetImport | undefined;
  startImport:
    | ((
        url: UrlObject | undefined,
        importAddress: string,
        importOrigin: ImportOrigin,
        forceNewImport: boolean,
      ) => void)
    | undefined;
  endImport: (() => void) | undefined;
}>({ activeImport: undefined, startImport: undefined, endImport: undefined });

const useAssetImport = () => {
  const ctx = useContext(AssetImportContext);
  if (!ctx) {
    throw new Error("useAssetImport must be used within a AssetImportProvider");
  }
  return ctx;
};

const AssetImportProvider = ({ children }: PropsWithChildren) => {
  const { push } = useRouter();
  const { track } = useAnalytics();
  const [activeImport, setActiveImport] = useState<
    ActiveAssetImport | undefined
  >(undefined);
  const startImport = useCallback(
    (
      url: UrlObject | undefined,
      importAddress: string,
      importOrigin: ImportOrigin,
      forceNewImport = false,
    ) => {
      // If the import is already active, don't start a new one e.g. click import on collection card
      if (
        !activeImport ||
        activeImport.importAddress !== importAddress ||
        forceNewImport
      ) {
        const newTrackingId = uuidv4();
        setActiveImport({ importAddress, trackingId: newTrackingId });
        track({
          screen: "",
          userJourney: "AssetTransfer",
          action: "Started",
          extras: { flowId: newTrackingId, importOrigin },
        });
      }

      if (url) {
        push(url);
      }
    },
    [push, track, activeImport],
  );
  const endImport = useCallback(() => {
    setActiveImport(undefined);
  }, []);
  return (
    <AssetImportContext.Provider
      value={{
        activeImport,
        startImport,
        endImport,
      }}
    >
      {children}
    </AssetImportContext.Provider>
  );
};

export { AssetImportProvider, useAssetImport };
