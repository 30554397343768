import { AcceptOfferStatusModal } from "@/components/collections/AcceptOfferStatusModal";
import { MarketPlaceModal } from "@/components/collections/MarketPlaceModal";
import { BAD_IMAGE_URL } from "@/constants";
import {
  useAnalytics,
  useFiatPricingContext,
  useOrderbook,
  usePassportProvider,
} from "@/context";
import { useErc20 } from "@/context/Erc20Provider";
import type { Bid, ItemDetails } from "@/types";
import { flattenToString, getFormattedNetPrice } from "@/utils/util";
import { Body, Box, Button, InputBar, MenuItem, Stack } from "@biom3/react";
import { useMappedCollectionItems } from "@hooks/useMappedCollectionItems";
import type { orderbook } from "@imtbl/sdk";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type CollectionBidItemSelectionProps = {
  items: ItemDetails[];
  bid: orderbook.CollectionBid | null;
  onCancel: () => void;
};

export const CollectionBidItemSelection = ({
  items,
  bid,
  onCancel,
}: CollectionBidItemSelectionProps) => {
  const [selectedItem, setSelectedItem] = useState<ItemDetails | null>(null);
  const [modalStatus, setModalStatus] = useState<{
    state?: "SUCCESS" | "ERROR" | "LOADING";
    itemName?: string;
    itemImage?: string;
    offerPrice?: string;
  }>({ state: undefined });
  const [isMarketplaceModalVisible, setIsMarketplaceModalVisible] =
    useState(false);

  const {
    fulfillERC721CollectionBid,
    fulfillERC1155CollectionBid,
    activeOrders,
  } = useOrderbook();
  const { tokens } = useErc20();
  const { getFormattedPrice } = useFiatPricingContext();
  const { walletAddress } = usePassportProvider();
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const router = useRouter();

  const hiddenBidIds = useMemo(() => {
    if (typeof window === "undefined") return new Set();
    try {
      const stored = localStorage.getItem("hiddenItemBidIds");
      return stored ? new Set(JSON.parse(stored)) : new Set();
    } catch (error) {
      console.error(
        "Failed to parse hiddenItemBidIds from localStorage:",
        error,
      );
      return new Set();
    }
  }, []);

  const filterHiddenBids = useCallback(
    (bids: Bid[] | undefined): Bid[] => {
      if (!bids) return [];
      return bids.filter((bid) => !hiddenBidIds.has(bid.id));
    },
    [hiddenBidIds],
  );

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) => item.owner?.toLowerCase() === walletAddress?.toLowerCase(),
      ),
    [items, walletAddress],
  );

  const mappedCollectionItems = useMappedCollectionItems(filteredItems, {
    tokens,
    getFormattedPrice,
    t,
    activeOrders,
    filterHiddenBids,
  });

  const handleItemClick = (item: ItemDetails) => {
    setSelectedItem(item);
  };

  const handleSellNow = async () => {
    if (!selectedItem || !bid) return;

    setModalStatus({
      state: "LOADING",
      itemName: selectedItem.name || "",
      itemImage: selectedItem.image || BAD_IMAGE_URL,
    });

    try {
      if (bid.buy[0].type === "ERC721_COLLECTION") {
        await fulfillERC721CollectionBid(bid.id, selectedItem.tokenId);
      } else if (bid?.buy[0].type === "ERC1155_COLLECTION") {
        await fulfillERC1155CollectionBid(bid.id, "1", selectedItem.tokenId);
      }

      setModalStatus({
        state: "SUCCESS",
        itemName: selectedItem.name || "",
        itemImage: selectedItem.image || BAD_IMAGE_URL,
        offerPrice: getFormattedNetPrice(bid, tokens),
      });
      track({
        userJourney: "Bid",
        screen: "Accept",
        control: "Succeeded",
        controlType: "Effect",
      });
    } catch (error) {
      console.error("Error fulfilling offer:", error);
      setModalStatus({
        state: "ERROR",
        itemName: selectedItem.name || "",
        itemImage: selectedItem.image || BAD_IMAGE_URL,
      });
      track({
        userJourney: "Bid",
        screen: "Accept",
        control: "Failed",
        controlType: "Effect",
      });
    }
  };

  const onRetry = async () => {
    await handleSellNow();
  };

  const onCloseModal = () => {
    setModalStatus({ state: undefined });
    if (modalStatus.state === "SUCCESS") {
      router.back();
    }
  };

  const onGoToMarketplace = useCallback(() => {
    setIsMarketplaceModalVisible(true);
  }, []);

  const onMarketplaceClose = useCallback(() => {
    if (modalStatus.state === "SUCCESS") {
      router.back();
    }
    setIsMarketplaceModalVisible(false);

    setModalStatus({
      state: undefined,
      itemName: "",
      itemImage: "",
      offerPrice: "",
    });
  }, [modalStatus.state, router]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            gap: "base.spacing.x3",
            mb: "base.spacing.x4",
          }}
        >
          <Button variant="secondary" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={handleSellNow}
            disabled={!selectedItem || modalStatus.state === "LOADING"}
          >
            {t("sell_now")}
          </Button>
        </Box>
        <Stack>
          {mappedCollectionItems.map(
            ({ item, pricingInfo, listing, bestOffer }) => {
              let primaryLabel = "";
              let primaryValue = "";
              let secondaryLabel = "";
              let secondaryValue = "";

              if (bestOffer) {
                primaryLabel = t("offer_price");
                primaryValue = bestOffer.offeredPrice;
                secondaryValue = bestOffer.fiatPrice;
              } else if (listing) {
                primaryLabel = t("asked_price");
                primaryValue = listing.askingPrice;
                secondaryValue = listing.fiatPrice;
              } else if (pricingInfo) {
                primaryLabel = pricingInfo.floorPrice ? t("floor_price") : "";
                primaryValue = pricingInfo.floorPrice ?? "";
                secondaryLabel = pricingInfo.lastSoldDate ?? "";
                secondaryValue = pricingInfo.lastSoldPrice ?? "";
              }

              return (
                <InputBar
                  key={item.tokenId}
                  onClick={() => handleItemClick(item)}
                  inputType="radio"
                  checked={selectedItem?.tokenId === item.tokenId}
                  sx={{
                    background: "base.color.translucent.emphasis.100",
                    borderRadius: "base.spacing.x2",
                    ".rightContainer": {
                      marginTop: "auto",
                      mb: "base.spacing.x1",
                    },
                  }}
                >
                  <InputBar.FramedImage
                    imageUrl={item.image ?? BAD_IMAGE_URL}
                  />
                  <InputBar.Label>{item.name}</InputBar.Label>
                  <InputBar.Caption sx={{ mt: "auto", pb: "0px" }}>
                    {primaryLabel || secondaryLabel || ""}
                  </InputBar.Caption>
                  <Box
                    sx={{
                      mr: "base.spacing.x4",
                    }}
                  >
                    <Body
                      size={primaryValue ? "small" : "xSmall"}
                      sx={
                        primaryValue
                          ? undefined
                          : { color: "base.color.text.body.secondary" }
                      }
                    >
                      {primaryValue || secondaryValue || ""}
                    </Body>
                  </Box>
                  <MenuItem.BottomSlot
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      pt: "0px",
                    }}
                  >
                    <Body>
                      {primaryLabel && secondaryLabel ? secondaryLabel : ""}
                    </Body>
                    <Body sx={{ mr: "base.spacing.x4", pt: "0px" }}>
                      {primaryLabel && secondaryValue ? secondaryValue : ""}
                    </Body>
                  </MenuItem.BottomSlot>
                </InputBar>
              );
            },
          )}
        </Stack>
      </Box>
      {modalStatus.state && (
        <AcceptOfferStatusModal
          state={modalStatus.state}
          itemName={modalStatus.itemName}
          itemImage={modalStatus.itemImage}
          offerPrice={modalStatus.offerPrice}
          onRetry={onRetry}
          onClose={onCloseModal}
          onGoToMarketplace={onGoToMarketplace}
        />
      )}
      <MarketPlaceModal
        visible={isMarketplaceModalVisible}
        origin={"ItemList"}
        dismissModal={onMarketplaceClose}
        address={
          items[0]?.discriminator === "ZKEVM_ITEM_V2" ? items[0].collection : ""
        }
        network={items[0].network}
        collectionName={flattenToString(items[0]?.collectionName)}
      />
    </>
  );
};
