import { checkout, config } from "@imtbl/sdk";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { EnvironmentNames } from "@/types";

import { useImmutableProvider } from "./ImmutableProvider";
import { usePassportProvider } from "./PassportProvider";

export type CheckoutContext = {
  checkoutClient: checkout.Checkout | undefined;
  checkoutNetwork: checkout.NetworkInfo | undefined;
  connectWidget:
    | checkout.Widget<typeof checkout.WidgetType.CONNECT>
    | undefined;
  onRampWidget: checkout.Widget<typeof checkout.WidgetType.ONRAMP> | undefined;
  swapWidget: checkout.Widget<typeof checkout.WidgetType.SWAP> | undefined;
  bridgeWidget: checkout.Widget<typeof checkout.WidgetType.BRIDGE> | undefined;
  widgets: checkout.IWidgetsFactory | undefined;
  commerceWidget:
    | checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>
    | undefined;
};

const checkoutContext = createContext<CheckoutContext | null>(null);

// TODO @pano @ji: move to config.ts and reuse after configuring unit testing
export const getBaseConfig = (
  environment: EnvironmentNames,
): { baseConfig: config.ImmutableConfiguration } => {
  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.PRODUCTION,
          // publishableKey: process.env.NEXT_PUBLIC_IMMUTABLE_HUB_PUBLISHABLE_KEY, // TODO: configure when upgrading imtbl SDK to latest
        }),
      };
    }
    case EnvironmentNames.SANDBOX: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.SANDBOX,
          // publishableKey: process.env.NEXT_PUBLIC_IMMUTABLE_HUB_PUBLISHABLE_KEY, // TODO: configure when upgrading imtbl SDK to latest
        }),
      };
    }
    case EnvironmentNames.DEV: {
      const baseConfig = new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
        // publishableKey: process.env.NEXT_PUBLIC_IMMUTABLE_HUB_PUBLISHABLE_KEY, // TODO: configure when upgrading imtbl SDK to latest
      });

      return {
        baseConfig,
      };
    }

    default: {
      throw new Error("Invalid environment");
    }
  }
};

export const getCheckoutConfig = (environment: EnvironmentNames) => {
  const { baseConfig } = getBaseConfig(environment);
  const checkoutConfig = {
    // This configuration is for Smart Checkout functionality. It is not used to configure individual widgets.
    bridge: { enable: false },
    onRamp: { enable: false },
    swap: { enable: false },
  };

  return {
    baseConfig,
    ...checkoutConfig,
  };
};

export function CheckoutProvider({ children }: PropsWithChildren) {
  const [widgets, setWidgets] = useState<checkout.IWidgetsFactory | undefined>(
    undefined,
  );
  const [checkoutClient, setCheckoutClient] = useState<
    checkout.Checkout | undefined
  >(undefined);
  const [checkoutNetwork, setCheckoutNetwork] = useState<
    checkout.NetworkInfo | undefined
  >(undefined);

  const [connectWidget, setConnectWidget] = useState<
    checkout.Widget<typeof checkout.WidgetType.CONNECT> | undefined
  >(undefined);

  const [onRampWidget, setOnRampWidget] = useState<
    checkout.Widget<typeof checkout.WidgetType.ONRAMP> | undefined
  >(undefined);

  const [swapWidget, setSwapWidget] = useState<
    checkout.Widget<typeof checkout.WidgetType.SWAP> | undefined
  >(undefined);

  const [bridgeWidget, setBridgeWidget] = useState<
    checkout.Widget<typeof checkout.WidgetType.BRIDGE> | undefined
  >(undefined);

  const [commerceWidget, setCommerceWidget] =
    useState<checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>>();

  const { passportClient } = useImmutableProvider();
  const { zkEvmProvider, passportState } = usePassportProvider();
  const environment = appConfig.ENVIRONMENT;

  useEffect(() => {
    const initialise = async () => {
      if (zkEvmProvider) {
        const checkoutConfig = {
          passport: passportClient,
          ...getCheckoutConfig(environment),
        };

        const checkoutSDK = new checkout.Checkout(checkoutConfig);
        setCheckoutClient(checkoutSDK);

        const networkInfo = await checkoutSDK.getNetworkInfo({
          provider: zkEvmProvider,
        });
        setCheckoutNetwork(networkInfo);

        const widgetsFactory = await checkoutSDK.widgets({
          config: {
            theme: checkout.WidgetTheme.DARK,
          },
        });
        setWidgets(widgetsFactory);
        setConnectWidget(widgetsFactory.create(checkout.WidgetType.CONNECT));
        setOnRampWidget(
          widgetsFactory.create(checkout.WidgetType.ONRAMP, {
            config: { theme: checkout.WidgetTheme.DARK },
          }),
        );
        setSwapWidget(widgetsFactory.create(checkout.WidgetType.SWAP));
        setBridgeWidget(widgetsFactory.create(checkout.WidgetType.BRIDGE));
        setCommerceWidget(
          widgetsFactory.create(checkout.WidgetType.IMMUTABLE_COMMERCE, {}),
        );
        widgetsFactory.updateProvider(zkEvmProvider);
      }
    };

    if (passportState.zkEvmRegistered && zkEvmProvider) {
      initialise();
    }
  }, [environment, passportClient, passportState, zkEvmProvider]);

  return (
    <checkoutContext.Provider
      value={{
        checkoutClient,
        checkoutNetwork,
        connectWidget,
        onRampWidget,
        swapWidget,
        bridgeWidget,
        widgets,
        commerceWidget,
      }}
    >
      {children}
    </checkoutContext.Provider>
  );
}

export function useCheckout() {
  const ctx = useContext(checkoutContext);
  if (!ctx) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }

  return { ...ctx };
}
