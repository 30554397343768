import { Box, Stack } from "@biom3/react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

import { BaseEmptyState } from "@/components/emptystates/BaseEmptyState";
import { useEffect } from "react";

export const AnimatedEmptyState = ({
  creatingWallet,
  rivePath,
  riveStateMachine,
  riveInputPath,
  titleKey,
  captionKey,
  primaryButtonKey,
  secondaryButtonKey,
  primaryButtonClick,
  secondaryButtonClick,
}: {
  creatingWallet: boolean;
  rivePath: string;
  riveStateMachine: string;
  riveInputPath: string;
  titleKey: string;
  captionKey: string;
  primaryButtonKey: string;
  secondaryButtonKey: string;
  primaryButtonClick: () => void;
  secondaryButtonClick: () => void;
}) => {
  const { rive, RiveComponent } = useRive({
    src: rivePath,
    autoplay: true,
    stateMachines: [riveStateMachine],
  });

  const walletCreationDoneInput = useStateMachineInput(
    rive,
    "Balance",
    "Wallet Creation Done",
  );

  useEffect(() => {
    if (!creatingWallet && walletCreationDoneInput) {
      walletCreationDoneInput.value = true;
    }
  }, [walletCreationDoneInput, creatingWallet]);

  return (
    <Stack sx={{ w: "100%", alignItems: "center" }}>
      <Box sx={{ width: "430px", height: "240px" }} rc={<RiveComponent />} />
      <BaseEmptyState
        creatingWallet={creatingWallet}
        titleKey={titleKey}
        captionKey={captionKey}
        primaryButtonKey={primaryButtonKey}
        secondaryButtonKey={secondaryButtonKey}
        primaryButtonClick={primaryButtonClick}
        primaryMouseOver={(hovering: boolean) => {
          rive?.setNumberStateAtPath(
            "Sad - Happy",
            hovering ? 1 : 0,
            riveInputPath,
          );
        }}
        secondaryButtonClick={secondaryButtonClick}
      />
    </Stack>
  );
};
