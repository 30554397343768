import {
  Box,
  CarouselSimple,
  type CarouselSimpleProps,
  ShimmerBox,
} from "@biom3/react";
import type { EmblaCarouselType } from "embla-carousel";
import { type PropsWithChildren, useEffect, useState } from "react";

import { MAX_CONTAINER_WIDTH, PADDING_X, TILE_DIMENSIONS } from "./constants";
import type { TileType } from "./types";

export type CarouselContainerProps = {
  tileType: TileType;
  isLoading?: boolean;
  scrollToIndex?: number;
} & CarouselSimpleProps &
  PropsWithChildren;

const LoadingState = ({ tileType }: { tileType: TileType }) => {
  const height = TILE_DIMENSIONS[tileType].height;
  const width = TILE_DIMENSIONS[tileType].widthTillLarge;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        marginX: "auto",
        maxWidth: `${MAX_CONTAINER_WIDTH}px`,
        px: PADDING_X,
      }}
      testId={`${tileType}__loading`}
    >
      <ShimmerBox
        sx={{
          flexShrink: 0,
          height,
          width,
          ml: "0px",
        }}
      />
      {tileType !== "featureQuest" && (
        <ShimmerBox
          sx={{
            flexShrink: 0,
            height,
            width,
            ml: "base.spacing.x6",
          }}
        />
      )}
    </Box>
  );
};

export const CarouselContainer = ({
  children,
  tileType,
  scrollToIndex,
  isLoading,
  ...carouselProps
}: CarouselContainerProps) => {
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);

  useEffect(() => {
    if (emblaApi && scrollToIndex !== undefined) {
      const { slideRegistry } = emblaApi.internalEngine();
      const snapIndexThatSlideBelongsTo = slideRegistry.findIndex((group) =>
        group.includes(scrollToIndex),
      );
      if (typeof snapIndexThatSlideBelongsTo !== "undefined") {
        emblaApi.scrollTo(snapIndexThatSlideBelongsTo);
      }
    }
  }, [scrollToIndex, emblaApi]);

  return isLoading ? (
    <LoadingState tileType={tileType} />
  ) : (
    <CarouselSimple
      testId={`${tileType}__carousel`}
      slidesToScroll="auto"
      highlightActiveSlide={false}
      getApi={setEmblaApi}
      controlBarSx={{
        justifyContent: "start",
        gap: "base.spacing.x4",
      }}
      {...carouselProps}
    >
      {children}
      <CarouselSimple.PreviousButtCon
        testId={`${tileType}__prev`}
        size="medium"
      />
      <CarouselSimple.NextButtCon
        testId={`${tileType}__next`}
        size="medium"
        sx={{
          mr: "base.spacing.x2",
        }}
      />
      <CarouselSimple.Pagination testId={`${tileType}__navigation`} />
    </CarouselSimple>
  );
};
