import { HOMEPAGE } from "@/constants";
import {
  type DashboardAnalyticsJourneyType,
  usePassportProvider,
} from "@/context";
import { useRouter } from "next/router";
import { type PropsWithChildren, useEffect } from "react";
import { Layout } from "./Layout";
type Props = PropsWithChildren & {
  screen: string;
  userJourney: DashboardAnalyticsJourneyType;
};

export function ProtectedLayout({ children, screen, userJourney }: Props) {
  const { passportState } = usePassportProvider();
  const router = useRouter();

  useEffect(() => {
    if (passportState.ready && !passportState.authenticated) {
      router.push({
        pathname: HOMEPAGE,
      });
    }
  }, [passportState, router]);

  return passportState.ready ? (
    <Layout screen={screen} userJourney={userJourney}>
      {children}
    </Layout>
  ) : (
    <></>
  );
}
