import Image from "next/image";

import { notifyError } from "@/utils/monitoring";

// @ts-ignore
function ErrorComponent() {
  // We intentionally don't use biome here since the error state is a fallback to any error, including biome errors.
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        color: "white",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Image
        priority
        src="/errorState.svg"
        alt="error state"
        style={{ maxWidth: "400px", maxHeight: "300.939px" }}
        width={400}
        height={300.939}
        layout="responsive"
      />

      <span>We hit a glitch - we’re onto it</span>
      <span>Please try again later</span>
    </div>
  );
}

// @ts-ignore
Error.getInitialProps = ({ res, err }) => {
  // @ts-ignore
  notifyError(err, "appError");

  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorComponent;
