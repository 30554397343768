import { appConfig } from "@/constants";
import {
  EnvironmentNames,
  type Erc20Token,
  type MainnetBalance,
} from "@/types";
import { Alchemy, BigNumber, Network } from "alchemy-sdk";
import axios from "axios";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePassportProvider } from "./PassportProvider";

export type ImportOrigin =
  | "CollectionsEmptyState"
  | "ActionMenuLinkWallet"
  | "ExternalWallets"
  | "ActionMenu"
  | "CollectionCard"
  | "ImportSuccess"
  | "ItemDetails";

export type ActiveAssetImport = {
  importAddress: string;
  trackingId: string;
};

const config = {
  apiKey: appConfig.ALCHEMY_API_KEY,
  network:
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? Network.ETH_MAINNET
      : Network.ETH_SEPOLIA,
};
export const alchemy = new Alchemy(config);

const SEPOLIA_TOKENS = [
  {
    symbol: "tIMX",
    address: "0x5b26a75eE4a4B68a8fe8f94E4b729Ff1b8a31051",
    name: "Test Immutable X",
    decimals: 18,
    imageUrl: "https://checkout-cdn.immutable.com/v1/blob/img/tokens/imx.svg",
  },
  {
    symbol: "USDC",
    address: "0x40b87d235A5B010a20A241F15797C9debf1ecd01",
    name: "USD Coin",
    decimals: 6,
    imageUrl:
      "https://checkout-cdn.immutable.com/v1/blob/img/tokens/0x6de8acc0d406837030ce4dd28e7c08c5a96a30d2.svg",
  },
  {
    symbol: "tIMX",
    address: "0x2Fa06C6672dDCc066Ab04631192738799231dE4a",
    name: "Test Immutable X 2",
    decimals: 18,
    imageUrl: "https://checkout-cdn.immutable.com/v1/blob/img/tokens/imx.svg",
  },
];

const MAINNET_TOKENS = [
  {
    symbol: "IMX",
    address: "0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF",
    name: "IMX",
    decimals: 18,
    imageUrl: "https://checkout-cdn.immutable.com/v1/blob/img/tokens/imx.svg",
  },
  {
    symbol: "USDC",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    name: "USD Coin",
    decimals: 6,
    imageUrl:
      "https://checkout-cdn.immutable.com/v1/blob/img/tokens/0x6de8acc0d406837030ce4dd28e7c08c5a96a30d2.svg",
  },
  {
    symbol: "GOG",
    address: "0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62",
    name: "Guild of Guardians",
    decimals: 18,
    imageUrl:
      "https://checkout-cdn.immutable.com/v1/blob/img/tokens/0xb00ed913aaff8280c17bff33cce82fe6d79e85e8.svg",
  },
  {
    symbol: "ILV",
    address: "0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E",
    name: "Illuvium",
    decimals: 18,
    imageUrl: `${appConfig.ASSET_BASE_URL}/illuvium.webp`,
  },
];

export const Erc20Context = createContext<{
  tokens: Map<string, Erc20Token>;
  fetchToken: (address: string) => Erc20Token | undefined;
  getMainnetBalances: () => Promise<MainnetBalance[]>;
}>({
  tokens: new Map(),
  fetchToken: () => undefined,
  getMainnetBalances: async () => [],
});

const useErc20 = () => {
  const ctx = useContext(Erc20Context);
  if (!ctx) {
    throw new Error("useErc20 must be used within a Erc20Provider");
  }
  return ctx;
};

const Erc20Provider = ({ children }: PropsWithChildren) => {
  const { walletAddress } = usePassportProvider();
  // const walletAddress = "0x9ac254faaca6e215252430ed5ae14fe1fb2004ac"; // IMX && ETH test address
  // const walletAddress = "0xfae7b0240947B9d8a0Cf4adEE411706a2360074E"; // ILV && USD && ETH test address
  // const walletAddress = "0x9C36A49CBb76df9039DC06fB5E2930b63412245B"; // GOG && ETH test address
  const [tokenMap, setTokenMap] = useState<Map<string, Erc20Token>>(new Map());
  const baseUrl = useMemo(() => {
    const base =
      appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
        ? "https://api.immutable.com"
        : "https://api.sandbox.immutable.com";
    const chainName =
      appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
        ? "imtbl-zkevm-mainnet"
        : "imtbl-zkevm-testnet";
    return `${base}/v1/chains/${chainName}`;
  }, []);

  useEffect(() => {
    const getTokens = async () => {
      const path = `${baseUrl}/tokens?verification_status=verified&page_size=200`;
      const response = await axios.get(path);
      const tokens: Erc20Token[] = response.data.result;
      setTokenMap(
        new Map(tokens.map((token) => [token.contract_address, token])),
      );
    };
    getTokens();
  }, [baseUrl]);

  const fetchToken = useCallback(
    (address: string) => {
      if (tokenMap.has(address)) {
        return tokenMap.get(address);
      }

      const getSingleToken = async () => {
        try {
          const path = `${baseUrl}/tokens/${address}`;
          const response = await axios.get(path);
          const token: Erc20Token = response.data.result;
          // need to update the state in order for UI's to re-render
          const newMap = new Map(tokenMap);
          newMap.set(address, token);
          setTokenMap(newMap);
        } catch (e) {
          console.error(e);
        }
      };
      getSingleToken();

      return undefined;
    },
    [baseUrl, tokenMap],
  );

  const getMainnetBalances = useCallback(async () => {
    if (!walletAddress) {
      return [];
    }

    const tokensToFetch =
      appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
        ? MAINNET_TOKENS
        : SEPOLIA_TOKENS;
    const data = await alchemy.core.getTokenBalances(
      walletAddress,
      tokensToFetch.map((token) => token.address),
    );
    const balances: MainnetBalance[] = [];
    for (const balance of data.tokenBalances) {
      if (
        typeof balance.tokenBalance === "string" &&
        !BigNumber.from(balance.tokenBalance).eq(BigNumber.from(0))
      ) {
        const token = tokensToFetch.find(
          (t) =>
            t.address.toLocaleLowerCase() ===
            balance.contractAddress.toLocaleLowerCase(),
        );
        if (token) {
          balances.push({
            address: balance.contractAddress,
            symbol: token.symbol,
            balance: BigNumber.from(balance.tokenBalance).toString(),
            decimals: token.decimals,
            tokenName: token.name,
            tokenImage: token.imageUrl,
          });
        }
      }
    }
    const ethBalance = await alchemy.core.getBalance(walletAddress);
    if (!ethBalance.eq(BigNumber.from(0))) {
      balances.push({
        address: "ETH",
        symbol: "ETH",
        balance: ethBalance.toString(),
        decimals: 18,
        tokenName: "Ethereum",
        tokenImage:
          "https://checkout-cdn.immutable.com/v1/blob/img/tokens/eth.svg",
      });
    }
    return balances;
  }, [walletAddress]);

  return (
    <Erc20Context.Provider
      value={{
        tokens: tokenMap,
        fetchToken,
        getMainnetBalances,
      }}
    >
      {children}
    </Erc20Context.Provider>
  );
};

export { Erc20Provider, useErc20 };
