import "../i18n";
import "regenerator-runtime/runtime";

import { EnvironmentNames } from "@/types";
import { onDarkBase } from "@biom3/design-tokens";
import { BiomeCombinedProviders } from "@biom3/react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Roboto, Roboto_Mono } from "next/font/google";
import localFont from "next/font/local";
import Script from "next/script";
import type { ComponentType, ReactElement, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { appConfig } from "@/constants";
import {
  AnalyticsProvider,
  FeatureFlagProvider,
  FiatPricingProvider,
  ImmutableProvider,
  PassportProvider,
  QueryProvider,
  WagmiProvider,
} from "@/context";
import { BrazeProvider } from "@/context/BrazeProvider";
import { CheckoutProvider } from "@/context/CheckoutProvider";
import { PreferencesProvider } from "@/context/PreferencesProvider";

import { AssetImportProvider } from "@/context/AssetImportProvider";
import { CommerceProvider } from "@/context/CommerceProvider";
import { Erc20Provider } from "@/context/Erc20Provider";
import { OrderbookProvider } from "@/context/OrderbookProvider";
import { SdkModuleProvider } from "@/hooks/sdk-module";
import ErrorComponent from "./_error";

export const breakpoint = {
  small: 430,
  medium: 768,
  large: 905,
  xLarge: 1240,
  xxLarge: 1440,
  xxxLarge: 1920,
};

export const bodyPrimary = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const bodySecondary = Roboto_Mono({
  weight: ["400", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const headingPrimary = localFont({
  display: "swap",
  src: [
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
});

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

// NewRelic Subresource Integrity hashes. Produce them like such:
// openssl dgst -sha512 -binary public/newrelic-sandbox.js | openssl base64 -A
const newRelicIntegritySHAs = {
  [EnvironmentNames.DEV]:
    "sha512-scp0QkY+FyqeKX04HutNsZW3iUBuUnMs3Vk8tZ3vsOD7USyL/Ac9xrN6aMPJKf02c68r0D05+tTGnAypG+dh2w==",
  [EnvironmentNames.SANDBOX]:
    "sha512-k9BKfmnSAkM/Tj804WWwhjraPrIAZTwdJUiZQPMvWHuO3YihDHb9I0x2UioFQTkkaZT27xVi1SgPWH4F3ZDgQg==",
  [EnvironmentNames.PRODUCTION]:
    "sha512-NQgjogwsD+zJOdEIOkDFbW2fvGLKVShTZQqjmoKny6ccrr7LJLCEGFEMS4Cq/jPsQT+CjkYmpukwU5kbIFgYpg==",
};

function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();
  // @ts-ignore
  const getLayout = Component.getLayout ?? ((page) => page);
  const newRelicIntegritySHA = newRelicIntegritySHAs[appConfig.ENVIRONMENT];

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <AnalyticsProvider>
        <ImmutableProvider>
          <SdkModuleProvider>
            <PassportProvider>
              <CheckoutProvider>
                <CommerceProvider>
                  <WagmiProvider>
                    <OrderbookProvider>
                      <QueryProvider>
                        <PreferencesProvider>
                          <BrazeProvider>
                            <FeatureFlagProvider>
                              <FiatPricingProvider>
                                <Erc20Provider>
                                  <BiomeCombinedProviders
                                    skipFontLoad
                                    globalSx={{
                                      "html, body": {
                                        p: "0",
                                        m: "0",
                                        bg: "base.color.neutral.1000",
                                        h: "100dvh",
                                      },

                                      "#__next": {
                                        w: "100%",
                                        h: "100%",
                                      },
                                    }}
                                    theme={{
                                      base: {
                                        ...onDarkBase,
                                        font: {
                                          ...onDarkBase.font,
                                          family: {
                                            heading: {
                                              primary:
                                                headingPrimary.style.fontFamily,
                                              secondary:
                                                headingPrimary.style.fontFamily,
                                            },
                                            body: {
                                              primary:
                                                bodyPrimary.style.fontFamily,
                                              secondary:
                                                bodySecondary.style.fontFamily,
                                            },
                                          },
                                        },
                                        breakpoint,
                                      },
                                      globalConfig: {
                                        imageResizeServiceUrl:
                                          appConfig.IMAGE_RESIZER_URL,
                                      },
                                    }}
                                  >
                                    <Script
                                      id="newrelic-script"
                                      src={`/newrelic-${appConfig.ENVIRONMENT}.js`}
                                      integrity={newRelicIntegritySHA}
                                      onReady={() => {
                                        // @ts-ignore
                                        window.newrelic.setErrorHandler(
                                          (err: {
                                            stack?: string | string[];
                                          }) =>
                                            !!err.stack?.includes(
                                              "chrome-extension",
                                            ),
                                        );
                                      }}
                                    />
                                    <AssetImportProvider>
                                      {getLayout(<Component {...pageProps} />)}
                                    </AssetImportProvider>
                                  </BiomeCombinedProviders>
                                </Erc20Provider>
                              </FiatPricingProvider>
                            </FeatureFlagProvider>
                          </BrazeProvider>
                        </PreferencesProvider>
                      </QueryProvider>
                    </OrderbookProvider>
                  </WagmiProvider>
                </CommerceProvider>
              </CheckoutProvider>
            </PassportProvider>
          </SdkModuleProvider>
        </ImmutableProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

export default withLDProvider({
  clientSideID: appConfig.LAUNCH_DARKLY_CLIENT_ID,
})(App as ComponentType<unknown>);
