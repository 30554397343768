import {
  Body,
  Box,
  ButtCon,
  Button,
  Heading,
  MenuItem,
  Stack,
} from "@biom3/react";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { walletIconProps } from "@/components/wallets/utils/walletIconParams";
import { BAD_IMAGE_URL, MODAL_WIDTH } from "@/constants";
import { useAnalytics } from "@/context";
import type { ActiveAssetImport } from "@/context/AssetImportProvider";
import type { ItemDetails, Wallet } from "@/types";
import { toSimpleAddressFormat } from "@/utils/util";

const Address = ({
  labelKey,
  wallet,
}: {
  labelKey: string;
  wallet: Wallet;
}) => {
  const { alt, imagePath, testId } = walletIconProps(wallet?.type);
  return (
    <Box
      sx={{
        d: "flex",
        flexDirection: "row",
        padding: "base.spacing.x4",
      }}
    >
      <Body color={"base.color.text.secondary"} sx={{ w: "base.spacing.x20" }}>
        {" "}
        <Trans i18nKey={labelKey} />
      </Body>
      <Box sx={{ pr: "base.spacing.x2" }} testId={testId}>
        <Image alt={alt} src={imagePath} width={16} height={16} />
      </Box>
      <Body>{toSimpleAddressFormat(wallet.address)}</Body>
    </Box>
  );
};

type ItemImportProps = {
  items: ItemDetails[];
  source: Wallet;
  destination: Wallet;
  activeImport: ActiveAssetImport | undefined;
  onConfirm: (items: ItemDetails[]) => void;
  onClose: () => void;
};

export const ReviewImportItems = ({
  source,
  destination,
  activeImport,
  onConfirm,
  onClose,
  items: incomingItems,
}: ItemImportProps) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [items, setItems] = useState<ItemDetails[]>([]);

  useEffect(() => setItems(incomingItems), [incomingItems]);

  const unselectItem = (unselectedItem: ItemDetails) => {
    if (items.length === 1) {
      onClose();
    } else {
      setItems(
        items.filter(
          (item) =>
            item.tokenId + item.collectionName !==
            unselectedItem.tokenId + unselectedItem.collectionName,
        ),
      );
    }
  };

  if (items.length === 0) {
    return (
      <Box testId="empty">
        <Image
          priority
          src="/emptyInventoryState.svg"
          alt="empty state"
          fill
          sizes="(max-width: 400px) 189.383px, 400.819px"
        />
      </Box>
    );
  }

  return (
    <Box
      testId="item-import"
      sx={{
        d: "flex",
        flexDirection: "column",
        px: "base.spacing.x4",
        pb: "base.spacing.x8",
        background: "base.color.neutral.900",
        borderRadius: "base.borderRadius.x4",
        boxShadow: "base.shadow.400",
        width: {
          default: "calc(100dvw - 32px)",
          medium: MODAL_WIDTH,
          large: "480px",
        },
        height: "auto",
        minh: "650px",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ my: "base.spacing.x4", ml: "base.spacing.x2" }}
      >
        <Heading size={"small"}>{t("asset_transfer_modal_title")}</Heading>
        <ButtCon
          icon="Close"
          variant={"tertiary"}
          sx={{}}
          onClick={() => {
            track({
              screen: "ReviewImportItems",
              userJourney: "AssetTransfer",
              control: "Close",
              controlType: "Button",
              action: "Pressed",
              extras: { flowId: activeImport?.trackingId },
            });
            onClose();
          }}
        />
      </Stack>

      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          borderRadius: "base.borderRadius.x8",
          backgroundColor: "base.color.neutral.800",
        }}
      >
        <Address labelKey={"source"} wallet={source} />
        <Box
          sx={{
            h: "1px",
            w: "100%",
            backgroundColor: "base.color.neutral.900",
          }}
        />
        <Address labelKey={"destination"} wallet={destination} />
        <Box
          sx={{
            h: "1px",
            w: "100%",
            backgroundColor: "base.color.neutral.900",
          }}
        />
        <Box
          testId="items-list"
          sx={{
            m: "base.spacing.x1",
            backgroundColor: "base.color.translucent.standard.100",
            borderRadius: "base.borderRadius.x8",
            overflowY: "scroll",
            maxHeight: "260px",
          }}
        >
          {items.map((item) => (
            <MenuItem key={item.tokenId}>
              <MenuItem.FramedImage imageUrl={item.image ?? BAD_IMAGE_URL} />
              <MenuItem.Label>{item.name}</MenuItem.Label>
              <MenuItem.Caption>{item.collectionName}</MenuItem.Caption>
              <MenuItem.StatefulButtCon
                icon="Close"
                onClick={() => {
                  track({
                    screen: "ReviewImportItems",
                    userJourney: "AssetTransfer",
                    control: "RemoveItem",
                    controlType: "StatefulButtCon",
                    action: "Pressed",
                    extras: { flowId: activeImport?.trackingId },
                  });
                  unselectItem(item);
                }}
              >
                {t("remove")}
              </MenuItem.StatefulButtCon>
            </MenuItem>
          ))}
        </Box>
        <Box
          sx={{
            d: "flex",
            flexDirection: "row",
            justifyItems: "space-between",
            p: "base.spacing.x4",
          }}
        >
          <Body color={"base.color.text.secondary"} sx={{ w: "100%" }}>
            {t("number_of_items")}
          </Body>
          <Body testId="item_count">{items.length}</Body>
        </Box>
      </Box>

      <Box sx={{ flex: 1 }} />

      <Button
        testId="confirm-import"
        size={"large"}
        sx={{ width: "100%", mt: "base.spacing.x8" }}
        onClick={() => {
          track({
            screen: "ReviewImportItems",
            userJourney: "AssetTransfer",
            control: "Confirm",
            controlType: "Button",
            action: "Pressed",
            extras: { flowId: activeImport?.trackingId },
          });
          onConfirm(items);
        }}
      >
        {t("confirm_import", { source })}
      </Button>
    </Box>
  );
};
