import { appConfig } from "@/constants";
import { useFiatPricingContext } from "@/context";
import type { ChainAddress, Erc20Token, ItemDetails } from "@/types";
import {
  Body,
  Box,
  FormControl,
  Heading,
  InputGroup,
  NumberStepper,
  Select,
  TextInput,
} from "@biom3/react";
import BigNumber from "bignumber.js";
import { utils } from "ethers";
import { useEffect, useMemo, useRef } from "react";
import {
  type Control,
  type FieldErrors,
  type UseFormClearErrors,
  type UseFormSetError,
  type UseFormSetValue,
  useController,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormValues = {
  buyItemAmount: string;
  payoutItemAmount: string;
  sellItemQty: `${number}`;
  buyCurrencyType: string;
  payoutCurrencyType: string;
};

type ListingFormProps = {
  item: ItemDetails | undefined;
  getTotalFee: (
    contractAddress: ChainAddress,
    tokenId: string,
    tokenType: string,
    salePrice: string,
  ) => Promise<string>;
  control: Control<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  errors: FieldErrors<FormValues>;
  setError: UseFormSetError<FormValues>;
  clearErrors: UseFormClearErrors<FormValues>;
  buyToken: Erc20Token | undefined;
  payoutToken: Erc20Token | undefined;
  tokenDetails?: Erc20Token[];
};

export const ListingItemForm = ({
  item,
  getTotalFee,
  control,
  setValue,
  errors,
  setError,
  clearErrors,
  buyToken,
  payoutToken,
  tokenDetails,
}: ListingFormProps) => {
  const { t } = useTranslation();

  const collection = item?.collection ?? ("" as ChainAddress);
  const tokenId = item?.tokenId ?? "";
  const tokenType = item?.tokenType ?? "ERC721";
  const balance = item?.balance ?? "1";

  const { getFormattedPrice } = useFiatPricingContext();
  const decimals = buyToken?.decimals || 18;

  const { field: buyItemAmountField } = useController({
    name: "buyItemAmount",
    control,
    rules: {
      required: "Price is required",
      validate: (value) => {
        if (value === "" || Number.isNaN(Number(value)) || Number(value) <= 0) {
          return "Price must be greater than zero";
        }
        if (balance !== undefined) {
          const userBalance = Number(balance);
          if (Number.isNaN(userBalance)) {
            return "Invalid balance value";
          }
        }
        return true;
      },
    },
  });

  const { field: sellItemQtyField } = useController({
    name: "sellItemQty",
    control,
    rules:
      tokenType === "ERC1155"
        ? {
            required: "Quantity is required",
            validate: (value) => {
              if (Number.isNaN(Number(value)) || Number(value) <= 0) {
                return "Quantity must be a positive number";
              }
              if (balance !== undefined) {
                const userBalance = Number(balance);
                const qty = Number(value);
                if (Number.isNaN(userBalance)) {
                  return "Invalid balance value";
                }
                if (qty > userBalance) {
                  return `Quantity cannot be greater than your balance ${userBalance}`;
                }
              }
              return true;
            },
          }
        : undefined,
  });

  const { field: payoutItemAmountField } = useController({
    name: "payoutItemAmount",
    control,
  });

  const { field: buyItemTypeField } = useController({
    name: "buyCurrencyType",
    control,
  });

  const { field: payoutItemTypeField } = useController({
    name: "payoutCurrencyType",
    control,
  });

  const buyItemAmountValue = useWatch({ control, name: "buyItemAmount" });
  const payoutItemAmountValue = useWatch({ control, name: "payoutItemAmount" });

  const prevBuyItemAmount = useRef<string>("");
  const prevPayoutItemAmount = useRef<string>("");
  const updatingFieldRef = useRef<"buyItemAmount" | "payoutItemAmount" | null>(
    null,
  );

  useEffect(() => {
    const calculateValues = async () => {
      if (
        buyItemAmountValue !== prevBuyItemAmount.current &&
        updatingFieldRef.current !== "payoutItemAmount"
      ) {
        updatingFieldRef.current = "buyItemAmount";
        if (buyItemAmountValue && buyItemAmountValue !== "0") {
          try {
            const amountInSmallestUnit = utils
              .parseUnits(buyItemAmountValue, decimals)
              .toString();

            const fee = await getTotalFee(
              collection,
              tokenId,
              tokenType,
              amountInSmallestUnit,
            );

            const amountInBigInt = BigInt(amountInSmallestUnit);
            const feeAmount = fee ? BigInt(fee) : BigInt(0);
            const amountWithoutFee = amountInBigInt - feeAmount;

            if (amountWithoutFee <= 0n) {
              setValue("payoutItemAmount", "0");
              setError("buyItemAmount", {
                type: "manual",
                message: "Amount too small to cover fees",
              });
            } else {
              const amountWithoutFeeInUnits = utils.formatUnits(
                amountWithoutFee.toString(),
                decimals,
              );
              setValue("payoutItemAmount", amountWithoutFeeInUnits, {
                shouldValidate: false,
              });
              clearErrors("buyItemAmount");
            }
          } catch (error) {
            console.error("Error calculating payout amount:", error);
          }
        } else {
          setValue("payoutItemAmount", "");
        }
        prevBuyItemAmount.current = buyItemAmountValue;
      } else if (
        payoutItemAmountValue !== prevPayoutItemAmount.current &&
        updatingFieldRef.current !== "buyItemAmount"
      ) {
        updatingFieldRef.current = "payoutItemAmount";
        if (payoutItemAmountValue && payoutItemAmountValue !== "0") {
          try {
            const payoutAmountInSmallestUnit = utils
              .parseUnits(payoutItemAmountValue, decimals)
              .toString();

            const fee = await getTotalFee(
              collection,
              tokenId,
              tokenType,
              payoutAmountInSmallestUnit,
            );

            const amountInBigInt = BigInt(payoutAmountInSmallestUnit);
            const feeAmount = fee ? BigInt(fee) : BigInt(0);
            const amountWithFee = amountInBigInt + feeAmount;

            const amountWithFeeInUnits = utils.formatUnits(
              amountWithFee.toString(),
              decimals,
            );
            setValue("buyItemAmount", amountWithFeeInUnits, {
              shouldValidate: false,
            });
          } catch (error) {
            console.error("Error calculating buy amount:", error);
          }
        } else {
          setValue("buyItemAmount", "");
        }
        prevPayoutItemAmount.current = payoutItemAmountValue;
      }
    };

    calculateValues();
  }, [
    buyItemAmountValue,
    payoutItemAmountValue,
    collection,
    decimals,
    getTotalFee,
    tokenId,
    tokenType,
    setValue,
    setError,
    clearErrors,
  ]);

  const selectedToken = useMemo(() => {
    return tokenDetails?.find(
      (token) => token.symbol === buyItemTypeField.value,
    );
  }, [buyItemTypeField.value, tokenDetails]);

  const fiatEquivalentPrice = useMemo(() => {
    if (!buyItemAmountField.value) return t("usd_value_zero");

    const amount = new BigNumber(buyItemAmountField.value);

    return getFormattedPrice(
      buyItemTypeField.value,
      decimals,
      utils.parseUnits(amount.toFixed(decimals), decimals).toString(),
    );
  }, [
    buyItemAmountField.value,
    buyItemTypeField.value,
    getFormattedPrice,
    decimals,
    t,
  ]);

  const fiatEquivalentPayoutPrice = useMemo(() => {
    if (!payoutItemAmountField.value || !payoutToken)
      return t("usd_value_zero");

    const amount = new BigNumber(payoutItemAmountField.value);

    return getFormattedPrice(
      payoutToken.symbol,
      decimals,
      utils.parseUnits(amount.toFixed(decimals), decimals).toString(),
    );
  }, [
    payoutItemAmountField.value,
    payoutToken,
    getFormattedPrice,
    decimals,
    t,
  ]);

  const fiatEquivalentTotalPayoutPrice = useMemo(() => {
    if (!payoutItemAmountField.value || !sellItemQtyField.value || !payoutToken)
      return t("usd_value_zero");

    const totalAmount = new BigNumber(payoutItemAmountField.value).multipliedBy(
      sellItemQtyField.value,
    );

    return getFormattedPrice(
      payoutToken.symbol,
      decimals,
      utils.parseUnits(totalAmount.toFixed(decimals), decimals).toString(),
    );
  }, [
    payoutItemAmountField.value,
    sellItemQtyField.value,
    payoutToken,
    getFormattedPrice,
    decimals,
    t,
  ]);

  return (
    <>
      <FormControl validationStatus={errors.sellItemQty ? "error" : undefined}>
        {item?.tokenType === "ERC1155" && item.balance !== "1" && (
          <>
            <FormControl.Label>
              {t("listing_form.labels.quantity")}
            </FormControl.Label>
            <NumberStepper
              value={sellItemQtyField.value}
              onChange={(newValue) => {
                // Numberstepper doesn't support disabled state yet
                const min = 1;
                const max = Number(balance);
                const numericValue = Number(newValue);
                if (numericValue >= min && numericValue <= max) {
                  sellItemQtyField.onChange(newValue);
                }
              }}
              onBlur={sellItemQtyField.onBlur}
              min="1"
              max={balance as `${number}`}
            />
          </>
        )}
        {errors.sellItemQty && (
          <FormControl.Validation>
            {errors.sellItemQty.message}
          </FormControl.Validation>
        )}
      </FormControl>
      {item?.tokenType === "ERC1155" && (
        <Heading size="xSmall" sx={{ py: "base.spacing.x4" }}>
          {t("listing_form.labels.set_price_per_item")}
        </Heading>
      )}
      <FormControl
        validationStatus={
          errors.buyItemAmount || errors.sellItemQty ? "error" : undefined
        }
      >
        <FormControl.Label size="medium">
          {t("listing_form.labels.buyer_will_pay")}
        </FormControl.Label>
        <InputGroup>
          <InputGroup.Row>
            <Select
              selectedOption={buyItemTypeField.value}
              onSelectChange={(val) => {
                setValue("buyCurrencyType", val as string);
                setValue("payoutCurrencyType", val as string);
                buyItemTypeField.onChange(val);
                payoutItemTypeField.onChange(val);
              }}
              size="medium"
            >
              {tokenDetails?.map((token) => (
                <Select.Option
                  key={token.symbol}
                  optionKey={token.symbol ?? ""}
                >
                  <Select.Option.FramedImage
                    defaultImageUrl={`${appConfig.TOKEN_ICONS_URL}/defaultondark.svg`}
                    imageUrl={
                      token.symbol?.toLowerCase() === "imx"
                        ? `${appConfig.TOKEN_ICONS_URL}/imx.svg`
                        : `${appConfig.TOKEN_ICONS_URL}/${token.contract_address.toLowerCase()}.svg`
                    }
                    circularFrame
                  />
                  <Select.Option.Label>{token.symbol}</Select.Option.Label>
                </Select.Option>
              ))}
            </Select>
            <TextInput
              value={buyItemAmountField.value}
              onChange={(event) => {
                const value = event.target.value;
                if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
                  buyItemAmountField.onChange(value);
                } else if (value === "") {
                  buyItemAmountField.onChange("");
                }
              }}
              onBlur={buyItemAmountField.onBlur}
              onClearValue={() => setValue("buyItemAmount", "")}
              placeholder={t("listing_form.labels.enter_amount")}
            />
          </InputGroup.Row>
        </InputGroup>
        {errors.buyItemAmount && (
          <FormControl.Validation>
            {errors.buyItemAmount.message}
          </FormControl.Validation>
        )}
        {!errors.buyItemAmount && !errors.sellItemQty && (
          <FormControl.Caption sx={{ textAlign: "end" }}>
            {fiatEquivalentPrice || t("usd_value_zero")}
          </FormControl.Caption>
        )}
      </FormControl>

      <Body>{t("listing_form.labels.you_will_get")}</Body>
      <InputGroup>
        <InputGroup.Row>
          <TextInput
            value={selectedToken?.symbol}
            disabled={true}
            placeholder={t("listing_form.labels.enter_amount")}
            hideClearValueButton={true}
            sx={{ pr: "base.spacing.x13" }}
          >
            <TextInput.FramedImage
              defaultImageUrl={`${appConfig.TOKEN_ICONS_URL}/defaultondark.svg`}
              imageUrl={
                selectedToken?.symbol?.toLowerCase() === "imx"
                  ? `${appConfig.TOKEN_ICONS_URL}/imx.svg`
                  : `${appConfig.TOKEN_ICONS_URL}/${selectedToken?.contract_address.toLowerCase()}.svg`
              }
              circularFrame
            />
          </TextInput>
          <TextInput
            value={payoutItemAmountField.value}
            disabled={true}
            placeholder={t("listing_form.labels.enter_amount")}
            hideClearValueButton={true}
          />
        </InputGroup.Row>
      </InputGroup>
      <Body
        sx={{
          d: "flex",
          justifyContent: "end",
          pt: "base.spacing.x1",
          color: "var(--color-text-body-secondary, #B6B6B6)",
        }}
        size="small"
      >
        {fiatEquivalentPayoutPrice || t("usd_value_zero")}
      </Body>

      {item?.tokenType === "ERC1155" && (
        <>
          <Box
            sx={{
              d: "flex",
              justifyContent: "space-between",
              pt: "base.spacing.x6",
            }}
          >
            <Body>{t("listing_form.labels.total_potential_earnings")}</Body>
            <Body>
              {buyItemTypeField.value} {(() => {
                const payoutAmount = new BigNumber(
                  payoutItemAmountField.value || "0",
                );
                const sellQuantity = new BigNumber(
                  sellItemQtyField.value || "1",
                );
                const totalAmount = payoutAmount.multipliedBy(sellQuantity);

                const totalAmountInSmallestUnit = utils
                  .parseUnits(totalAmount.toFixed(decimals), decimals)
                  .toString();

                return utils.formatUnits(totalAmountInSmallestUnit, decimals);
              })()}
            </Body>
          </Box>
          <Body
            sx={{
              d: "flex",
              justifyContent: "end",
              color: "var(--color-text-body-secondary, #B6B6B6)",
            }}
            size="small"
          >
            {fiatEquivalentTotalPayoutPrice || t("usd_value_zero")}
          </Body>
        </>
      )}
    </>
  );
};
