import {
  Body,
  Button,
  MenuItem,
  type MenuItemSize,
  Popover,
  type SxProps,
} from "@biom3/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  type DashboardAnalyticsBaseType,
  useAnalytics,
  usePassportProvider,
} from "@/context";
import { usePreferences } from "@/context/PreferencesProvider";

type CopyWalletAddressProps = {
  size?: MenuItemSize;
  sx?: SxProps;
};

const copyWalletAddressAnalytics: DashboardAnalyticsBaseType = {
  userJourney: "Wallets",
  screen: "CopyWalletAddress",
};

type CopyWalletAddressComponentProps = CopyWalletAddressProps & {
  isCopied?: boolean;
  isWalletAddressVisible: boolean;
  onClear: () => void;
  onCopy: () => void;
  onShowWalletAddressClick: () => void;
  walletAddress: string;
};

export const CopyWalletAddressComponent = ({
  onCopy,
  onClear,
  size,
  sx,
  isWalletAddressVisible,
  isCopied,
  onShowWalletAddressClick,
  walletAddress,
}: CopyWalletAddressComponentProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!isWalletAddressVisible && (
        <Button
          testId="CopyWalletAddress_ShowAddressButton"
          variant="primary"
          onClick={onShowWalletAddressClick}
          sx={{
            mb: "base.spacing.x24",
            ...sx,
          }}
          size="large"
        >
          {t("show_address")}
        </Button>
      )}
      {isWalletAddressVisible && (
        <MenuItem
          testId="CopyWalletAddress_MenuItem"
          emphasized
          sx={{ mb: "base.spacing.x24", maxWidth: "786px" }}
          size={size}
        >
          <MenuItem.FramedLogo
            logo="PassportSymbolOutlined"
            sx={{ backgroundColor: "transparent" }}
            size="small"
          />
          <MenuItem.Label>{t("passport_l2_wallet_address")}</MenuItem.Label>
          <MenuItem.Caption testId="CopyWalletAddress_WalletAddress">
            {walletAddress}
          </MenuItem.Caption>
          <Popover
            position={{ x: "center", y: "above" }}
            visible={isCopied}
            onOutsideClick={onClear}
          >
            <Popover.Target>
              <MenuItem.StatefulButtCon
                testId="CopyWalletAddress_CopyButton"
                icon="CopyText"
                onClick={onCopy}
                state={isCopied ? "success" : "initial"}
                disabled={walletAddress === ""}
              />
            </Popover.Target>
            <Popover.Content
              sx={{
                bg: "base.color.neutral.500",
                px: "base.spacing.x4",
                py: "base.spacing.x3",
                borderRadius: "base.borderRadius.x4",
                textAlign: "center",
                boxShadow: "base.shadow.500",
              }}
            >
              <Body
                testId="CopyWalletAddress_CopiedText"
                color="base.color.brand.1"
                sx={{
                  fontSize: "base.text.body.small.regular.fontSize",
                }}
              >
                {t("copied")}
              </Body>
            </Popover.Content>
          </Popover>
        </MenuItem>
      )}
    </>
  );
};

export function CopyWalletAddress({ sx, size }: CopyWalletAddressProps) {
  const { walletAddress } = usePassportProvider();

  const { data, update } = usePreferences();

  const { track, page } = useAnalytics();

  const [isWalletAddressVisible, setWalletAddressVisible] = useState(false);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setWalletAddressVisible(!!data?.reveal_address ?? true);
  }, [data]);

  const onCopy = () => {
    if (walletAddress) {
      track({
        ...copyWalletAddressAnalytics,
        control: "Copy",
        controlType: "Button",
        action: "Pressed",
      });
      navigator.clipboard.writeText(walletAddress);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const onShowWalletAddressClick = () => {
    track({
      ...copyWalletAddressAnalytics,
      control: "Show",
      controlType: "Button",
      action: "Pressed",
    });
    update({ ...data, reveal_address: true });
    setWalletAddressVisible(true);
  };

  if (!walletAddress) {
    return <></>;
  }

  return (
    <CopyWalletAddressComponent
      isCopied={copied}
      isWalletAddressVisible={isWalletAddressVisible}
      onClear={() => setCopied(false)}
      onCopy={onCopy}
      onShowWalletAddressClick={onShowWalletAddressClick}
      size={size}
      sx={sx}
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      walletAddress={walletAddress!}
    />
  );
}
