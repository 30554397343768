import { Box } from "@biom3/react";
import type { PropsWithChildren } from "react";

export function AppToolbarLeftSlot({ children }: PropsWithChildren) {
  return (
    <Box
      testId="AppToolBarLeftSlot"
      sx={{
        flex: 1,
        d: "flex",
        alignItems: "center",
        gap: "base.spacing.x4",
        minh: "48px",
      }}
    >
      {children}
    </Box>
  );
}

AppToolbarLeftSlot.displayName = "AppToolbar.LeftSlot";
