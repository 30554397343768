import { BAD_IMAGE_URL, MODAL_WIDTH } from "@/constants";
import { useAnalytics } from "@/context";
import { Box, ConfirmationDialog } from "@biom3/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type ListingStatusModalProps = {
  state?: "SUCCESS" | "ERROR" | "SUBMITTING";
  errorMessage?: string;
  successMessage?: string;
  onRetry?: () => void;
  onClose?: () => void;
  itemName?: string;
  itemImage?: string;
};

type ImageWithOverlayProps = {
  src: string;
  alt: string;
  overlaySrc?: string;
  width?: string;
  height?: string;
  mt?: string;
};

const overlayImage = "/listingErrorModal.svg";
const defaultImage =
  "https://biome.immutable.com/hosted-assets/images/cloudImageDefaultImage--onDark.svg";

const ImageWithOverlay = ({
  src,
  alt,
  overlaySrc,
  width,
  height,
  mt,
}: ImageWithOverlayProps) => {
  const [mainImageSrc, setMainImageSrc] = useState<string>(src);

  return (
    <Box
      sx={{
        position: "relative",
        width: "128px",
        height: "128px",
        mt: mt,
        mb: "base.spacing.x3",
      }}
    >
      <img
        src={mainImageSrc}
        alt={alt}
        width={width}
        height={height}
        style={{ borderRadius: 8 }}
        onError={() => {
          setMainImageSrc(defaultImage);
        }}
      />
      {overlaySrc && (
        <img
          src={overlaySrc}
          alt="Overlay"
          style={{
            position: "absolute",
            top: 55,
            left: 90,
            width: 77,
            height: 95,
          }}
        />
      )}
    </Box>
  );
};

export const ListingItemStatusModal = ({
  state,
  onRetry,
  onClose,
  itemName,
  itemImage,
}: ListingStatusModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { track } = useAnalytics();

  useEffect(() => {
    const open = state !== undefined;
    setIsModalOpen(open);

    if (open) {
      let control: string;
      switch (state) {
        case "SUCCESS":
          control = "ListItemSuccess";
          break;
        case "ERROR":
          control = "ListItemFailure";
          break;
        default:
          control = "ListItemSubmitting";
          break;
      }
      track({
        userJourney: "Listing",
        screen: "ListingStatusModal",
        control: control,
        controlType: "Effect",
      });
    }
  }, [state, track]);

  const handleClose = useCallback(() => {
    track({
      userJourney: "Listing",
      screen: "ListingStatusModal",
      control: "Close",
      controlType: "Button",
      action: "Pressed",
    });

    if (onClose) onClose();
    setIsModalOpen(false);
  }, [onClose, track]);

  const handleRetry = useCallback(() => {
    track({
      userJourney: "Listing",
      screen: "ListingStatusModal",
      control: "Retry",
      controlType: "Button",
      action: "Pressed",
    });

    if (onRetry) onRetry();
  }, [onRetry, track]);

  const modalContent = useMemo(() => {
    switch (state) {
      case "SUCCESS":
        return {
          title: t("listing_success_title", { itemName }),
          description: t("listing_success_description"),
          imageSrc: itemImage,
          primaryButton: {
            text: t("Close"),
            action: handleClose,
          },
          mt: "base.spacing.x30",
        };
      case "ERROR":
        return {
          title: t("listing_error_title", { itemName }),
          description: t("listing_error_description"),
          imageSrc: itemImage,
          primaryButton: onRetry
            ? {
                text: t("Retry"),
                action: handleRetry,
              }
            : undefined,
          secondaryButton: {
            text: t("Close"),
            action: handleClose,
          },
          mt: "base.spacing.x20",
        };
      case "SUBMITTING":
        return {
          imageSrc: itemImage,
          title: t("listing_submitting_title"),
          mt: "base.spacing.x20",
        };
      default:
        return undefined;
    }
  }, [state, itemName, itemImage, t, handleClose, handleRetry, onRetry]);

  if (!modalContent) return null;

  return (
    <ConfirmationDialog visible={isModalOpen} outsideClicksClose={false}>
      <ConfirmationDialog.Content
        sx={{
          d: "flex",
          width: MODAL_WIDTH,
          height: "650px",
          alignItems: "center",
        }}
      >
        <ConfirmationDialog.Content.Image
          alt={modalContent.title}
          use={
            <ImageWithOverlay
              src={modalContent.imageSrc ?? BAD_IMAGE_URL}
              alt={modalContent.title}
              overlaySrc={state === "ERROR" ? overlayImage : undefined}
              width="128px"
              height="128px"
              mt={modalContent.mt}
            />
          }
        />
        <ConfirmationDialog.Content.Title sx={{ px: "base.spacing.x12" }}>
          {modalContent.title}
        </ConfirmationDialog.Content.Title>
        {modalContent.description && (
          <ConfirmationDialog.Content.Caption sx={{ px: "base.spacing.x12" }}>
            {modalContent.description}
          </ConfirmationDialog.Content.Caption>
        )}
        {modalContent.primaryButton && (
          <ConfirmationDialog.Content.Button
            size="large"
            variant="primary"
            onClick={modalContent.primaryButton.action}
          >
            {modalContent.primaryButton.text}
          </ConfirmationDialog.Content.Button>
        )}
        {modalContent.secondaryButton && (
          <ConfirmationDialog.Content.Button
            size="large"
            variant="tertiary"
            onClick={modalContent.secondaryButton.action}
          >
            {modalContent.secondaryButton.text}
          </ConfirmationDialog.Content.Button>
        )}
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
};
