import { CarouselThumbnail, type VideoMimeTypes } from "@biom3/react";
import type { EmblaCarouselType } from "embla-carousel";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";

export type MediaCarouselSlide = {
  video_url?: string;
  video_mime_type?: string;
  image: {
    url: string;
    alt?: string;
  };
};

export type MediaCarouselProps = {
  slides: MediaCarouselSlide[];
  onSlideChange?: (url: string) => void;
};

export const MediaCarousel = ({
  slides,
  onSlideChange,
}: MediaCarouselProps) => {
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);

  const handleSlideChange = () => {
    const index = emblaApi?.selectedScrollSnap() || 0;
    const url = slides?.[index].image.url;
    url && onSlideChange?.(url);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    emblaApi?.on("select", handleSlideChange);

    return () => {
      emblaApi?.off("select", handleSlideChange);
    };
  }, [emblaApi]);

  return (
    <CarouselThumbnail getApi={setEmblaApi}>
      {slides.map((slide, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Fragment key={index}>
          <CarouselThumbnail.Slide hideGradientSkrim>
            {slide.video_url && slide.video_mime_type ? (
              <CarouselThumbnail.Slide.AssetVideo
                videoUrl={slide.video_url}
                mimeType={slide.video_mime_type as VideoMimeTypes}
              />
            ) : slide.image.url ? (
              <CarouselThumbnail.Slide.AssetImage
                loading="eager"
                imageUrl={slide.image.url}
                relativeImageSizeInLayout="80vw"
              />
            ) : null}
          </CarouselThumbnail.Slide>
          <CarouselThumbnail.Thumb>
            {slide.image.url && (
              <CarouselThumbnail.Thumb.BgImage
                imageUrl={slide.image.url}
                loading="eager"
              />
            )}
          </CarouselThumbnail.Thumb>
        </Fragment>
      ))}
    </CarouselThumbnail>
  );
};

export default MediaCarousel;
