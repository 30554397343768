import Head from "next/head";

type Props = {
  title: string;
  url: string;
  description?: string;
  image?: string;
};

export default function SEO({ title, description, url, image }: Props) {
  return (
    <Head>
      <title>{title || "Immutable Play"}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
    </Head>
  );
}
