import { MODAL_WIDTH } from "@/constants";
import { useAnalytics } from "@/context";
import { ConfirmationDialog } from "@biom3/react";
import { useTranslation } from "react-i18next";

type HideOfferModalProps = {
  visible: boolean | undefined;
  bidIds: string[] | null;
  onHide: (bidIds: string[]) => void;
  onClose: () => void;
};

export const HideOfferModal = ({
  visible,
  bidIds,
  onHide,
  onClose,
}: HideOfferModalProps) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const handleHide = () => {
    track({
      userJourney: "Bid",
      screen: "HideOfferModal",
      controlType: "Button",
      control: "HideOffer",
      action: "Pressed",
    });
    if (bidIds) {
      onHide(bidIds);
    }
  };
  return (
    <ConfirmationDialog visible={visible} outsideClicksClose={false}>
      <ConfirmationDialog.Content
        sx={{
          d: "flex",
          width: MODAL_WIDTH,
          height: "650px",
          alignItems: "center",
        }}
      >
        <ConfirmationDialog.Content.DuoCon
          icon="Exclamation"
          variant="bold"
          iconVariant="bold"
          sx={{ mt: "base.spacing.x2" }}
        />
        <ConfirmationDialog.Content.Title sx={{ px: "base.spacing.x12" }}>
          {t("hide_offer_title")}
        </ConfirmationDialog.Content.Title>
        <ConfirmationDialog.Content.Caption
          sx={{
            px: "base.spacing.x8",
            color: "base.color.text.body.secondary",
            mt: "base.spacing.x2",
          }}
        >
          {t("hide_offer_description")}
        </ConfirmationDialog.Content.Caption>
        <ConfirmationDialog.Content.Button
          size="large"
          variant="primary"
          onClick={handleHide}
        >
          {t("hide_offer")}
        </ConfirmationDialog.Content.Button>
        <ConfirmationDialog.Content.Button
          size="large"
          variant="tertiary"
          onClick={onClose}
        >
          {t("close")}
        </ConfirmationDialog.Content.Button>
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
};
