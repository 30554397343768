import type { ListForSaleData } from "@/components/collections/CollectionItems";
import { ListingItemForm } from "@/components/collections/ListingItemForm";
import { ListingItemStatusModal } from "@/components/collections/ListingItemStatusModal";
import { Modal } from "@/components/modal";
import { BAD_IMAGE_URL, MODAL_WIDTH } from "@/constants";
import { useAnalytics, useOrderbook, usePassportProvider } from "@/context";
import { useErc20 } from "@/context/Erc20Provider";
import { Body, Box, Button, Divider, FramedImage, Heading } from "@biom3/react";
import { BigNumber } from "bignumber.js";
import { utils } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ListItemModalProps = {
  visible: boolean;
  dismissModal: () => void;
  listForSaleData?: ListForSaleData;
  setIsListItemModalVisible: (isVisible: boolean) => void;
};

type FormValues = {
  buyItemAmount: string;
  payoutItemAmount: string;
  sellItemQty: `${number}`;
  buyCurrencyType: string;
  payoutCurrencyType: string;
};

export const ListItemModal = ({
  visible,
  dismissModal,
  listForSaleData,
  setIsListItemModalVisible,
}: ListItemModalProps) => {
  const { t } = useTranslation();
  const { walletAddress } = usePassportProvider();
  const { track } = useAnalytics();
  const { createERC721Listing, createERC1155Listing, getTotalFee } =
    useOrderbook();
  const [modalStatus, setModalStatus] = useState<{
    state: "SUCCESS" | "ERROR" | "SUBMITTING" | undefined;
    itemName: string;
    itemImage: string;
  }>({ state: undefined, itemName: "", itemImage: "" });

  const item = listForSaleData?.item;
  const {
    price: priceInfo,
    lastSoldDate: lastSoldDateInfo,
    lastSoldPrice: lastSoldPriceInfo,
  } = listForSaleData || {};
  const { image: imageSrc = BAD_IMAGE_URL, name } = item || {};

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      buyItemAmount: "",
      payoutItemAmount: "",
      sellItemQty: "1",
      buyCurrencyType: "IMX",
      payoutCurrencyType: "IMX",
    },
  });

  const { tokens } = useErc20();

  const buyCurrencyType = watch("buyCurrencyType");
  const payoutCurrencyType = watch("payoutCurrencyType");

  const tokenOptions = ["IMX", "ETH", "USDC"];

  const tokenDetails = useMemo(() => {
    return tokenOptions.map((symbol) => {
      const token = Array.from(tokens.values()).find(
        (t) => t.symbol?.toUpperCase() === symbol.toUpperCase(),
      );
      return {
        symbol: symbol.toUpperCase(),
        contract_address: token?.contract_address || "",
        decimals: token?.decimals || 18,
        name: token?.name || symbol.toUpperCase(),
      };
    });
  }, [tokens]);

  const buyToken = tokenDetails.find(
    (token) => token.symbol === buyCurrencyType,
  );
  const payoutToken = tokenDetails.find(
    (token) => token.symbol === payoutCurrencyType,
  );

  const onSubmit = async (data: FormValues) => {
    track({
      userJourney: "Listing",
      screen: "ListItemModal",
      control: "Submit",
      controlType: "Button",
      action: "Pressed",
    });

    if (!item || !walletAddress) {
      handleDismiss();
      return;
    }

    setModalStatus({
      state: "SUBMITTING",
      itemName: name || "",
      itemImage: imageSrc,
    });

    const decimals = buyToken?.decimals || 18;
    const amount = new BigNumber(data.buyItemAmount);
    const buyItemAmountInSmallestUnit = utils.parseUnits(
      amount.toFixed(decimals),
      decimals,
    );

    try {
      const buyType = data.buyCurrencyType === "IMX" ? "NATIVE" : "ERC20";
      const commonData = {
        buyCurrencyType: buyType,
        buyItemAmount: buyItemAmountInSmallestUnit.toString(),
        buyItemContractAddress: buyToken?.contract_address || "",
        makerAddress: walletAddress,
      };

      if (item.tokenType === "ERC721") {
        await createERC721Listing({
          sellAddress: item.collection,
          sellItemTokenID: item.tokenId,
          ...commonData,
        });
      } else if (item.tokenType === "ERC1155") {
        await createERC1155Listing({
          sellItemContractAddress: item.collection,
          sellItemTokenID: item.tokenId,
          sellItemQty: data.sellItemQty,
          ...commonData,
        });
      }
      setModalStatus({
        state: "SUCCESS",
        itemName: name || "",
        itemImage: imageSrc,
      });
      handleDismiss();
    } catch (e) {
      console.error("Error creating listing:", e);
      setModalStatus({
        state: "ERROR",
        itemName: name || "",
        itemImage: imageSrc,
      });
      handleDismiss();
    }
  };

  const handleDismiss = useCallback(() => {
    reset();
    dismissModal();
  }, [reset, dismissModal]);

  const renderItemDetails = useMemo(
    () => (
      <Box sx={{ d: "flex", flexDirection: "row" }}>
        <FramedImage
          imageUrl={imageSrc}
          sx={{ width: "128px", flexShrink: 0 }}
          relativeImageSizeInLayout="100vw"
        />
        <Box
          sx={{
            pl: "base.spacing.x4",
            lineHeight: "base.text.body.xSmall.regular.lineHeight",
          }}
        >
          {name && (
            <Heading size="medium" sx={{ pb: "base.spacing.x3" }}>
              {name}
            </Heading>
          )}
          <Body
            size="small"
            sx={{
              color: "base.color.text.body.secondary",
            }}
          >
            {t("listing_form.item.available_on_marketplaces")}
          </Body>
        </Box>
      </Box>
    ),
    [imageSrc, name, t],
  );

  const renderPriceInfo = useMemo(
    () => (
      <Box sx={{ py: "base.spacing.x6" }}>
        {priceInfo !== "" && (
          <Box sx={{ d: "flex", justifyContent: "space-between" }}>
            <Body>{t("listing_form.labels.floor_price")}</Body>
            <Body>{priceInfo}</Body>
          </Box>
        )}
        {lastSoldDateInfo && lastSoldPriceInfo && (
          <Box sx={{ d: "flex", justifyContent: "space-between" }}>
            <Body size="small" sx={{ color: "base.color.text.body.secondary" }}>
              {lastSoldDateInfo}
            </Body>
            <Body size="small" sx={{ color: "base.color.text.body.secondary" }}>
              {lastSoldPriceInfo}
            </Body>
          </Box>
        )}
      </Box>
    ),
    [priceInfo, lastSoldDateInfo, lastSoldPriceInfo, t],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: onSubmit can't be a dependency
  const renderModalActions = useMemo(
    () => (
      <Box sx={{ position: "sticky", bottom: "0" }}>
        <Divider sx={{ mt: "base.spacing.x2" }} size="xSmall" />
        <Box
          sx={{
            d: "flex",
            justifyContent: "center",
            gap: "base.spacing.x4",
            py: "base.spacing.x8",
            px: "base.spacing.x6",
            background: "var(--on-dark-base-color-neutrals-500, #1F1F1F)",
          }}
        >
          <Button
            sx={{ width: "50%" }}
            variant="tertiary"
            size="large"
            onClick={() => dismissModal()}
          >
            {t("listing_form.buttons.cancel")}
          </Button>
          <Button
            sx={{ width: "50%" }}
            variant="primary"
            size="large"
            type="submit"
          >
            {t("listing_form.buttons.confirm")}
          </Button>
        </Box>
      </Box>
    ),
    [dismissModal, handleSubmit, t],
  );

  const onRetry = useCallback(() => {
    setModalStatus({ state: undefined, itemName: "", itemImage: "" });
    setIsListItemModalVisible(true);
  }, [setIsListItemModalVisible]);

  const onClose = useCallback(() => {
    track({
      userJourney: "Listing",
      screen: "ListItemModal",
      control: "Close",
      controlType: "Button",
      action: "Pressed",
    });
    setModalStatus({ state: undefined, itemName: "", itemImage: "" });
    handleDismiss();
  }, [handleDismiss, track]);

  const renderStatusModal = modalStatus.state && (
    <ListingItemStatusModal
      state={modalStatus?.state}
      onRetry={onRetry}
      onClose={onClose}
      itemName={modalStatus?.itemName}
      itemImage={modalStatus?.itemImage}
    />
  );

  return (
    <>
      <Modal
        open={visible}
        rightButtCon="Close"
        onRightButtonClick={() => {
          handleDismiss();
        }}
        width={{
          default: MODAL_WIDTH,
          medium: MODAL_WIDTH,
          large: MODAL_WIDTH,
        }}
        divider={false}
        sx={{ height: "650px" }}
      >
        <Modal.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ px: "base.spacing.x6" }}>
              {renderItemDetails}
              {renderPriceInfo}
              <ListingItemForm
                item={item}
                getTotalFee={getTotalFee}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                buyToken={buyToken}
                payoutToken={payoutToken}
                tokenDetails={tokenDetails}
              />
            </Box>
            {renderModalActions}
          </form>
        </Modal.Content>
      </Modal>
      {renderStatusModal}
    </>
  );
};
