import {
  Body,
  Button,
  type DeeplyNestedSx,
  Heading,
  Stack,
} from "@biom3/react";
import { useTranslation } from "react-i18next";
import merge from "ts-deepmerge";

export const BaseEmptyState = ({
  creatingWallet,
  titleKey,
  captionKey,
  primaryButtonKey,
  secondaryButtonKey,
  primaryButtonClick,
  primaryMouseOver,
  secondaryButtonClick,
  sx = {},
}: {
  creatingWallet: boolean;
  titleKey: string;
  captionKey: string;
  primaryButtonKey: string;
  secondaryButtonKey: string;
  primaryButtonClick: () => void;
  primaryMouseOver: (hovering: boolean) => void;
  secondaryButtonClick: () => void;
  sx?: DeeplyNestedSx;
}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={merge({ maxw: "430px", mt: "base.spacing.x8" }, sx)}>
      <Heading
        testId="EmptyStateView-Title"
        sx={{
          px: "base.spacing.x2",
          textAlign: "center",
          mb: "base.spacing.x3",
          whiteSpace: "pre-line",
        }}
      >
        {t(creatingWallet ? "creating_wallet_title" : titleKey)}
      </Heading>
      <Body
        testId="EmptyStateView-Title"
        size={"medium"}
        weight={"bold"}
        sx={{
          px: "base.spacing.x2",
          color: "base.color.text.body.secondary",
          minh: "112px",
          textAlign: "center",
        }}
      >
        {t(creatingWallet ? "creating_wallet_caption" : captionKey)}
      </Body>
      {!creatingWallet && (
        <>
          <Button
            testId="EmptyStateView-PrimaryButton"
            size="large"
            variant="primary"
            onClick={primaryButtonClick}
            onMouseEnter={() => primaryMouseOver(true)}
            onMouseLeave={() => primaryMouseOver(false)}
          >
            {t(primaryButtonKey)}
          </Button>
          <Button
            testId="EmptyStateView-SecondaryButton"
            size="large"
            variant="tertiary"
            onClick={secondaryButtonClick}
          >
            {t(secondaryButtonKey)}
          </Button>
        </>
      )}
    </Stack>
  );
};
