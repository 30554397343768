import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { useSupportedCurrenciesQuery } from "@/hooks";
import { EnvironmentNames } from "@/types";
import { notifyError } from "@/utils/monitoring";
import { useTranslation } from "react-i18next";

type FiatPrice = {
  usd: number;
};

type CryptoFiatPrices = {
  eth: FiatPrice;
  imx: FiatPrice;
  gods: FiatPrice;
  usdc: FiatPrice;
  gog: FiatPrice;
};

const coinGeckoIdMap = new Map<string, string>([
  ["eth", "ethereum"],
  ["imx", "immutable-x"],
  ["gods", "gods-unchained"],
  ["gog", "guild-of-guardians"],
  ["usdc", "usd-coin"],
  ["usdt", "tether"],
  ["ilv", "illuvium"],
]);

// create me an array from the keys of the coinGeckoIdMap object
const coinGeckoSymbols = Object.keys(coinGeckoIdMap);

interface Context {
  fiatPrices?: CryptoFiatPrices | undefined;
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => string;
  buildFiatEquivalentPrice: (
    tokenPrice: string,
    decimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency?: string,
  ) => { currency: string; amount: string; prefixSymbol: string } | undefined;
  setTokenSymbols: (tokenSymbols: string[]) => void;
}

export const FiatPricingContext = createContext<Context>({
  fiatPrices: undefined,
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => "",
  buildFiatEquivalentPrice: () => undefined,
  setTokenSymbols: () => undefined,
});

export const useFiatPricingContext = (): Context =>
  useContext(FiatPricingContext);

async function getCheckoutApiPrices(fiatSymbol = "usd") {
  const coinGeckoIds = [...coinGeckoIdMap.values()];
  const idsParam = coinGeckoIds?.join(",");
  const currenciesParam = fiatSymbol.toLowerCase();

  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = `/v1/fiat/conversion?ids=${idsParam}&currencies=${currenciesParam}`;

  const { data } = await axios.get(baseUrl + path);
  return data;
}

// TODO @pano @ji: This is actually only a Fiat pricing provider for tokens that Moonpay supports :(
export function FiatPricingProvider({
  children,
  fiatSymbol = "usd",
}: PropsWithChildren<{ fiatSymbol?: string }>) {
  const [tokenSymbols, setTokenSymbols] = useState(["eth"]);
  const { data: supportedCurrencies, isLoading } =
    useSupportedCurrenciesQuery();
  const { t } = useTranslation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isLoading) return;

    if (supportedCurrencies) {
      setTokenSymbols(supportedCurrencies);
    }
  }, [isLoading, setTokenSymbols, supportedCurrencies]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const { data } = useQuery<any, any, CryptoFiatPrices, any>({
    queryKey: ["checkoutApiPrices", fiatSymbol],
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    queryFn: () => getCheckoutApiPrices(fiatSymbol),
    select: (data) => ({
      eth: data[coinGeckoIdMap.get("eth") as string],
      imx: data[coinGeckoIdMap.get("imx") as string],
      gods: data[coinGeckoIdMap.get("gods") as string],
      usdc: data[coinGeckoIdMap.get("usdc") as string],
      gog: data[coinGeckoIdMap.get("gog") as string],
      usdt: data[coinGeckoIdMap.get("usdt") as string],
      ilv: data[coinGeckoIdMap.get("ilv") as string],
    }),
  });

  const getFormattedPrice = useCallback(
    (
      symbol: string | undefined,
      decimals: number | undefined,
      amount: string,
    ) => {
      if (!symbol || !decimals) {
        return amount;
      }

      const usdValue = data
        ? (
            data[
              symbol.toLocaleLowerCase() as keyof CryptoFiatPrices
            ] as FiatPrice
          )?.usd
        : undefined;
      if (usdValue) {
        const price = new BigNumber(amount)
          .multipliedBy(usdValue)
          .div(10 ** decimals, 10)
          .toFormat(2);
        if (price === "0.00") {
          return t("approximate_usd_less_than_0_01");
        }
        if (price && price !== "NaN") {
          return t("approximate_usd_value", { amount: price });
        }
      }

      const formattedAmount = new BigNumber(amount)
        .div(10 ** decimals, 10)
        .toFormat(2);
      if (formattedAmount && formattedAmount !== "NaN") {
        return `${symbol} ${formattedAmount}`;
      }
      return `${symbol} ${amount}`;
    },
    [data, t],
  );

  function buildFiatEquivalentPrice(
    tokenPrice: string,
    tokenDecimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency = "usd",
  ) {
    if (!fiatPrices) {
      return;
    }

    if (!tokenSymbol) {
      const tokenSymbolError = new Error("Token Symbol does not exist");
      notifyError(tokenSymbolError, "appError");
      return;
    }

    const symb = tokenSymbol.toLowerCase();

    if (!(symb in fiatPrices)) {
      return;
    }

    const fiatEquivalentRaw = new BigNumber(tokenPrice)
      .multipliedBy(
        (fiatPrices[symb as keyof CryptoFiatPrices] as FiatPrice)?.usd,
      )
      .dividedBy(new BigNumber(10).pow(tokenDecimals));

    let formattedAmount: string;
    if (
      fiatEquivalentRaw.isGreaterThan(0) &&
      fiatEquivalentRaw.isLessThan(0.01)
    ) {
      formattedAmount = "< $0.01";
    } else {
      formattedAmount = fiatEquivalentRaw.toFormat(2);
    }

    return {
      currency: `${
        fiatEquivalentRaw.isGreaterThan(0) ? "" : "~ "
      }${fiatCurrency.toUpperCase()}`,
      amount: formattedAmount !== "NaN" ? formattedAmount : "",
      prefixSymbol: fiatCurrency === "usd" ? "$" : "",
    };
  }

  return (
    <FiatPricingContext.Provider
      value={{
        fiatPrices: data,
        getFormattedPrice,
        buildFiatEquivalentPrice,
        setTokenSymbols,
      }}
    >
      {children}
    </FiatPricingContext.Provider>
  );
}
