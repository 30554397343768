import { Box } from "@biom3/react";
import type { ReactNode } from "react";

type ModalActionsProps = {
  children?: ReactNode;
};

export const ModalActions = ({ children }: ModalActionsProps) => (
  <Box
    sx={{
      d: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      background: "var(--on-dark-base-color-neutrals-600, #444)",
      px: "base.spacing.x6",
      py: "base.spacing.x4",
    }}
  >
    {children}
  </Box>
);
