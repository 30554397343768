import { AppHeaderBar, ButtCon, Button } from "@biom3/react";
import Link from "next/link";
import { type Ref, useState } from "react";

import { CopyWalletModal } from "@/components/coin-balances/CopyWalletModal";
import { HOMEPAGE } from "@/constants";
import { useAnalytics, usePassportProvider } from "@/context";
import { useIsSmallScreen } from "@/hooks";
import { usePassportLogin } from "@/hooks/usePassportLogin";
import { toSimpleAddressFormat } from "@/utils/util";
import { useTranslation } from "react-i18next";
import { LoadingModal } from "./LoadingModal";

export function Header({
  domRef,
  onMenuClick,
  onTitleClick,
}: {
  domRef: Ref<HTMLDivElement> | undefined;
  onMenuClick: () => void;
  onTitleClick: () => void;
}) {
  const { login, loading } = usePassportLogin();
  const { walletAddress, isLoggedIn, passportState } = usePassportProvider();
  const isSmallScreenMode = useIsSmallScreen();
  const { track } = useAnalytics();
  const [isCopyWalletModalVisible, setCopyWalletModalVisible] = useState(false);
  // not required until add tokens widget is in ready state
  // const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  // const [isLayerswapOpen, setIsLayerswapOpen] = useState(false);
  const { t } = useTranslation();

  const onCopyWalletAddress = () => {
    setCopyWalletModalVisible(true);
    walletAddress && navigator.clipboard.writeText(walletAddress);
  };

  const onLogin = () => {
    track({
      screen: "Header",
      userJourney: "Activity",
      control: "SignInButtonClicked",
      controlType: "Button",
    });
    login();
  };

  return (
    <>
      <AppHeaderBar
        variant="bright"
        domRef={domRef}
        testId="header"
        contentAlign={isSmallScreenMode ? "center" : "left"}
        sx={{ pos: "absolute", top: "0", left: "0" }}
      >
        <AppHeaderBar.LeftLogo
          testId="header-logo"
          logo={
            isSmallScreenMode
              ? "PlayHorizontalLockupCompact"
              : "PlayHorizontalLockup"
          }
          rc={<Link onClick={onTitleClick} href={HOMEPAGE} />}
          sx={{ cursor: "pointer" }}
        />
        {isSmallScreenMode && (
          <AppHeaderBar.LeftButtCon
            testId="header-smallscreen"
            size="medium"
            icon="Menu"
            onClick={onMenuClick}
          />
        )}
        <AppHeaderBar.RightSlot>
          <>
            {/* not required until add tokens widget is in ready state */}
            {/* {!isSmallScreenMode && (
              <>
                <Button
                  size="medium"
                  variant="tertiary"
                  onClick={() => setIsCheckoutModalOpen(true)}
                  testId="add-tokens-button"
                >
                  <Button.Icon icon="Coins" iconVariant="bold" />
                  Add Tokens
                </Button>
                <CheckoutWidgetModal
                  isModalOpen={isCheckoutModalOpen}
                  setIsModalOpen={setIsCheckoutModalOpen}
                  overrideConnectedState={true}
                  widgetType="addtokens"
                  widgetParams={{ flow: checkout.CommerceFlowType.ADD_TOKENS }}
                  onActionCompleted={() => { }}
                />
                <Button
                  size="medium"
                  variant="tertiary"
                  onClick={() => setIsLayerswapOpen(true)}
                  testId="cex-withdraw-button"
                >
                  <Button.Icon icon="Sparkle" iconVariant="bold" />
                  CEX Deposit
                </Button>
                <LayerSwap
                  isModalOpen={isLayerswapOpen}
                  onCloseModal={() => setIsLayerswapOpen(false)}
                />
              </>
            )} */}
            {!isLoggedIn && !walletAddress && passportState.ready && (
              <Button
                onClick={onLogin}
                testId="header-signin-button"
                size="medium"
                variant="primary"
              >
                {t("sign_in")}
              </Button>
            )}

            {walletAddress && (
              <>
                {isSmallScreenMode ? (
                  <ButtCon
                    testId="header-wallet-address"
                    icon="Wallet"
                    onClick={onCopyWalletAddress}
                  />
                ) : (
                  <Button
                    onClick={onCopyWalletAddress}
                    size="medium"
                    testId="header-wallet-address"
                    variant="tertiary"
                  >
                    {toSimpleAddressFormat(walletAddress)}
                    <Button.Icon icon="Wallet" />
                  </Button>
                )}
                <CopyWalletModal
                  isCopyWalletModalVisible={isCopyWalletModalVisible}
                  setCopyWalletModalVisible={setCopyWalletModalVisible}
                />
              </>
            )}
          </>
        </AppHeaderBar.RightSlot>
      </AppHeaderBar>
      <LoadingModal loading={loading} title={t("logging_in")} />
    </>
  );
}
