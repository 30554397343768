import { Box, ButtCon, Modal } from "@biom3/react";
import type React from "react";

import { MODAL_WIDTH } from "@/constants";

export const ActionModal = ({
  children,
  isModalOpen,
  onClose,
}: {
  children: React.ReactNode;
  isModalOpen: boolean;
  onClose: () => void;
}) => (
  <Modal visible={isModalOpen}>
    <Modal.Content>
      <Box
        sx={{
          d: "flex",
          flexDirection: "column",
          px: "base.spacing.x3",
          pb: "base.spacing.x8",
          background: "base.color.neutral.900",
          borderRadius: "base.borderRadius.x4",
          boxShadow: "base.shadow.400",
          maxw: MODAL_WIDTH,
          w: "100vw",
          height: "auto",
          minh: "650px",
        }}
      >
        <>
          <Box
            sx={{
              my: "base.spacing.x4",
              w: "100%",
              d: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <ButtCon icon="Close" variant={"tertiary"} onClick={onClose} />
          </Box>
          <Box
            sx={{
              mt: "base.spacing.x10",
              d: "flex",
              flexDirection: "column",
              gap: "base.spacing.x4",
            }}
          >
            {children}
          </Box>
        </>
      </Box>
    </Modal.Content>
  </Modal>
);
