import { Heading, MenuItem, Stack } from "@biom3/react";
import Image from "next/image";
import type React from "react";
import { useTranslation } from "react-i18next";
import { type Connector, useAccount, useConnect } from "wagmi";

import { ActionModal } from "@/components/modal/ActionModal";
import { walletIconProps } from "@/components/wallets/utils/walletIconParams";
import { useAnalytics } from "@/context";
import { useValidConnectors } from "@/hooks/useValidConnectors";
import { CONNECTOR_NAME_MAP } from "@/types";

type SelectWalletModalProps = {
  visible: boolean;
  onClose: () => void;
  setConnector: React.Dispatch<React.SetStateAction<Connector | undefined>>;
  footer?: React.ReactNode;
};

export const SelectConnectorModal = ({
  visible,
  onClose,
  footer,
  setConnector,
}: SelectWalletModalProps) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { connectAsync } = useConnect();
  const { isConnected } = useAccount();
  const { validConnectors } = useValidConnectors();

  const closeModal = () => {
    track({
      userJourney: "AssetTransfer",
      screen: "SelectConnectorModal",
      control: "Close",
      controlType: "Button",
      action: "Pressed",
    });
    onClose();
  };

  const onAvailableConnectorClick = async (connector: Connector) => {
    track({
      userJourney: "AssetTransfer",
      screen: "SelectConnectorModal",
      controlType: "Button",
      control: "Wallet",
      action: "Pressed",
    });
    if (!isConnected) {
      await connectAsync({ connector });
    }
    setConnector(connector);
    onClose();
  };

  return (
    <ActionModal isModalOpen={visible} onClose={closeModal}>
      <Heading
        size="medium"
        weight="bold"
        sx={{ pl: "base.spacing.x3", pr: "base.spacing.x3" }}
      >
        {t("choose_your_wallet")}
      </Heading>
      <Stack gap="base.spacing.x2">
        {validConnectors.map((connector) => {
          const { alt, sx, imagePath, testId } = walletIconProps(connector.id);
          return (
            <MenuItem
              key={connector.id}
              onClick={() => onAvailableConnectorClick(connector)}
              emphasized
              size={"small"}
            >
              <MenuItem.FramedImage
                {...{ alt, testId, sx }}
                use={<Image alt={alt} src={imagePath} width={48} height={48} />}
              />
              <MenuItem.Label>
                {CONNECTOR_NAME_MAP[connector.id]
                  ? CONNECTOR_NAME_MAP[connector.id]
                  : connector.name}
              </MenuItem.Label>
              <MenuItem.IntentIcon icon={"ChevronForward"} />
            </MenuItem>
          );
        })}
        {footer !== undefined && footer}
      </Stack>
    </ActionModal>
  );
};
