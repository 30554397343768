import { base } from "@biom3/design-tokens";

export const TILE_GAP = {
  belowLarge: 16,
  largeAndAbove: 24,
};

// @NOTE: the below heights are only used for LoadingState
export const TILE_DIMENSIONS = {
  default: {
    // PromoTile
    height: "520px",
    widthTillLarge: "280px",
  },
  featureQuest: {
    height: "520px",
    widthTillLarge: "100%",
  },
  mainQuest: {
    height: "520px",
    widthTillLarge: "350px",
  },
  sideQuest: {
    height: "360px",
    widthTillLarge: "350px",
  },
} as const;

export const MAX_CONTAINER_WIDTH = 1300;

export const PADDING_X = {
  default: base.spacing.x4,
  medium: base.spacing.x6,
  large: base.spacing.x12, // large=1024
  xxLarge: "0px", // xxLarge=1536
};
