import { type AllDualVariantIconKeys, Body, Icon, Stack } from "@biom3/react";
import Link from "next/link";
import { Fragment } from "react/jsx-runtime";

export type Breadcrumb = {
  url?: string;
  label: string;
  icon?: AllDualVariantIconKeys;
  onClick?: () => void;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

export const Breadcrumb = ({ breadcrumbs }: Props) => {
  return (
    <Stack direction="row" alignItems="center">
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={breadcrumb.label}>
          {index > 0 && " / "}
          {breadcrumb.icon && (
            <Icon
              icon={breadcrumb.icon}
              variant="bold"
              sx={{
                width: "base.spacing.x5",
                height: "base.spacing.x5",
              }}
            />
          )}
          <Body
            weight="bold"
            size="small"
            sx={{
              color: "base.color.fixed.white.1000",
              textDecoration: "none",
            }}
            rc={
              breadcrumb.url ? (
                <Link href={breadcrumb.url} onClick={breadcrumb?.onClick} />
              ) : undefined
            }
          >
            {breadcrumb.label}
          </Body>
        </Fragment>
      ))}
    </Stack>
  );
};
