import { appConfigFn } from "@/constants/appConfig";

export const SIDEBAR_WIDTH = "320px";
export const HOMEPAGE = "/games";
export const CAROUSEL_IMAGE_SIZE = "240px";
export const CAROUSEL_IMAGE_SIZE_SMALL = "215px";
export const MODAL_WIDTH = "430px";
export const GRID_MIN_COLUMN_WIDTH = "250px";
export const HERO_GRID_MIN_COLUMN_WIDTH = "330px";
export const PLACEHOLDER_CARD_MIN_HEIGHT = "473px";

export const appConfig = appConfigFn();
// @NOTE: use this when we have no valid image url to pass to Biome Image
// components, as this will have them display default imagery
export const BAD_IMAGE_URL = "https://something";
export const PINATA_GATEWAY_URL = "https://gateway.pinata.cloud";
