import {
  type ContentRelationshipField,
  type FilledContentRelationshipField,
  type FilledLinkToWebField,
  LinkType,
} from "@prismicio/client";

export const isWebLink = (
  field: ContentRelationshipField | undefined,
): field is FilledLinkToWebField => {
  return field?.link_type === LinkType.Web;
};

export const isLinkedDocument = <DocType = string, DocDataType = unknown>(
  field: ContentRelationshipField | undefined,
): field is FilledContentRelationshipField<DocType, string, DocDataType> => {
  return field?.link_type === LinkType.Document;
};

export const getWebLink = (
  field: ContentRelationshipField | undefined,
): FilledLinkToWebField => {
  if (isWebLink(field)) return field;

  return {
    link_type: LinkType.Web,
    text: field?.text || "",
    url: "#",
  };
};

export const getLinkedDocument = <DocType = string, DocDataType = unknown>(
  field: ContentRelationshipField | undefined,
): FilledContentRelationshipField<DocType, string, DocDataType> | undefined => {
  if (isLinkedDocument<DocType, DocDataType>(field)) return field;

  return undefined;
};
