import { MarketingConsentModal } from "@/components/core/MarketingConsentModal";
import { usePreferences } from "@/context/PreferencesProvider";
import { useFlags } from "launchdarkly-react-client-sdk";

const Onboarding = () => {
  const { data } = usePreferences();
  const { onboarding } = useFlags();
  return (
    <>{onboarding && data?.onboard && <MarketingConsentModal {...data} />}</>
  );
};

export { Onboarding };
