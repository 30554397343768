import {
  type AllIconKeys,
  Body,
  BulletList,
  ConfirmationDialog,
  FramedImage,
  Heading,
  Stack,
} from "@biom3/react";
import type {
  Content,
  FilledLinkToWebField,
  ImageField,
  KeyTextField,
  RichTextField,
} from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import NextLink from "next/link";

import type { AnalyticsType } from "@cms/context";
import { formatDate } from "@ui-kit/utils/dates";
import { RulesDialogInfoBox } from "./RulesDialogInfoBox/RulesDialogInfoBox";

export type QuestRules = {
  title: string;
  description?: RichTextField;
  primaryCta: {
    icon?: AllIconKeys;
    link: FilledLinkToWebField;
  };
  secondaryCta?: {
    icon?: AllIconKeys;
    link: FilledLinkToWebField;
  };
  endDate: {
    label: string;
    value: string;
  };
  startDate: {
    label: string;
    value: string;
  };
  estimatedTime: {
    label: string;
    value: string;
  };
  platforms: {
    logo: ImageField;
    label: string;
  }[];
  disclaimer?: {
    title: string;
    description?: RichTextField;
  };
  game: {
    name?: KeyTextField;
    logo?: ImageField;
    socials: {
      logo: ImageField;
      link: FilledLinkToWebField;
    }[];
  };
  platformsLabel: string;
  supportLink?: FilledLinkToWebField;
  supportDisclaimer?: string;
};

export type RulesDialogProps = {
  testId: string;
  isVisible: boolean;
  image: ImageField;
  questRules?: QuestRules;
  onSupportLinkClick?: () => void;
  onClose: () => void;
  customImageHeight?: string;
  analytics: AnalyticsType;
  game?: Content.GameDocument;
};

export function RulesDialog({
  testId,
  onSupportLinkClick,
  isVisible,
  onClose,
  customImageHeight,
  image,
  questRules,
  analytics,
  game,
}: RulesDialogProps) {
  if (!questRules) return null;

  const {
    title,
    description,
    game: questGame,
    primaryCta,
    secondaryCta,
    endDate,
    estimatedTime,
    platforms,
    platformsLabel,
    disclaimer,
    supportLink,
    supportDisclaimer,
  } = questRules;

  const onSecondaryCtaClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "QuestCarouselDialogSecondaryClicked",
      controlType: "Button",
      extras: {
        gameName: game?.data.name,
        gameUid: game?.uid,
        secondaryTarget: secondaryCta?.link.target,
        secondaryText: secondaryCta?.link.text,
        secondaryUrl: secondaryCta?.link.url,
      },
    });
  };

  const onPrimaryCtaClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "QuestCarouselDialogPrimaryClicked",
      controlType: "Button",
      extras: {
        gameName: game?.data.name,
        gameUid: game?.uid,
        primaryTarget: primaryCta.link.target,
        primaryText: primaryCta.link.text,
        primaryUrl: primaryCta.link.url,
      },
    });
  };

  return (
    <ConfirmationDialog
      testId={testId}
      visible={isVisible}
      outsideClicksClose
      escapeKeyClose
      onCloseModal={onClose}
    >
      <ConfirmationDialog.Content
        textAlign="left"
        sx={{
          maxWidth: {
            default: "100dvw",
            small: "430px",
            medium: "430px",
            large: "530px",
          },
          backgroundColor: "base.color.neutral.800",
        }}
      >
        <ConfirmationDialog.Content.Image
          testId={`${testId}__image`}
          imageUrl={image.url}
          aspectRatio={4}
          responsiveSizes={[370, 450, 512, 720, 1024]}
          sx={{ height: customImageHeight || "161px" }}
        />
        <ConfirmationDialog.Content.Title
          testId={`${testId}__title`}
          size="small"
          sx={{
            marginBottom: "base.spacing.x4",
            position: "relative",
          }}
        >
          {questGame?.logo?.url && (
            <FramedImage
              imageUrl={questGame.logo.url || ""}
              alt={questGame.logo.alt || ""}
              relativeImageSizeInLayout={"48px"}
              responsiveSizes={[48]}
              testId={`${testId}__logo`}
              sx={{
                height: "48px",
                width: "48px",
                position: "absolute",
                left: "0px",
                top: "-58px",
              }}
            />
          )}
          {title}
        </ConfirmationDialog.Content.Title>
        <ConfirmationDialog.Content.Caption
          testId={`${testId}__caption`}
          sx={{ pt: "0" }}
        >
          <Stack
            testId={`${testId}__summary`}
            direction="row"
            sx={{
              gap: "base.spacing.x1",
              mb: "base.spacing.x6",
            }}
          >
            {estimatedTime.value && (
              <RulesDialogInfoBox
                caption={estimatedTime.label}
                icons={["Countdown"]}
                title={estimatedTime.value}
                testId={`${testId}__estimated`}
              />
            )}
            {endDate.value && (
              <RulesDialogInfoBox
                caption={endDate.label}
                icons={["Calendar"]}
                title={formatDate(new Date(endDate.value))}
                testId={`${testId}__ends`}
              />
            )}
            {platformsLabel && (
              <RulesDialogInfoBox
                caption={platforms.map((platform) => platform.label).join(", ")}
                images={platforms.map((platform) => platform.logo)}
                title={platformsLabel}
                testId={`${testId}__platforms`}
              />
            )}
          </Stack>
        </ConfirmationDialog.Content.Caption>
        <ConfirmationDialog.Content.Caption
          testId={`${testId}__caption`}
          sx={{ pt: "0" }}
        >
          <PrismicRichText
            field={description}
            components={{
              list: (p) => (
                <BulletList
                  sx={{ "& > ul": { gap: "base.spacing.x2" } }}
                  {...p}
                />
              ),
              listItem: ({ key, ...p }) => <BulletList.Item {...p} key={key} />,
              paragraph: ({ key, ...p }) => <Body {...p} key={key} />,
            }}
          />
          {disclaimer?.title && disclaimer?.description && (
            <>
              {/* biome-ignore lint/a11y/useHeadingContent: <explanation> */}
              <Heading rc={<h4 />} size="xSmall" sx={{ pt: "base.spacing.x6" }}>
                {disclaimer.title}
              </Heading>
              <PrismicRichText
                field={disclaimer.description}
                components={{
                  paragraph: (p) => (
                    <Body
                      {...p}
                      size="small"
                      sx={{ color: "base.color.text.body.secondary" }}
                    />
                  ),
                }}
              />
            </>
          )}
        </ConfirmationDialog.Content.Caption>
        {supportLink ? (
          <ConfirmationDialog.Content.Caption
            testId={`${testId}__caption`}
            sx={{ pt: "0" }}
          >
            <Body sx={{ color: "base.color.text.body.secondary" }}>
              {supportDisclaimer}
            </Body>
          </ConfirmationDialog.Content.Caption>
        ) : null}
        <ConfirmationDialog.Content.CloseButtCon
          testId={`${testId}__close-button`}
          onClick={onClose}
        />
        {/* Primary Call to Action */}
        <ConfirmationDialog.Content.Button
          testId={`${testId}__primary-button`}
          size="large"
          variant="primary"
          rc={
            primaryCta?.link.url ? (
              <NextLink
                onClick={onPrimaryCtaClick}
                href={{ pathname: primaryCta.link.url }}
                target={primaryCta.link.target}
                rel="external noreferrer"
                referrerPolicy="origin"
              />
            ) : undefined
          }
        >
          {primaryCta.icon && (
            <ConfirmationDialog.Content.Button.Icon icon={primaryCta.icon} />
          )}
          {primaryCta.link.text}
        </ConfirmationDialog.Content.Button>

        {/* Secondary Call to Action */}
        {secondaryCta?.link?.text && (
          <ConfirmationDialog.Content.Button
            testId={`${testId}__secondary-button`}
            variant="tertiary"
            size="large"
            rc={
              secondaryCta?.link.url ? (
                <NextLink
                  onClick={onSecondaryCtaClick}
                  href={{ pathname: secondaryCta.link.url }}
                  target={secondaryCta.link.target}
                  rel="external noreferrer"
                  referrerPolicy="origin"
                />
              ) : undefined
            }
          >
            {secondaryCta.icon && (
              <ConfirmationDialog.Content.Button.Icon
                icon={secondaryCta.icon}
              />
            )}
            {secondaryCta.link.text}
          </ConfirmationDialog.Content.Button>
        )}
        {/* Support Link */}
        {supportLink?.text && (
          <ConfirmationDialog.Content.Button
            testId={`${testId}__support-button`}
            variant="tertiary"
            size="large"
            rc={
              supportLink?.url ? (
                <NextLink
                  href={{ pathname: supportLink.url }}
                  target={supportLink.target}
                  rel="external noreferrer"
                  referrerPolicy="origin"
                  onClick={onSupportLinkClick}
                />
              ) : undefined
            }
          >
            {supportLink.text}
          </ConfirmationDialog.Content.Button>
        )}
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
}
