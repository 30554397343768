import { Box, ConfirmationDialog, usePrevious } from "@biom3/react";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";

import { AcceptOfferStatusModal } from "@/components/collections/AcceptOfferStatusModal";
import { ListItemModal } from "@/components/collections/ListItemModal";
import { MarketPlaceModal } from "@/components/collections/MarketPlaceModal";
import { BAD_IMAGE_URL, MODAL_WIDTH } from "@/constants";
import { useAnalytics, useOrderbook } from "@/context";
import { useErc20 } from "@/context/Erc20Provider";
import type {
  ChainAddress,
  ItemDetails,
  PricingDisplayInfo,
  ZkEvmItemV2,
} from "@/types";
import { flattenToString } from "@/utils/util";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { CollectionItemGrid } from "./CollectionItemGrid";

type CollectionItemsProps = {
  address: ChainAddress;
  items: ItemDetails[] | undefined;
  refetchItems: () => void;
};

export interface ListForSaleData {
  item: ItemDetails;
  price: string;
  lastSoldDate: string;
  lastSoldPrice?: string;
}

export type CollectionItemAction =
  | { type: "import"; item: ItemDetails }
  | { type: "cancel_listing"; item: ItemDetails; orderId: string }
  | {
      type: "list_for_sale";
      item: ItemDetails;
      pricingInfo: PricingDisplayInfo | undefined;
    }
  | { type: "open_marketplace" }
  | {
      type: "accept_offer";
      item: ItemDetails;
      bidId: string;
      offerPrice: string;
      netPrice: string;
      quantity?: string;
    };

export const CollectionItems = ({
  address,
  items,
  refetchItems,
}: CollectionItemsProps) => {
  const { query, push } = useRouter();
  const { name: collectionName, slug } = query;
  const network = Array.isArray(slug) ? slug[0] : undefined;

  const { fetchToken } = useErc20();
  const { t } = useTranslation();
  const previousItems = usePrevious(items);
  const { cancelListings, fulfillERC721Bid, fulfillERC1155Bid } =
    useOrderbook();
  const { track } = useAnalytics();

  const [isMarketplaceModalVisible, setIsMarketPlaceModalVisible] =
    useState(false);
  const [
    isCancelListingErrorModalVisible,
    setIsCancelListingErrorModalVisible,
  ] = useState(false);
  const [listForSaleData, setListForSaleData] = useState<
    ListForSaleData | undefined
  >(undefined);
  const [isListItemModalVisible, setIsListItemModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState<{
    state: "SUCCESS" | "ERROR" | "LOADING" | undefined;
    itemName: string;
    itemImage: string;
    offerPrice?: string;
  }>({ state: undefined, itemName: "", itemImage: "", offerPrice: "" });
  const [currentAction, setCurrentAction] =
    useState<CollectionItemAction | null>(null);

  // This effect ensures we don't spam the same token endpoint multiple times
  useEffect(() => {
    if (items === previousItems || !items) {
      return;
    }
    const erc20s: string[] = [];
    for (const item of items) {
      if (item.discriminator === "ZKEVM_ITEM_V2") {
        const zkItem = item as ZkEvmItemV2;
        if (
          zkItem.marketData?.floor_listing &&
          zkItem.marketData.floor_listing.token.type === "ERC20"
        ) {
          erc20s.push(zkItem.marketData.floor_listing.token.contract_address);
        }
        if (
          zkItem.marketData?.last_sold &&
          zkItem.marketData.last_sold.pricing_data.token.type === "ERC20"
        ) {
          erc20s.push(
            zkItem.marketData.last_sold.pricing_data.token.contract_address,
          );
        }
      }
    }
    for (const erc20 of erc20s) {
      fetchToken(erc20);
    }
  }, [items, previousItems, fetchToken]);

  const handleAcceptOffer = useCallback(
    async (action: CollectionItemAction) => {
      if (action.type !== "accept_offer") return;

      setModalStatus({
        state: "LOADING",
        itemName: action.item.name || "",
        itemImage: action.item.image || BAD_IMAGE_URL,
      });

      try {
        const isERC1155 = action.item.tokenType === "ERC1155";
        if (isERC1155) {
          await fulfillERC1155Bid(action.bidId, action.quantity);
        } else {
          await fulfillERC721Bid(action.bidId);
        }
        setModalStatus({
          state: "SUCCESS",
          itemName: action.item.name || "",
          itemImage: action.item.image || BAD_IMAGE_URL,
          offerPrice: action.netPrice,
        });
        track({
          userJourney: "Bid",
          screen: "Accept",
          control: "Succeeded",
          controlType: "Effect",
        });
      } catch (e) {
        setModalStatus({
          state: "ERROR",
          itemName: action.item.name || "",
          itemImage: action.item.image || BAD_IMAGE_URL,
        });
        track({
          userJourney: "Bid",
          screen: "Accept",
          control: "Failed",
          controlType: "Effect",
        });
      }
    },
    [fulfillERC1155Bid, fulfillERC721Bid, track],
  );

  const onRetry = useCallback(async () => {
    setModalStatus({
      state: undefined,
      itemName: "",
      itemImage: "",
      offerPrice: "",
    });
    if (currentAction) {
      await handleAcceptOffer(currentAction);
    }
  }, [currentAction, handleAcceptOffer]);

  const onClose = useCallback(() => {
    if (modalStatus.state === "SUCCESS") {
      refetchItems();
    }

    track({
      userJourney: "Bid",
      screen: "AcceptOfferStatusModal",
      control: "Close",
      controlType: "Button",
      action: "Pressed",
    });
    setModalStatus({
      state: undefined,
      itemName: "",
      itemImage: "",
      offerPrice: "",
    });
  }, [track, modalStatus.state, refetchItems]);

  const onGoToMarketplace = useCallback(() => {
    setIsMarketPlaceModalVisible(true);
  }, []);

  const onMarketplaceClose = useCallback(() => {
    if (modalStatus.state === "SUCCESS") {
      refetchItems();
    }
    setIsMarketPlaceModalVisible(false);

    setModalStatus({
      state: undefined,
      itemName: "",
      itemImage: "",
      offerPrice: "",
    });
  }, [modalStatus.state, refetchItems]);

  const renderStatusModal = modalStatus.state && (
    <AcceptOfferStatusModal
      state={modalStatus?.state}
      onRetry={onRetry}
      onClose={onClose}
      itemName={modalStatus?.itemName}
      itemImage={modalStatus?.itemImage}
      offerPrice={modalStatus.offerPrice}
      onGoToMarketplace={onGoToMarketplace}
    />
  );

  return (
    <Box
      sx={{ d: "flex", flexDirection: "column", flex: 1 }}
      data-testid="item-list"
    >
      <CollectionItemGrid
        items={items ?? []}
        onAction={async (action: CollectionItemAction) => {
          switch (action.type) {
            case "import": {
              const item = action.item;
              push(
                `/item/${item.network}/${item.collection}/${item.tokenId}?owner=${item.owner}`,
              );
              break;
            }
            case "accept_offer":
              setCurrentAction(action);
              await handleAcceptOffer(action);
              break;
            case "cancel_listing":
              try {
                await cancelListings([action.orderId]);
                track({
                  userJourney: "Listing",
                  screen: "Cancel",
                  control: "Succeeded",
                  controlType: "Effect",
                });
              } catch (e) {
                setIsCancelListingErrorModalVisible(true);
                track({
                  userJourney: "Listing",
                  screen: "Cancel",
                  control: "Failed",
                  controlType: "Effect",
                });
              }
              break;
            case "list_for_sale":
              setListForSaleData({
                item: action.item,
                price: action.pricingInfo?.floorPrice ?? t("na"),
                lastSoldDate: action.pricingInfo?.lastSoldDate ?? "",
                lastSoldPrice: action.pricingInfo?.lastSoldPrice,
              });
              setIsListItemModalVisible(true);
              break;
            case "open_marketplace":
              setIsMarketPlaceModalVisible(true);
              break;
          }
        }}
      />
      <MarketPlaceModal
        visible={isMarketplaceModalVisible}
        origin={"ItemList"}
        dismissModal={onMarketplaceClose}
        address={address}
        network={network}
        collectionName={flattenToString(collectionName)}
      />
      <ListItemModal
        visible={isListItemModalVisible}
        setIsListItemModalVisible={setIsListItemModalVisible}
        dismissModal={() => {
          setIsListItemModalVisible(false);
        }}
        listForSaleData={listForSaleData}
      />
      {renderStatusModal}
      <ConfirmationDialog
        visible={isCancelListingErrorModalVisible}
        outsideClicksClose={false}
      >
        <ConfirmationDialog.Content
          sx={{
            d: "flex",
            width: MODAL_WIDTH,
            height: "650px",
            alignItems: "center",
          }}
        >
          <ConfirmationDialog.Content.Image
            alt={t("cancel_listing_error_title")}
            sx={{ mt: "base.spacing.x30" }}
            use={
              <Image
                src={"/errorState.svg"}
                alt={t("cancel_listing_error_title")}
                width="170"
                height="128"
              />
            }
          />
          <ConfirmationDialog.Content.Title sx={{ px: "base.spacing.x12" }}>
            {t("cancel_listing_error_title")}
          </ConfirmationDialog.Content.Title>
          <ConfirmationDialog.Content.Caption sx={{ px: "base.spacing.x12" }}>
            {t("listing_error_description")}
          </ConfirmationDialog.Content.Caption>
          <ConfirmationDialog.Content.Button
            size="large"
            variant="primary"
            onClick={() => setIsCancelListingErrorModalVisible(false)}
          >
            {t("close")}
          </ConfirmationDialog.Content.Button>
        </ConfirmationDialog.Content>
      </ConfirmationDialog>
    </Box>
  );
};
