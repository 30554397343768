import { useEffect, useState } from "react";
import {
  type Connector,
  useConnect,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { immutableZkEvm, immutableZkEvmTestnet } from "wagmi/chains";

import { appConfig } from "@/constants";
import { useAnalytics } from "@/context";
import { useAssetImport } from "@/context/AssetImportProvider";
import { type ChainAddress, EnvironmentNames } from "@/types";
import { usePrevious } from "@biom3/react";
// ABI of ERC721 preset: https://github.com/immutable/contracts/blob/main/contracts/token/erc721/preset/ImmutableERC721.sol
const abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "tos",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "tokenIds",
            type: "uint256[]",
          },
        ],
        internalType: "struct ERC721Hybrid.TransferRequest",
        name: "tr",
        type: "tuple",
      },
    ],
    name: "safeTransferFromBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
type TransferStatus =
  | "idle"
  | "pending"
  | "txSubmitting"
  | "txSubmitted"
  | "error"
  | "success";

export class AddressMismatchError extends Error {
  wallet: string | undefined;

  constructor(message: string, wallet?: string) {
    super(message);
    this.name = "AddressMismatchError";
    this.wallet = wallet;
  }
}

export class SwitchChainError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "SwitchChainError";
  }
}

export function useZkBatchTransfer(
  connector: Connector | undefined,
  contractAddress: ChainAddress | undefined,
  from: ChainAddress | undefined,
  to: ChainAddress | undefined,
  tokenIds: string[],
): {
  transfer: () => void;
  reset: () => void;
  status: TransferStatus;
  hash: ChainAddress | undefined;
  error: Error | undefined;
} {
  const {
    connect,
    error: connectError,
    reset: resetConnect,
    status: connectStatus,
  } = useConnect();
  const {
    chains,
    switchChainAsync,
    error: switchChainError,
    reset: resetSwitchChain,
  } = useSwitchChain();
  const {
    data: hash,
    error: writeError,
    reset: resetWrite,
    isSuccess: writeSuccess,
    writeContract,
  } = useWriteContract();
  const { activeImport } = useAssetImport();
  const { track } = useAnalytics();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [status, setStatus] = useState<TransferStatus>("idle");
  const [chainId, setChainId] = useState<number | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const previousWriteSuccess = usePrevious(writeSuccess);

  const zkChainID =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? immutableZkEvm.id
      : immutableZkEvmTestnet.id;
  const zkChain = chains?.find((chain) => chain.id === zkChainID);
  const receivers = tokenIds.map(() => to);
  const { error: txError, isSuccess: txSuccess } = useWaitForTransactionReceipt(
    { chainId: zkChain?.id, hash },
  );
  const previousTxSuccess = usePrevious(txSuccess);

  useEffect(() => {
    if (error) {
      setStatus("error");
    } else if (switchChainError) {
      setError(new SwitchChainError(switchChainError.message));
    } else if (
      connectError &&
      connectError.name !== "ConnectorAlreadyConnectedError"
    ) {
      setError(connectError);
    } else if (writeError) {
      setError(writeError);
    } else if (txError) {
      setError(txError);
    }
  }, [error, writeError, txError, connectError, switchChainError]);

  useEffect(() => {
    if (txSuccess && !previousTxSuccess) {
      setStatus("success");
      return;
    }

    if (writeSuccess && !previousWriteSuccess) {
      track({
        screen: "TxSubmission",
        userJourney: "AssetTransfer",
        action: "Succeeded",
        extras: {
          flowId: activeImport?.trackingId,
          itemCount: tokenIds.length,
          contractAddress,
        },
      });
      setStatus("txSubmitted");
    }
  }, [
    txSuccess,
    writeSuccess,
    activeImport,
    previousTxSuccess,
    previousWriteSuccess,
    tokenIds,
    contractAddress,
    track,
  ]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (status === "txSubmitting") {
      writeContract({
        chainId: zkChain?.id,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        address: contractAddress!,
        abi: abi,
        functionName: "safeTransferFromBatch",
        args: [[from, receivers, tokenIds]],
      });
    }
  }, [status]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const executeTransfer = async () => {
      if (status === "pending" && contractAddress && to && from) {
        if (!connector) {
          setError(new Error("Connector not found"));
          return;
        }
        if (connectStatus === "idle") {
          connect(
            { connector },
            {
              onSuccess: ({ accounts, chainId }) => {
                setAddress(accounts[0]);
                setChainId(chainId);
              },
              onError: (error) => {
                if (error.name === "ConnectorAlreadyConnectedError") {
                  connector.getAccounts().then((accounts) => {
                    setAddress(accounts[0]);
                  });
                  connector.getChainId().then((chainId) => {
                    setChainId(chainId);
                  });
                }
              },
            },
          );
          return;
        }
        if (!address || !chainId) {
          return;
        }
        if (!zkChain) {
          setError(new Error("zkChain not found"));
          return;
        }
        if (chainId && chainId !== zkChain.id) {
          await switchChainAsync({ chainId: zkChain.id });
          setChainId(zkChain.id);
          return;
        }
        if (
          address &&
          address.toLocaleLowerCase() !== from.toLocaleLowerCase()
        ) {
          setError(
            new AddressMismatchError(
              "Address mismatch",
              from.toLocaleLowerCase(),
            ),
          );
          return;
        }
        setStatus("txSubmitting");
      }
    };
    executeTransfer();
  }, [
    connect,
    connector,
    contractAddress,
    from,
    status,
    switchChainAsync,
    to,
    zkChain,
    connectStatus,
    address,
    chainId,
    connectError,
  ]);

  const reset = () => {
    setError(undefined);
    resetConnect();
    resetSwitchChain();
    resetWrite();
    setAddress(undefined);
    setChainId(undefined);
    setStatus("idle");
  };

  const transfer = () => {
    setStatus("pending");
  };
  return {
    transfer,
    reset,
    status,
    hash,
    error,
  };
}
