import { HideOfferModal } from "@/components/collections/HideOfferModal";
import { BAD_IMAGE_URL } from "@/constants";
import {
  type DashboardAnalyticsJourneyType,
  useAnalytics,
  useFiatPricingContext,
  usePassportProvider,
} from "@/context";
import { useErc20 } from "@/context/Erc20Provider";
import { useOrderbook } from "@/context/OrderbookProvider";
import type {
  BestOffer,
  ItemDetails,
  Listing,
  PricingDisplayInfo,
} from "@/types";
import { toSimpleAddressFormat } from "@/utils/util";
import {
  Badge,
  BaseClickable,
  Body,
  Box,
  ButtCon,
  Card,
  Grid,
  Icon,
  MenuItem,
  Pile,
  Popover,
} from "@biom3/react";
import { useMappedCollectionItems } from "@hooks/useMappedCollectionItems";
import type {
  StandardAnalyticsActions,
  StandardAnalyticsControlTypes,
} from "@imtbl/react-analytics";
import { useFlags } from "launchdarkly-react-client-sdk";
import Link from "next/link";
import { type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import type { CollectionItemAction } from "./CollectionItems";

const CARD_HEIGHT = "384px";

type CollectionItemCardProps = {
  item: ItemDetails;
  pricingInfo: PricingDisplayInfo | undefined;
  isLinkedAsset: boolean;
  balance: number;
  rarity: string | number | undefined;
  listing: Listing | undefined;
  bestOffer: BestOffer | undefined;
};

// More performant than creating an element for each Pile
const customPileLayer = (
  <Box
    sx={{
      border: "solid",
      borderWidth: "base.border.size.100",
      borderColor: "base.color.translucent.standard.400",
      height: "30px",
      width: "100%",
      borderRadius: "base.borderRadius.x4",
      boxShadow: "base.shadow.200",
    }}
  />
);

const PricingView = ({
  primaryLabel,
  primaryValue,
  secondaryLabel,
  secondaryValue,
}: {
  primaryLabel: string;
  primaryValue: string;
  secondaryLabel: string;
  secondaryValue: string;
}) => {
  return (
    <>
      <Box
        sx={{
          d: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          minh: "24px",
        }}
      >
        {primaryValue !== "" && (
          <Body
            sx={{
              color: "base.color.text.body.primary",
              fontSize: "base.text.body.small.regular.fontSize",
            }}
          >
            {primaryLabel}
          </Body>
        )}
        {primaryValue !== "" && (
          <Body
            sx={{
              color: "base.color.text.body.primary",
              fontSize: "base.text.body.small.regular.fontSize",
            }}
          >
            {primaryValue}
          </Body>
        )}
      </Box>
      <Box
        sx={{
          d: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Body
          sx={{
            color: "base.color.text.body.secondary",
            fontSize: "base.text.body.xSmall.regular.fontSize",
          }}
        >
          {secondaryLabel}
        </Body>
        <Body
          sx={{
            color: "base.color.text.body.secondary",
            fontSize: "base.text.body.xSmall.regular.fontSize",
          }}
        >
          {secondaryValue}
        </Body>
      </Box>
    </>
  );
};

const ImageBadges = ({
  item,
  isMultiple,
  rarity,
}: { item: ItemDetails; isMultiple: boolean; rarity?: string | number }) => {
  return (
    <Box
      sx={{
        d: "flex",
        w: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        pos: "absolute",
        top: "base.spacing.x6",
        px: "base.spacing.x6",
      }}
    >
      {rarity ? (
        <Badge
          badgeContent={rarity}
          variant="dark"
          data-testid={`item-rarity-${item.tokenId}`}
        />
      ) : (
        <Box />
      )}
      {isMultiple ? (
        <Badge
          badgeContent={`x ${item.balance}`}
          variant="dark"
          data-testid={`item-balance-${item.tokenId}`}
        />
      ) : (
        <Box />
      )}
    </Box>
  );
};

const StatusBadges = ({
  isListed,
  hasBestOffer,
}: { isListed: boolean; hasBestOffer: boolean }) => {
  return (
    <Box
      sx={{
        d: "flex",
        flexDirection: "row",
        pos: "absolute",
        top: "calc(var(--cardAssetMediaHeight) - 9px)",
        left: "base.spacing.x6",
      }}
    >
      {isListed && !hasBestOffer && (
        <Badge badgeContent={"Listed"} variant="success" />
      )}
      {hasBestOffer && <Badge badgeContent={"Best offer"} variant="guidance" />}
    </Box>
  );
};

const ItemCard = ({
  itemProps,
  onAction,
  t,
  track,
  listFlowEnable,
  onHideOfferClick,
}: {
  itemProps: CollectionItemCardProps;
  onAction: (action: CollectionItemAction) => void;
  t: (key: string, options?: Record<string, unknown>) => string;
  track: (event: {
    userJourney: DashboardAnalyticsJourneyType;
    screen: string;
    control: string;
    controlType:
      | StandardAnalyticsControlTypes
      | "Effect"
      | "Card"
      | "Modal"
      | "Group";
    action: StandardAnalyticsActions | "Changed" | "Closed" | undefined;
  }) => void;
  listFlowEnable: boolean;
  onHideOfferClick: (bidIds: string[]) => void;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const {
    balance,
    listing,
    isLinkedAsset,
    pricingInfo,
    item,
    rarity,
    bestOffer,
  } = itemProps;

  const isMultiple = balance > 1;
  const isERC1155 = item.tokenType === "ERC1155";
  const minQuantity =
    isERC1155 && bestOffer?.availableQuantity
      ? Math.min(balance, bestOffer.availableQuantity)
      : 1;

  let buttonAction: CollectionItemAction;
  let buttonText = "";
  let descriptionContents: ReactNode;
  let control: string;

  if (isLinkedAsset) {
    buttonAction = { type: "import", item };
    buttonText = t("import");
    control = "Import";
    descriptionContents = t("item_owned_by_linked_wallet_message", {
      name: toSimpleAddressFormat(item.owner ?? ""),
    });
  } else if (bestOffer) {
    buttonAction = {
      type: "accept_offer",
      item,
      bidId: bestOffer.id,
      offerPrice: bestOffer.offeredPrice,
      netPrice: bestOffer.netPrice,
      ...(isERC1155 && { quantity: minQuantity.toString() }),
    };
    buttonText = t("accept_offer");
    control = "AcceptOffer";
    descriptionContents = (
      <PricingView
        primaryLabel={t("offer_price")}
        primaryValue={bestOffer.offeredPrice}
        secondaryLabel=""
        secondaryValue={bestOffer.fiatPrice}
      />
    );
  } else if (listing) {
    buttonAction = {
      type: "cancel_listing",
      item,
      orderId: listing.id,
    };
    buttonText = t("cancel_listing");
    control = "CancelListing";
    descriptionContents = (
      <PricingView
        primaryLabel={t("asked_price")}
        primaryValue={listing.askingPrice}
        secondaryLabel=""
        secondaryValue={listing.fiatPrice}
      />
    );
  } else {
    buttonAction = { type: "list_for_sale", item, pricingInfo };
    buttonText = t("list_item_button");
    control = "ListForSale";
    descriptionContents = (
      <PricingView
        primaryLabel={pricingInfo ? t("floor_price") : ""}
        primaryValue={pricingInfo?.floorPrice ?? ""}
        secondaryLabel={pricingInfo?.lastSoldDate ?? ""}
        secondaryValue={pricingInfo?.lastSoldPrice ?? ""}
      />
    );
  }

  const cardContent = (
    <Card
      key={item.tokenId}
      className={isPopoverOpen ? "isPopoverOpen" : ""}
      rc={
        <Link
          onClick={() => {
            track({
              userJourney: "Collections",
              screen: "ItemList",
              control: "Click",
              controlType: "Button",
              action: "Request",
            });
          }}
          href={`/item/${item.network}/${item.collection}/${item.tokenId}?owner=${item.owner}`}
        />
      }
      sx={{
        minh: isMultiple ? "100%" : undefined,
        maxh: CARD_HEIGHT,
        border: "solid",
        borderWidth: "base.border.size.100",
        borderColor: "base.color.translucent.standard.400",
        mb: !isMultiple ? "base.spacing.x4" : undefined,
        ...(listFlowEnable &&
          item.network === "zkEvm" && {
            // Default style
            "& .buttonsContainer": {
              display: "none",
            },
            // Styles on hover
            "&:hover .buttonsContainer": {
              display: "flex",
            },
            "&:hover .hoverButton": {
              display: "block",
            },
            "&:hover .nonHoverDescription": {
              display: "none",
            },
            "&:hover .innerTextContainer": {
              minHeight: "0px",
            },
            // Styles when popover is open
            "&.isPopoverOpen .buttonsContainer": {
              display: "flex",
            },
            "&.isPopoverOpen .hoverButton": {
              display: "block",
            },
            "&.isPopoverOpen .nonHoverDescription": {
              display: "none",
            },
            "&.isPopoverOpen .innerTextContainer": {
              minHeight: "0px",
            },
          }),
      }}
    >
      <Card.AssetImage
        imageUrl={item.image ?? BAD_IMAGE_URL}
        relativeImageSizeInLayout="100vw"
        aspectRatio="1:1"
        objectFit="cover"
        sx={{ opacity: isLinkedAsset ? 0.5 : 1 }}
      />
      <ImageBadges item={item} isMultiple={isMultiple} rarity={rarity} />
      {listFlowEnable && item.network === "zkEvm" && !isLinkedAsset && (
        <StatusBadges isListed={!!listing} hasBestOffer={!!bestOffer} />
      )}
      <Card.Title>{item.name}</Card.Title>
      <Card.Description className={"nonHoverDescription"}>
        {descriptionContents}
      </Card.Description>
      <Box sx={{ d: "flex", gap: "base.spacing.x2", width: "100%" }}>
        <Card.Button
          className={"hoverButton"}
          sx={{ display: "none" }}
          variant="primary"
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            track({
              userJourney: "Collections",
              screen: "ItemList",
              controlType: "Button",
              control,
              action: "Pressed",
            });
            onAction(buttonAction);
          }}
        >
          {buttonText}
        </Card.Button>
        {bestOffer && (
          <Popover
            visible={isPopoverOpen}
            onClosePopover={() => setIsPopoverOpen(false)}
          >
            <Popover.Target>
              <ButtCon
                icon="MoreVertical"
                variant="secondary"
                size="medium"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  track({
                    userJourney: "Collections",
                    screen: "ItemList",
                    controlType: "Button",
                    control: "MoreOptions",
                    action: "Pressed",
                  });
                  setIsPopoverOpen((prev) => !prev);
                }}
              />
            </Popover.Target>
            <Popover.Content
              sx={{
                bg: "base.color.neutral.500",
                borderRadius: "base.borderRadius.x4",
                textAlign: "center",
                boxShadow: "base.shadow.500",
                p: "base.spacing.x1",
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseLeave={() => setIsPopoverOpen(false)}
            >
              <MenuItem
                onClick={() => {
                  track({
                    userJourney: "Collections",
                    screen: "ItemList",
                    controlType: "MenuItem",
                    control: "HideOffer",
                    action: "Pressed",
                  });
                  if (
                    itemProps.item.discriminator === "ZKEVM_ITEM_V2" &&
                    itemProps.item.bids
                  ) {
                    onHideOfferClick(itemProps.item.bids.map((bid) => bid.id));
                  }
                }}
                sx={{
                  minWidth: "280px",
                  minHeight: "52px",
                  pl: "base.spacing.x1", // padding has to be specified for left, right, top, bottom to override the default padding
                  pr: "base.spacing.x1",
                  pt: "base.spacing.x1",
                  pb: "base.spacing.x1",
                }}
              >
                <MenuItem.Label sx={{ width: "100%", p: "base.spacing.x3" }}>
                  {t("hide_offer")}
                </MenuItem.Label>
              </MenuItem>
              {listing && (
                <MenuItem
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    track({
                      userJourney: "Collections",
                      screen: "ItemList",
                      controlType: "MenuItem",
                      control: "CancelListing",
                      action: "Pressed",
                    });
                    onAction({
                      type: "cancel_listing",
                      item,
                      orderId: listing.id,
                    });
                  }}
                  sx={{
                    minWidth: "280px",
                    minHeight: "52px",
                    pl: "base.spacing.x1", // padding has to be specified for left, right, top, bottom to override the default padding
                    pr: "base.spacing.x1",
                    pt: "base.spacing.x1",
                    pb: "base.spacing.x1",
                  }}
                >
                  <MenuItem.Label sx={{ width: "100%", p: "base.spacing.x3" }}>
                    {t("cancel_listing")}
                  </MenuItem.Label>
                </MenuItem>
              )}
            </Popover.Content>
          </Popover>
        )}
      </Box>
    </Card>
  );

  return isMultiple ? (
    <Pile
      key={item.tokenId}
      pileLayer={customPileLayer}
      data-testid={`item-pile-${item.tokenId}`}
    >
      {cardContent}
    </Pile>
  ) : (
    cardContent
  );
};

export const CollectionItemGrid = ({
  items,
  onAction,
}: {
  items: ItemDetails[];
  onAction: (action: CollectionItemAction) => void;
}) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { tokens } = useErc20();
  const { getFormattedPrice } = useFiatPricingContext();
  const { activeOrders } = useOrderbook();
  const { walletAddress } = usePassportProvider();
  const { listFlowEnable, bidEnabled } = useFlags();

  const [hiddenBidIds, setHiddenBidIds] = useState<Set<string>>(() => {
    if (typeof window !== "undefined") {
      try {
        const stored = localStorage.getItem("hiddenItemBidIds");
        return stored ? new Set(JSON.parse(stored)) : new Set();
      } catch (error) {
        console.error(
          "Failed to parse hiddenItemBidIds from localStorage:",
          error,
        );
        return new Set();
      }
    }
    return new Set();
  });

  const [isHideModalVisible, setIsHideModalVisible] = useState(false);
  const [selectedBidIds, setSelectedBidIds] = useState<string[] | null>(null);

  const handleHideOffer = (bidIds: string[]) => {
    setHiddenBidIds((prev) => {
      const updated = new Set(prev);
      for (const bidId of bidIds) {
        updated.add(bidId);
      }
      localStorage.setItem(
        "hiddenItemBidIds",
        JSON.stringify(Array.from(updated)),
      );
      return updated;
    });
    setIsHideModalVisible(false);
    setSelectedBidIds(null);
  };

  const handleHideModalClose = () => {
    track({
      userJourney: "Bid",
      screen: "HideOfferModal",
      controlType: "Button",
      control: "Close",
      action: "Pressed",
    });
    setIsHideModalVisible(false);
    setSelectedBidIds(null);
  };

  const mappedCollectionItems = useMappedCollectionItems(items, {
    tokens,
    getFormattedPrice,
    t,
    hiddenBidIds,
    bidEnabled,
    activeOrders,
    walletAddress,
  });

  return (
    <>
      <Grid minColumnWidth={"243px"}>
        {mappedCollectionItems.map((itemProps) => (
          <ItemCard
            key={itemProps.item.tokenId}
            itemProps={itemProps}
            onAction={onAction}
            t={t}
            track={track}
            listFlowEnable={listFlowEnable}
            onHideOfferClick={(bidIds: string[]) => {
              setSelectedBidIds(bidIds);
              setIsHideModalVisible(true);
            }}
          />
        ))}

        {mappedCollectionItems.length > 0 && (
          <BaseClickable
            sx={{
              minh: CARD_HEIGHT,
              mb: "base.spacing.x4",
              border: "var(--concept, 2px) dashed",
              borderColor:
                "var(--color-translucent-standard-600, rgba(247, 247, 247, 0.48))",
              borderRadius: "var(--Border-Radius-x4, 8px)",
              background: "var(--color-neutral-1000, #0D0D0D)",
              boxShadow: "base.shadow.100",
              transition: "box-shadow 0.2s ease, transform 0.2s ease",
              "&:hover": {
                boxShadow: "base.shadow.300",
                transform: "translate(0, -4px)",
                backgroundColor: "base.color.neutral.700",
              },
              d: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              track({
                userJourney: "Collections",
                screen: "ItemList",
                controlType: "Button",
                control: "Placeholder",
                action: "Pressed",
              });
              onAction({ type: "open_marketplace" });
            }}
          >
            <Icon icon="Add" sx={{ w: "base.icon.size.400" }} />
          </BaseClickable>
        )}
      </Grid>
      <HideOfferModal
        visible={isHideModalVisible}
        bidIds={selectedBidIds}
        onHide={handleHideOffer}
        onClose={handleHideModalClose}
      />
    </>
  );
};
