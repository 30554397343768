import { config, orderbook, passport, x } from "@imtbl/sdk";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

import { appConfig } from "@/constants";
import { EnvironmentNames, type PassportConfig } from "@/types";

const getCoreSdkConfig = (environment: EnvironmentNames) => {
  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return x.imxClientConfig({ environment: x.Environment.PRODUCTION });
    }
    case EnvironmentNames.SANDBOX: {
      return x.imxClientConfig({ environment: x.Environment.SANDBOX });
    }
    case EnvironmentNames.DEV: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: x.Environment.SANDBOX,
        }),
        overrides: {
          immutableXConfig: x.createConfig({
            basePath: "https://api.dev.x.immutable.com",
            chainID: 5,
            coreContractAddress: "0xd05323731807A35599BF9798a1DE15e89d6D6eF1",
            registrationContractAddress:
              "0x7EB840223a3b1E0e8D54bF8A6cd83df5AFfC88B2",
          }),
        },
      };
    }
    default: {
      throw new Error(`Invalid environment: "${environment}"`);
    }
  }
};

const getPassportConfig = (environment: EnvironmentNames) => {
  const passportConfig: PassportConfig = {
    logoutMode: "redirect",
    audience: "platform_api",
    scope: "openid email offline_access transact read:grants",
    clientId: appConfig.AUTH0_CLIENT_ID,
    logoutRedirectUri: appConfig.IS_PLAY_PORTAL_DEPLOYMENT
      ? appConfig.AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI
      : appConfig.AUTH0_LOGOUT_REDIRECT_URI,
    redirectUri: appConfig.IS_PLAY_PORTAL_DEPLOYMENT
      ? appConfig.AUTH0_REDIRECT_PLAY_PORTAL_URI
      : appConfig.AUTH0_REDIRECT_URI,
    forceScwDeployBeforeMessageSignature: true,
  };

  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.PRODUCTION,
        }),
        ...passportConfig,
      };
    }
    case EnvironmentNames.SANDBOX: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.SANDBOX,
        }),
        ...passportConfig,
      };
    }

    case EnvironmentNames.DEV: {
      const baseConfig = new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
      });

      return {
        baseConfig,
        overrides: {
          authenticationDomain: "https://auth.dev.immutable.com",
          magicPublishableApiKey: "pk_live_4058236363130CA9",
          magicProviderId: "C9odf7hU4EQ5EufcfgYfcBaT5V6LhocXyiPRhIjw2EY=",
          passportDomain: "https://passport.dev.immutable.com",
          imxPublicApiDomain: "https://api.dev.immutable.com",
          immutableXClient: new x.IMXClient({
            baseConfig,
            overrides: {
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              immutableXConfig: getCoreSdkConfig(EnvironmentNames.DEV)
                .overrides!.immutableXConfig,
            },
          }),
          zkEvmRpcUrl: "https://rpc.dev.immutable.com",
          relayerUrl: "https://api.dev.immutable.com/relayer-mr",
          indexerMrBasePath: "https://api.dev.immutable.com",
          orderBookMrBasePath: "https://api.dev.immutable.com",
          passportMrBasePath: "https://api.dev.immutable.com",
        },
        ...passportConfig,
      };
    }
    default: {
      throw new Error("Invalid environment");
    }
  }
};

const getOrderbookConfig = (environment: EnvironmentNames) => {
  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.PRODUCTION,
        }),
      };
    }
    case EnvironmentNames.SANDBOX: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.SANDBOX,
        }),
      };
    }

    case EnvironmentNames.DEV: {
      const baseConfig = new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
      });

      return {
        baseConfig,
      };
    }
    default: {
      throw new Error("Invalid environment");
    }
  }
};

const ImmutableContext = createContext<{
  passportClient: passport.Passport;
  coreSdkClient: x.ImmutableX;
  orderbookClient: orderbook.Orderbook;
  environment: EnvironmentNames;
} | null>(null);

export function ImmutableProvider({ children }: PropsWithChildren) {
  const providerValues = useMemo(() => {
    const environment = appConfig.ENVIRONMENT as EnvironmentNames;
    const passportClient = new passport.Passport(
      getPassportConfig(environment),
    );
    const coreSdkClient = new x.ImmutableX(getCoreSdkConfig(environment));
    const orderbookClient = new orderbook.Orderbook(
      getOrderbookConfig(environment),
    );
    return {
      passportClient,
      coreSdkClient,
      orderbookClient,
      environment,
    };
  }, []);

  return (
    <ImmutableContext.Provider value={providerValues}>
      {children}
    </ImmutableContext.Provider>
  );
}

export function useImmutableProvider() {
  const ctx = useContext(ImmutableContext);
  if (!ctx)
    throw new Error(
      "useImmutableProvider must be used within a ImmutableProvider",
    );
  return ctx;
}
