import {
  type AllIconKeys,
  type AllSingleVariantIconKeys,
  AspectRatioImage,
  Body,
  Box,
  ButtCon,
  Button,
  Divider,
  Icon,
  MenuItem,
  Stack,
  isDualVariantIcon,
} from "@biom3/react";
import type { AnalyticsType } from "@cms/context";
import type { Content } from "@prismicio/client";
import NextLink from "next/link";
import { Fragment, useMemo } from "react";

export type GameSpec = {
  main: GameMain;
  specifications: GameSpecifications;
};

export type GameSpecsProps = {
  gameSpec: GameSpec;
  isWishlisted: boolean;
  onWishlistClick: () => void;
  analytics: AnalyticsType;
  game: Content.GameDocument;
};

type GameSocial = {
  network: string;
  link: string;
  logo: string;
};

type GameAgeRating = {
  name: string;
  description: string;
  logo: string;
};

type GameMain = {
  logo: string;
  socials: GameSocial[];
  website?: string;
};

type GameCompany = {
  name: string;
  website: string;
};

type GameSpecifications = {
  studio: GameCompany;
  publisher: GameCompany;
  ageRating: GameAgeRating;
  genres: string[];
  platforms: [string, string][];
  releaseDate: string;
};

const mapSocialIconKey = (network: string): AllIconKeys => {
  switch (network) {
    case "X":
      return "X";
    case "Discord":
      return "Discord";
    default:
      return "ApplicationWeb";
  }
};

export const GameSpecs = ({
  gameSpec,
  isWishlisted,
  onWishlistClick,
  analytics,
  game,
}: GameSpecsProps) => {
  const socials = useMemo(() => {
    const entries = gameSpec.main.socials.map((social) => {
      const mappedIconKey = mapSocialIconKey(social.network);
      return (
        <ButtCon
          key={social.network}
          variant="tertiary"
          size="medium"
          rc={
            <NextLink
              href={{ pathname: social.link }}
              onClick={() => onSocialIconClick(social.link)}
              target="_blank"
              referrerPolicy="no-referrer"
            />
          }
          {...(isDualVariantIcon(mappedIconKey)
            ? { icon: mappedIconKey }
            : { icon: mappedIconKey })}
        />
      );
    });

    if (gameSpec.main.website) {
      entries.push(
        <ButtCon
          key="website"
          variant="tertiary"
          size="medium"
          rc={
            <NextLink
              onClick={() =>
                onSocialIconClick(gameSpec.main.website || "website")
              }
              href={{ pathname: gameSpec.main.website }}
              target="_blank"
              referrerPolicy="no-referrer"
            />
          }
          icon="ApplicationWeb"
        />,
      );
    }

    return entries;
  }, [gameSpec.main.socials, gameSpec.main.website]);

  const specs = useMemo(() => {
    const specifications = gameSpec.specifications;
    return [
      { label: "Genre", value: specifications.genres.join(", ") },
      {
        label: "Platform",
        value: specifications.platforms.map(([name]) => name).join(", "),
        links: specifications.platforms.map(([_, link]) => link),
      },
      {
        label: "Studio",
        value: specifications.studio.name,
        links: [specifications.studio.website],
      },
      {
        label: "Publisher",
        value: specifications.publisher.name,
        links: [specifications.publisher.website],
      },
      { label: "Released", value: specifications.releaseDate },
    ];
  }, [gameSpec.specifications]);

  const onPlayNowButtonClick = () => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "PlayNowClicked",
      controlType: "Button",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        website: gameSpec.main.website,
      },
    });
  };

  const onSocialIconClick = (socialLink: string) => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "SocialIconClicked",
      controlType: "ButtCon",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        socialLink,
      },
    });
  };

  const onSpecsTableLinkClick = (link: string, specLabel: string) => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "SpecsTableLinkClicked",
      controlType: "Link",
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
        link,
        specLabel,
      },
    });
  };

  return (
    <Stack gap="0px">
      <AspectRatioImage
        imageUrl={gameSpec.main.logo}
        aspectRatio={"3:2"}
        responsiveSizes={[1024]}
        relativeImageSizeInLayout={"270px"}
        sx={{ brad: "base.borderRadius.x6", w: "100%", mb: "base.spacing.x2" }}
      />
      <Stack direction="column" gap="base.spacing.x2">
        <Body
          size="small"
          sx={{
            mb: "base.spacing.x1",
            textAlign: "center",
            color: "base.color.text.body.secondary",
          }}
        >
          Follow {game.data.name?.replace(/ /g, "\u00A0")} to get access to
          exclusive content and important updates.
        </Body>
        <Button
          variant="primary"
          size="medium"
          sx={{
            ...(isWishlisted
              ? {
                  bg: "base.color.status.success.bright",
                  "&:hover:not([disabled])": {
                    boxShadow: ({ base }) =>
                      `inset 0 0 0 ${base.border.size[300]} ${base.color.status.success.bright}`,
                  },
                  "&::before": {
                    bg: "base.color.status.success.bright",
                  },
                }
              : {}),
          }}
          onClick={onWishlistClick}
        >
          <Button.Icon icon="Add" iconVariant="bold" />
          {isWishlisted ? "Following" : "Follow"}
        </Button>
      </Stack>
      <Button
        variant="tertiary"
        size="medium"
        sx={{ mt: "base.spacing.x4" }}
        rc={
          <NextLink
            onClick={onPlayNowButtonClick}
            href={{ pathname: gameSpec.main.website }}
            target="_blank"
            referrerPolicy="no-referrer"
          />
        }
      >
        Play Now
      </Button>

      {socials.length > 0 && (
        <Stack
          direction={"row"}
          gap={"base.spacing.x2"}
          justifyContent="center"
          sx={{ my: "base.spacing.x8" }}
        >
          {socials}
        </Stack>
      )}

      {/* Set reminder - not implemented yet
        <MenuItem size={"xSmall"} emphasized sx={{mt: "base.spacing.x1"}}>
          <MenuItem.FramedIcon icon="Alert" emphasized={false} />
          <MenuItem.Label>Set reminder</MenuItem.Label>
          <Toggle />
        </MenuItem> */}

      <MenuItem size={"xSmall"} emphasized sx={{ mt: "base.spacing.x1" }}>
        <MenuItem.FramedImage
          imageUrl={gameSpec.specifications.ageRating.logo}
          alt={gameSpec.specifications.ageRating.name}
        />
        <MenuItem.Label>
          {gameSpec.specifications.ageRating.name}
        </MenuItem.Label>
        <MenuItem.Caption>
          {gameSpec.specifications.ageRating.description}
        </MenuItem.Caption>
      </MenuItem>

      <Specs specs={specs} analyticsEvent={onSpecsTableLinkClick} />
    </Stack>
  );
};

function mapIcons(network: string): AllSingleVariantIconKeys | undefined {
  switch (network) {
    case "App Store":
      return "Apple";
    case "Play Store":
      return "GooglePlayStore";
  }

  return undefined;
}

const Platforms = ({
  platforms,
  links,
  analyticsEvent,
}: {
  platforms: string[];
  links?: string[];
  analyticsEvent: (link: string, specLabel: string) => void;
}): React.ReactNode => {
  return (
    <Stack direction={"row"} gap={"base.spacing.x2"}>
      {platforms.map((platform, idx) => {
        const icon = mapIcons(platform);
        const link = links?.[idx];

        if (!icon) return null;

        return (
          <Box
            key={link}
            rc={
              link ? (
                <NextLink
                  onClick={() => analyticsEvent(link, "Platform")}
                  href={link}
                  target="_blank"
                  referrerPolicy="no-referrer"
                />
              ) : undefined
            }
          >
            <Icon sx={{ w: "base.icon.size.300" }} icon={icon} key={platform} />
          </Box>
        );
      })}
    </Stack>
  );
};

type SpecsProps = {
  specs: { label: string; value: string; links?: string[] }[];
  analyticsEvent: (link: string, specLabel: string) => void;
};

const Specs = ({ specs, analyticsEvent }: SpecsProps): React.ReactNode => {
  const children: React.ReactNode[] = [];
  children.push(
    <Divider
      size={"xSmall"}
      sx={{ mt: "base.spacing.x8" }}
      key="Divider--start"
    />,
  );

  for (let i = 0; i < specs.length; i++) {
    const spec = specs[i];
    children.push(
      <Fragment key={spec.label}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ my: "base.spacing.x4" }}
        >
          <Body
            size={"small"}
            sx={{ color: "base.color.text.body.secondary", flex: 1 }}
          >
            {spec.label}
          </Body>
          {spec.label === "Platform" ? (
            <Platforms
              platforms={spec.value.split(", ")}
              links={spec.links}
              analyticsEvent={analyticsEvent}
            />
          ) : (
            <Body
              size={"small"}
              sx={{
                color: "base.color.text.body.primary",
                textAlign: "right",
                textDecoration: "none",
              }}
              rc={
                spec.links?.[0] ? (
                  <NextLink
                    onClick={() =>
                      analyticsEvent(spec?.links?.[0] || "", spec.label)
                    }
                    href={spec.links[0]}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  />
                ) : undefined
              }
            >
              {spec.value}
            </Body>
          )}
        </Stack>
        <Divider size={"xSmall"} />
      </Fragment>,
    );
  }
  return children;
};

export default GameSpecs;
