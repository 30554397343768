import { ActivationVariants } from "@/components/emptystates/useActivationExperiment";
import { useFeatureFlag } from "@/context";
import { useAccessToken } from "@/hooks/useAccessToken";
import { playNowGames, upcomingGames } from "@/pages/games";
import type { Game } from "@/types";
import { authorizedGet } from "@/utils/request";
import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";

export function useGetGrants() {
  const getAccessToken = useAccessToken();
  const { grantsApiEnabled } = useFlags();
  const { flags } = useFeatureFlag();
  const isCarouselVariant =
    flags?.activation0Experiment === ActivationVariants.carousel;

  return useQuery<Game[], Error>({
    queryKey: ["grants"],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return [];

      const response = await authorizedGet(
        "/passport-profile/v1/grants",
        accessToken,
      );
      return response
        .flatMap(
          (item: {
            client_id: string;
          }) => findGameByClientId(item.client_id),
        )
        .filter((game: Game) => !!game);
    },
    enabled: isCarouselVariant && grantsApiEnabled === true,
  });
}

const findGameByClientId = (clientId: string): Game[] => {
  const allGames = [...playNowGames(), ...upcomingGames()];
  return allGames.filter((game) => game.context?.clientIds?.includes(clientId));
};
