import { Box, Button, Icon, Link } from "@biom3/react";
import { useTranslation } from "react-i18next";

type ImportToolbarProps = {
  allSelected: boolean;
  importCount: number | undefined;
  cancelClick: () => void;
  importClick: () => void;
  selectAllClick: () => void;
};

const ImportToolbar = ({
  allSelected,
  importCount,
  cancelClick,
  importClick,
  selectAllClick,
}: ImportToolbarProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        d: "flex",
        flexDirection: "row",
        pb: "base.spacing.x6",
      }}
    >
      <Button onClick={cancelClick} variant={"tertiary"}>
        {t("cancel")}
      </Button>
      <Button
        onClick={importClick}
        variant={"secondary"}
        sx={{ mx: "base.spacing.x4" }}
        disabled={!importCount}
      >
        <Icon icon="Import" sx={{ width: "base.icon.size.200" }} />
        {importCount
          ? t("import_items", { count: importCount })
          : t("select_to_import")}
      </Button>
      <Box rc={<span />} sx={{ flex: 1 }} />
      <Link sx={{ alignSelf: "flex-end" }} onClick={selectAllClick}>
        {t(allSelected ? "deselect_all" : "select_all")}
      </Link>
    </Box>
  );
};

export { ImportToolbar };
