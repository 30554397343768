import { BAD_IMAGE_URL, MODAL_WIDTH } from "@/constants";
import { useAnalytics } from "@/context";
import { Box, ConfirmationDialog, Heading } from "@biom3/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type AcceptOfferStatusModalProps = {
  state?: "SUCCESS" | "ERROR" | "LOADING";
  errorMessage?: string;
  successMessage?: string;
  onRetry?: () => void;
  onClose?: () => void;
  itemName?: string;
  itemImage?: string;
  offerPrice?: string;
  onGoToMarketplace?: () => void;
};

type ImageWithOverlayProps = {
  src: string;
  alt: string;
  overlaySrc?: string;
  width?: string;
  height?: string;
  mt?: string;
  mb?: string;
};

const overlayImage = "/listingErrorModal.svg";
const successImage = "/acceptOfferSuccess.svg";
const defaultImage =
  "https://biome.immutable.com/hosted-assets/images/cloudImageDefaultImage--onDark.svg";

const ImageWithOverlay = ({
  src,
  alt,
  overlaySrc,
  width,
  height,
  mt,
  mb,
}: ImageWithOverlayProps) => {
  const [mainImageSrc, setMainImageSrc] = useState<string>(src);

  useEffect(() => {
    setMainImageSrc(src);
  }, [src]);

  return (
    <Box
      sx={{
        d: "flex",
        justifyContent: "center",
        position: "relative",
        width: width ?? "128px",
        height: height ?? "128px",
        mt: mt,
        mb: mb || "base.spacing.x3",
      }}
    >
      <img
        src={mainImageSrc}
        alt={alt}
        width={width}
        height={height}
        style={{ borderRadius: 8 }}
        onError={() => {
          setMainImageSrc(defaultImage);
        }}
      />
      {overlaySrc && (
        <img
          src={overlaySrc}
          alt="Overlay"
          style={{
            position: "absolute",
            top: 55,
            left: 90,
            width: 77,
            height: 95,
          }}
        />
      )}
    </Box>
  );
};

export const AcceptOfferStatusModal = ({
  state,
  onRetry,
  onClose,
  itemName,
  itemImage,
  offerPrice,
  onGoToMarketplace,
}: AcceptOfferStatusModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { track } = useAnalytics();

  useEffect(() => {
    const open = state !== undefined;
    setIsModalOpen(open);

    if (open) {
      let control: string;
      switch (state) {
        case "SUCCESS":
          control = "AcceptOfferSuccess";
          break;
        case "ERROR":
          control = "AcceptOfferFailure";
          break;
        default:
          control = "AcceptOfferLoading";
          break;
      }
      track({
        userJourney: "Bid",
        screen: "AcceptOfferStatusModal",
        control: control,
        controlType: "Effect",
      });
    }
  }, [state, track]);

  const handleClose = useCallback(() => {
    track({
      userJourney: "Bid",
      screen: "AcceptOfferStatusModal",
      control: "Close",
      controlType: "Button",
      action: "Pressed",
    });

    if (onClose) onClose();
    setIsModalOpen(false);
  }, [onClose, track]);

  const handleRetry = useCallback(() => {
    track({
      userJourney: "Bid",
      screen: "AcceptOfferStatusModal",
      control: "Retry",
      controlType: "Button",
      action: "Pressed",
    });

    if (onRetry) onRetry();
  }, [onRetry, track]);

  const handleGoToMarketplace = useCallback(() => {
    track({
      userJourney: "Bid",
      screen: "AcceptOfferStatusModal",
      control: "GoToMarketplace",
      controlType: "Button",
      action: "Pressed",
    });

    if (onGoToMarketplace) onGoToMarketplace();
    setIsModalOpen(false);
  }, [onGoToMarketplace, track]);

  const modalContent = useMemo(() => {
    switch (state) {
      case "SUCCESS":
        return {
          title: t("accept_offer_success_title", { itemName }),
          offerPrice: offerPrice,
          description: t("accept_offer_success_description"),
          imageSrc: successImage,
          primaryButton: {
            text: t("go_to_marketplace"),
            action: handleGoToMarketplace,
          },
          secondaryButton: {
            text: t("close"),
            action: handleClose,
          },
          imageWidth: "430px",
          imageHeight: "240px",
        };
      case "ERROR":
        return {
          title: t("accept_offer_error_title", { itemName }),
          description: t("accept_offer_error_description"),
          imageSrc: itemImage,
          primaryButton: onRetry
            ? {
                text: t("retry"),
                action: handleRetry,
              }
            : undefined,
          secondaryButton: {
            text: t("Close"),
            action: handleClose,
          },
          mt: "base.spacing.x20",
        };
      case "LOADING":
        return {
          imageSrc: itemImage,
          title: t("accept_offer_loading_title"),
          mt: "base.spacing.x20",
        };
      default:
        return undefined;
    }
  }, [
    state,
    itemName,
    itemImage,
    t,
    handleClose,
    handleRetry,
    onRetry,
    handleGoToMarketplace,
    offerPrice,
  ]);

  if (!modalContent) return null;

  return (
    <ConfirmationDialog visible={isModalOpen} outsideClicksClose={false}>
      <ConfirmationDialog.Content
        sx={{
          d: "flex",
          width: MODAL_WIDTH,
          height: "650px",
          alignItems: "center",
          background: "base.color.neutral.900",
        }}
      >
        <ConfirmationDialog.Content.Image
          alt={modalContent.title}
          use={
            <ImageWithOverlay
              src={modalContent.imageSrc ?? BAD_IMAGE_URL}
              alt={modalContent.title}
              overlaySrc={state === "ERROR" ? overlayImage : undefined}
              width={modalContent.imageWidth ?? "128px"}
              height={modalContent.imageHeight ?? "128px"}
              mt={modalContent.mt}
            />
          }
        />
        <ConfirmationDialog.Content.Title sx={{ px: "base.spacing.x12" }}>
          {modalContent.title}
        </ConfirmationDialog.Content.Title>
        {modalContent.offerPrice && (
          <ConfirmationDialog.Content.Caption
            sx={{
              px: "base.spacing.x12",
              color: "base.color.text.heading.emphasis",
            }}
          >
            <Heading>{modalContent.offerPrice}</Heading>
          </ConfirmationDialog.Content.Caption>
        )}

        {modalContent.description && (
          <ConfirmationDialog.Content.Caption sx={{ px: "base.spacing.x12" }}>
            {modalContent.description}
          </ConfirmationDialog.Content.Caption>
        )}
        {modalContent.primaryButton && (
          <ConfirmationDialog.Content.Button
            size="large"
            variant="primary"
            onClick={modalContent.primaryButton.action}
          >
            {modalContent.primaryButton.text}
          </ConfirmationDialog.Content.Button>
        )}
        {modalContent.secondaryButton && (
          <ConfirmationDialog.Content.Button
            size="large"
            variant="tertiary"
            onClick={modalContent.secondaryButton.action}
          >
            {modalContent.secondaryButton.text}
          </ConfirmationDialog.Content.Button>
        )}
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
};
