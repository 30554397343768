import { Box, LoadingOverlay, Modal, usePrevious } from "@biom3/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserRejectedRequestError } from "viem";
import { type Connector, useDisconnect } from "wagmi";

import { ReviewImportItems } from "@/components/collections/ReviewImportItems";
import { useAnalytics } from "@/context";
import {
  AddressMismatchError,
  useFetchWallets,
  useZkBatchTransfer,
} from "@/hooks";
import {
  type ChainAddress,
  type ItemDetails,
  type Wallet,
  WalletTypes,
} from "@/types";

import { useAssetImport } from "@/context/AssetImportProvider";
import { TransferFailureModalContent } from "./TransferFailureModalContent";
import { TransferTransactionModalContent } from "./TransferTransactionModalContent";

function LoadingOverlayContent({ text }: { text: string[] }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        p: "base.spacing.x12",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <LoadingOverlay.Content sx={{ alignItems: "center" }}>
        <LoadingOverlay.Content.LoopingText text={text} />
      </LoadingOverlay.Content>
    </Box>
  );
}

function TransferModal({
  visible,
  items,
  collectionAddress,
  onCloseModal,
  connector,
  source,
}: {
  visible: boolean;
  items: ItemDetails[];
  collectionAddress: ChainAddress;
  onCloseModal: () => void;
  connector: Connector;
  source: Wallet;
}) {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const {
    data: walletsData,
    error: walletsError,
    isLoading: walletsIsLoading,
  } = useFetchWallets();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { disconnectAsync } = useDisconnect();
  const { activeImport, endImport, startImport } = useAssetImport();

  const destination = walletsData?.find(
    (wallet: Wallet) => wallet.type === WalletTypes.PASSPORT,
  );

  const tokenIds = items.map((item) => item.tokenId);

  const {
    transfer,
    reset: resetTransfer,
    error: transferError,
    status: transferStatus,
    hash,
  } = useZkBatchTransfer(
    connector,
    collectionAddress,
    source?.address,
    destination?.address,
    tokenIds,
  );

  const previousTransferStatus = usePrevious(transferStatus);

  useEffect(() => {
    if (previousTransferStatus === transferStatus) return;

    switch (transferStatus) {
      case "txSubmitted":
        // This is a pseudo success event, users can exit the dialog after this shows
        track({
          screen: "Submitted",
          userJourney: "AssetTransfer",
          control: tokenIds.length === 1 ? "SingleImport" : "BulkImport",
          controlType: "Effect",
          extras: {
            flowId: activeImport?.trackingId,
            itemCount: tokenIds.length,
            contractAddress: collectionAddress,
          },
        });
        break;
      case "error":
        track({
          screen: "Modal",
          userJourney: "AssetTransfer",
          control: "Failed",
          controlType: "Effect",
          extras: {
            flowId: activeImport?.trackingId,
            error: transferError?.message,
          },
        });
        break;
      case "success":
        // Will only trigger if the user stays on the dialog
        track({
          screen: "Success",
          userJourney: "AssetTransfer",
          control: tokenIds.length === 1 ? "SingleImport" : "BulkImport",
          controlType: "Effect",
          extras: {
            flowId: activeImport?.trackingId,
            itemCount: tokenIds.length,
            contractAddress: collectionAddress,
          },
        });
        break;
    }
  }, [
    transferStatus,
    tokenIds,
    activeImport,
    transferError,
    track,
    collectionAddress,
    previousTransferStatus,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!visible) return;

    track({
      screen: "Modal",
      userJourney: "AssetTransfer",
      control: "Opened",
      controlType: "Effect",
      extras: {
        flowId: activeImport?.trackingId,
      },
    });
  }, [visible]);

  const closeModal = useCallback(() => {
    resetTransfer();
    onCloseModal();
  }, [resetTransfer, onCloseModal]);

  const importMore = useCallback(() => {
    track({
      screen: "Modal",
      userJourney: "AssetTransfer",
      control: "ImportMore",
      controlType: "Button",
      action: "Pressed",
    });
    startImport?.(
      { pathname: "/inventory" },
      source.address,
      "ImportSuccess",
      true,
    );
    closeModal();
  }, [startImport, source, closeModal, track]);

  if (transferError?.cause instanceof UserRejectedRequestError) {
    closeModal();
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const onTransferError = async () => {
      if (transferError instanceof AddressMismatchError) {
        await disconnectAsync({ connector });
      }
    };
    onTransferError();
  }, [transferError]);

  const loading =
    transferStatus === "pending" || transferStatus === "txSubmitting";
  const missingWallets = source === undefined || destination === undefined;
  const missingWalletsError =
    !walletsIsLoading && missingWallets
      ? new Error("missingWallets")
      : undefined;
  const error = walletsError || transferError || missingWalletsError;

  const renderContent = () => {
    if (error) {
      return (
        <TransferFailureModalContent onConfirm={closeModal} error={error} />
      );
    }
    if (walletsIsLoading) {
      return (
        <LoadingOverlayContent text={[t("asset_transfer_wallet_loading")]} />
      );
    }
    if (loading) {
      return (
        <LoadingOverlayContent
          text={[
            t("asset_transfer_confirming", { connectorName: connector.name }),
          ]}
        />
      );
    }
    if (transferStatus === "txSubmitted") {
      return (
        <TransferTransactionModalContent
          status="progress"
          onImportMore={importMore}
          onDone={() => {
            endImport?.();
            if (router.pathname === "/item/[...slug]") closeModal();
            else router.push("/inventory");
          }}
          hash={hash}
        />
      );
    }
    if (transferStatus === "success") {
      return (
        <TransferTransactionModalContent
          status="success"
          onImportMore={importMore}
          onDone={() => {
            endImport?.();
            if (router.pathname === "/item/[...slug]") {
              queryClient.invalidateQueries({
                queryKey: [
                  "item-details",
                  items[0].network,
                  items[0].collection,
                  items[0].tokenId,
                ],
              });
              closeModal();
            } else router.push("/inventory");
          }}
          hash={hash}
        />
      );
    }
    if (transferStatus === "idle") {
      return (
        <ReviewImportItems
          items={items}
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          source={source!}
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          destination={destination!}
          activeImport={activeImport}
          onConfirm={transfer}
          onClose={onCloseModal}
        />
      );
    }
    return null;
  };

  return (
    <Modal visible={visible}>
      <Modal.Content>{renderContent()}</Modal.Content>
    </Modal>
  );
}

export { TransferModal };
