import type { User } from "@braze/web-sdk";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context";
import type { SubscriptionStatus } from "@/types";

import { notifyError } from "@/utils/monitoring";
import { isImmutableUser, isSandbox } from "@/utils/util";
import { usePrevious } from "@biom3/react";
import { usePreferences } from "./PreferencesProvider";

export const BrazeContext = createContext<{
  unsubscribe: () => void;
  optIn: () => void;
}>({
  unsubscribe: () => {},
  optIn: () => {},
});

const useBraze = () => {
  const ctx = useContext(BrazeContext);
  if (!ctx) {
    throw new Error("useBraze must be used within a BrazeProvider");
  }

  return ctx;
};

// https://www.braze.com/docs/user_guide/message_building_by_channel/email/managing_user_subscriptions/
const BrazeProvider = ({ children }: PropsWithChildren) => {
  const { userInfo } = usePassportProvider();
  const { data, update } = usePreferences();
  const previousUserInfo = usePrevious(userInfo);
  const [brazeUser, setBrazeUser] = useState<User | undefined>();

  // Init to identify anonymous users
  useEffect(() => {
    if (isSandbox()) {
      return;
    }

    import("@/utils/braze/exports").then((braze) => {
      const success = braze.initialize(appConfig.BRAZE_API_KEY, {
        baseUrl: appConfig.BRAZE_API_ENDPOINT,
      });
      if (success) {
        braze.openSession();
      } else {
        notifyError("Failed to initialize Braze for anonymous users", "braze");
      }
    });
  }, []);

  // Identify authenticated users
  useEffect(() => {
    if (userInfo === undefined || userInfo === previousUserInfo) {
      return;
    }

    // For Sandbox only initialize Braze for Immutable emails
    if (isSandbox() && !isImmutableUser(userInfo)) {
      return;
    }

    // Initialise every time to avoid race conditions with useEffect dependencies
    import("@/utils/braze/exports").then((braze) => {
      const success = braze.initialize(appConfig.BRAZE_API_KEY, {
        baseUrl: appConfig.BRAZE_API_ENDPOINT,
      });
      if (success) {
        braze.changeUser(userInfo.sub);
        const user = braze.getUser();
        if (user) {
          if (userInfo.email) {
            user.setEmail(userInfo.email);
          }
          braze.openSession();
          setBrazeUser(user);
        } else {
          notifyError("Failed to identify user", "braze");
        }
      } else {
        notifyError(
          "Failed to initialize Braze for authenticated user",
          "braze",
        );
      }
    });
  }, [userInfo, previousUserInfo]);

  const updatePreference = useCallback(
    (marketing_consent: SubscriptionStatus) => {
      if (userInfo) {
        brazeUser?.setPushNotificationSubscriptionType(marketing_consent);
        brazeUser?.setEmailNotificationSubscriptionType(marketing_consent);
        update({ ...data, marketing_consent, onboard: false });
      }
    },
    [userInfo, update, data, brazeUser],
  );

  return (
    <BrazeContext.Provider
      value={{
        unsubscribe: () => updatePreference("unsubscribed"),
        optIn: () => updatePreference("opted_in"),
      }}
    >
      {children}
    </BrazeContext.Provider>
  );
};

export { BrazeProvider, useBraze };
