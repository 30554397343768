import {
  type AllSingleVariantIconKeys,
  Body,
  ButtCon,
  Heading,
  Icon,
  Stack,
} from "@biom3/react";
import type { Content } from "@prismicio/client";
import NextLink from "next/link";

import type { AnalyticsType } from "@cms/context";
import { getLinkedDocument, getWebLink } from "@cms/utils";
import { Breadcrumb } from "@ui-kit/GameHeader/Breadcrumb";

type GameHeaderProps = {
  game: Content.GameDocument;
  analytics: AnalyticsType;
};

const PageTitle = ({
  url,
  title,
  onClick,
}: {
  url: string;
  title: string;
  onClick: () => void;
}) => (
  <Stack direction="row" alignItems="center" gap="base.spacing.x6">
    <ButtCon
      icon="ArrowBackward"
      variant="primary"
      size="small"
      rc={<NextLink href={url} onClick={() => onClick()} />}
    />
    <Heading size="large" weight="bold">
      {title}
    </Heading>
  </Stack>
);

const Platforms = ({
  platforms,
}: {
  platforms: Content.GameDocument["data"]["platforms"];
}) => {
  const mapIcons = (network: string): AllSingleVariantIconKeys | undefined => {
    switch (network) {
      case "App Store":
        return "Apple";
      case "Play Store":
        return "GooglePlayStore";
    }

    return undefined;
  };

  return (
    <Stack direction={"row"} gap={"base.spacing.x6"}>
      {platforms.map(({ link: linkItem, platform: item }) => {
        const platform = getLinkedDocument<
          Content.GamePlatformDocument,
          Content.GamePlatformDocumentData
        >(item);
        const icon = mapIcons(platform?.data?.name || "");

        if (!platform?.data?.name || !icon) return null;

        const link = getWebLink(linkItem);

        return (
          <Stack direction="row" gap="base.spacing.x2" key={icon}>
            <Icon sx={{ w: "base.icon.size.250" }} icon={icon} />
            <Body
              weight={"bold"}
              size="small"
              sx={{
                color: "base.color.fixed.white.1000",
                textDecoration: "none",
              }}
              rc={
                link?.url ? (
                  <NextLink
                    href={link.url}
                    referrerPolicy="no-referrer"
                    target="_blank"
                  />
                ) : undefined
              }
            >
              {platform.data.name}
            </Body>
          </Stack>
        );
      })}
    </Stack>
  );
};

export const GameHeader = ({ game, analytics }: GameHeaderProps) => {
  const landingPageUrl = "/games";
  const pageName = game.data.name || "";

  const handleBackButtonClick = (controlType = "ButtCon") => {
    analytics.track({
      screen: "Details",
      userJourney: "Games",
      control: "BackToGamesListClicked",
      controlType,
      extras: {
        gameName: game.data.name,
        gameUid: game.uid,
      },
    });
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      label: "Games",
      url: landingPageUrl,
      icon: "ESports",
      onClick: () => handleBackButtonClick("Breadcrumb"),
    },
    {
      label: pageName,
    },
  ];

  return (
    <Stack direction="column" justifyContent="left" gap="base.spacing.x3">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PageTitle
        title={pageName}
        url={landingPageUrl}
        onClick={() => handleBackButtonClick()}
      />
      <Platforms platforms={game.data.platforms} />
    </Stack>
  );
};
