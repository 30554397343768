import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { retryQuery } from "@/utils/request";
import type { PropsWithChildren } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Can enable individually but authorized requests shouldn't do this as the access token will be out of date
      refetchOnWindowFocus: false,
      retry: retryQuery,
    },
  },
});

export function QueryProvider({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
