import { BaseEmptyState } from "@/components/emptystates/BaseEmptyState";
import {
  CAROUSEL_IMAGE_SIZE,
  CAROUSEL_IMAGE_SIZE_SMALL,
  appConfig,
} from "@/constants";
import { playNowGames } from "@/pages/games";
import type { Game } from "@/types";
import { Box, CarouselSimple, FramedImage, Stack } from "@biom3/react";
import Autoplay from "embla-carousel-autoplay";
import { useMemo } from "react";

export const CarouselEmptyState = ({
  creatingWallet,
  titleKey,
  captionKey,
  primaryButtonKey,
  secondaryButtonKey,
  primaryButtonClick,
  secondaryButtonClick,
  gameData,
}: {
  creatingWallet: boolean;
  titleKey: string;
  captionKey: string;
  primaryButtonKey: string;
  secondaryButtonKey: string;
  primaryButtonClick: () => void;
  secondaryButtonClick: () => void;
  gameData?: Game;
}) => {
  const games = useMemo(() => {
    const shuffledGames = playNowGames().sort(() => 0.5 - Math.random());
    return gameData
      ? shuffledGames.filter((game) => game.name !== gameData.name).slice(0, 4)
      : shuffledGames.slice(0, 10);
  }, [gameData]);

  return (
    <Stack
      alignItems={"center"}
      sx={{
        w: "calc(100% + 48px)",
        ml: "-24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "base.spacing.x6",
        }}
      >
        <CarouselSimple
          loop={!gameData}
          slideWidth={
            gameData ? CAROUSEL_IMAGE_SIZE_SMALL : CAROUSEL_IMAGE_SIZE
          }
          slideHeight={
            gameData ? CAROUSEL_IMAGE_SIZE_SMALL : CAROUSEL_IMAGE_SIZE
          }
          highlightActiveSlide={false}
          sx={gameData ? { opacity: 0.6, zIndex: 1 } : {}}
          plugins={
            gameData
              ? []
              : [
                  Autoplay({
                    playOnInit: true,
                    delay: 2000,
                    stopOnInteraction: false,
                  }),
                ]
          }
        >
          {games.map((game) => (
            <CarouselSimple.Slide key={game.name}>
              <FramedImage
                sx={{ w: "100%" }}
                imageUrl={`${appConfig.ASSET_BASE_URL}/games/${game.image}.webp`}
                relativeImageSizeInLayout={
                  gameData ? CAROUSEL_IMAGE_SIZE_SMALL : CAROUSEL_IMAGE_SIZE
                }
              />
            </CarouselSimple.Slide>
          ))}
        </CarouselSimple>
        {gameData && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              width: CAROUSEL_IMAGE_SIZE,
              height: CAROUSEL_IMAGE_SIZE,
            }}
          >
            <FramedImage
              sx={{
                w: "100%",
                borderRadius: "base.borderRadius.x4",
              }}
              imageUrl={`${appConfig.ASSET_BASE_URL}/games/${gameData.image}.webp`}
              relativeImageSizeInLayout={CAROUSEL_IMAGE_SIZE}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ px: "base.spacing.x6" }}>
        <BaseEmptyState
          creatingWallet={creatingWallet}
          titleKey={titleKey}
          captionKey={captionKey}
          primaryButtonKey={primaryButtonKey}
          secondaryButtonKey={secondaryButtonKey}
          primaryMouseOver={() => {}}
          primaryButtonClick={primaryButtonClick}
          secondaryButtonClick={secondaryButtonClick}
        />
      </Box>
    </Stack>
  );
};
