import {
  type BadgeProps,
  Box,
  Divider,
  type IconSubcomponentDiscrimintatedUnion,
  MenuItem,
  Stack,
  isDualVariantIcon,
} from "@biom3/react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Fragment,
  type Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { SIDEBAR_WIDTH } from "@/constants";
import { useAnalytics, useFeatureFlag, usePassportProvider } from "@/context";
import { useAssetImport } from "@/context/AssetImportProvider";
import { useCheckout } from "@/context/CheckoutProvider";
import { usePreferences } from "@/context/PreferencesProvider";
import {
  useCoinBalancesZkEVMQuery,
  useCollectionsQuery,
  useFetchWallets,
} from "@/hooks";
import { useFlag } from "@/hooks/useFlags";
import { useGetWalletGems } from "@/hooks/useGetWalletGems";
import {
  type Collection,
  type HideActivationBadges,
  WalletTypes,
} from "@/types";
import { ActivationVariants } from "../emptystates/useActivationExperiment";
import { LegalLinks } from "./LegalLinks";
import { LoadingModal } from "./LoadingModal";

type MenuItemType = IconSubcomponentDiscrimintatedUnion & {
  visible: boolean;
  key: string;
  label: string;
  path: string;
  badge?: {
    badgeContent?: (count: number) => string;
    variant: BadgeProps["variant"];
    isAnimated?: boolean;
  };
  divider?: boolean;
  linkProps?: {
    isExternal?: boolean;
    isTrusted?: boolean;
  };
};

const getMenuItems = ({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}): MenuItemType[] => [
  {
    visible: true,
    key: "games",
    label: "games",
    path: "/games",
    icon: "ESports",
    iconVariant: "bold",
  },
  {
    visible: true,
    key: "quest_gems",
    label: "daily_gems",
    path: "/quest-gems",
    icon: "ImxRewards",
    iconVariant: "bold",
    badge: {
      variant: "emphasis",
      badgeContent: (count: number) => `${count} claimed`,
    },
    divider: true,
  },
  {
    visible: true,
    key: "daily_gems",
    label: "daily_gems",
    path: "https://imx.community/gems",
    icon: "ImxRewards",
    iconVariant: "bold",
    badge: {
      variant: "emphasis",
      badgeContent: (count: number) => `${count} claimed`,
    },
    linkProps: {
      isExternal: true,
      isTrusted: true,
    },
    divider: true,
  },
  {
    visible: true,
    key: "balances",
    label: "balance",
    path: "/balance",
    icon: "Wallet",
    iconVariant: "bold",
    badge: { variant: "guidance", isAnimated: true },
  },
  {
    visible: true,
    key: "inventory",
    label: "inventory",
    path: "/inventory",
    icon: "TreasureChest",
    iconVariant: "bold",
    badge: { variant: "guidance", isAnimated: true },
  },
  {
    visible: isLoggedIn,
    key: "external_wallets",
    label: "external_wallets",
    path: "/external-wallets",
    icon: "WalletConnect",
    badge: { variant: "guidance", isAnimated: true },
  },
  {
    visible: isLoggedIn,
    key: "logout",
    label: "logout",
    path: "/logout",
    icon: "Logout",
    iconVariant: "bold",
  },
];

export function SideMenu({
  domRef,
  onItemClick,
  fullWidth,
}: {
  domRef: Ref<HTMLDivElement>;
  onItemClick: () => void;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { activeImport } = useAssetImport();
  const [loggingOut, setLoggingOut] = useState(false);
  const sidebarWidth = fullWidth ? "100%" : SIDEBAR_WIDTH;
  const { walletAddress, logout, isLoggedIn } = usePassportProvider();
  const { data } = useGetWalletGems(walletAddress);
  const { checkoutClient, checkoutNetwork } = useCheckout();
  const { data: preferencesData, update: updatePreferences } = usePreferences();
  const { data: coinBalancesData } = useCoinBalancesZkEVMQuery(
    walletAddress,
    checkoutClient,
    checkoutNetwork,
  );
  const { zkEvmData, starkExData, refetchZkEvm, refetchStarkEx } =
    useCollectionsQuery(activeImport?.importAddress, true);
  const { data: walletsData } = useFetchWallets(true);
  const [shouldRefetchInventory, setShouldRefetchInventory] = useState(false);
  const { track } = useAnalytics();
  const { flags } = useFeatureFlag();
  const activation0Experiment = useMemo(
    () => flags?.activation0Experiment ?? ActivationVariants.control,
    [flags],
  );
  // useFlag works for both loggedIn and loggedOut states
  const questAndGemsPage = useFlag("questAndGemsPage");

  const menuItems = getMenuItems({ isLoggedIn });
  const menuItemsFiltered = questAndGemsPage
    ? menuItems.filter((x) => x.key !== "daily_gems")
    : menuItems.filter((x) => x.key !== "quest_gems");

  const inventoryData = useMemo(() => {
    let collections: Collection[] = [];

    if (zkEvmData) {
      collections = collections.concat(
        zkEvmData.pages.flatMap((page) => page.collections),
      );
    }

    if (starkExData) {
      collections = collections.concat(
        starkExData.pages.flatMap((page) => page.collections),
      );
    }

    return { collections };
  }, [zkEvmData, starkExData]);

  const refetchInventoryData = useCallback(() => {
    refetchZkEvm();
    refetchStarkEx();
  }, [refetchZkEvm, refetchStarkEx]);

  // Update preferences based on coin balances
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      coinBalancesData &&
      coinBalancesData.length > 0 &&
      preferencesData &&
      !preferencesData.hide_activation_badges.balances
    ) {
      track({
        screen: "EmptyState",
        userJourney: "Balances",
        control: "HideActivationBadge",
        controlType: "Effect",
      });
      updatePreferences({
        hide_activation_badges: {
          ...preferencesData.hide_activation_badges,
          balances: true,
        },
      });
    }
  }, [coinBalancesData, preferencesData, updatePreferences]);

  // Check for inventory data and update preferences
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      preferencesData &&
      !preferencesData.hide_activation_badges.inventory &&
      inventoryData &&
      inventoryData.collections.length > 0
    ) {
      track({
        screen: "EmptyState",
        userJourney: "Collections",
        control: "HideActivationBadge",
        controlType: "Effect",
      });
      updatePreferences({
        hide_activation_badges: {
          ...preferencesData.hide_activation_badges,
          inventory: true,
          external_wallets: true,
        },
      });
    }
  }, [inventoryData, preferencesData, updatePreferences]);

  // Check for external wallets and set flag for inventory refetch
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (walletsData && preferencesData) {
      const externalLinkedWallets = walletsData.filter(
        (wallet) => wallet.type !== WalletTypes.PASSPORT,
      );
      if (
        externalLinkedWallets.length > 0 &&
        !preferencesData.hide_activation_badges.external_wallets
      ) {
        track({
          screen: "EmptyState",
          userJourney: "Wallets",
          control: "HideActivationBadge",
          controlType: "Effect",
        });
        updatePreferences({
          hide_activation_badges: {
            ...preferencesData.hide_activation_badges,
            external_wallets: true,
          },
        });
        setShouldRefetchInventory(true);
      }
    }
  }, [walletsData, preferencesData, updatePreferences]);

  // Refetch inventory data when external wallets are linked and update inventory badge status
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const updateInventoryBadge = async () => {
      if (shouldRefetchInventory && preferencesData) {
        await refetchInventoryData();
        setShouldRefetchInventory(false);
      }
    };
    updateInventoryBadge();
  }, [
    shouldRefetchInventory,
    refetchInventoryData,
    inventoryData,
    preferencesData,
    updatePreferences,
  ]);

  const onItem = () => {
    onItemClick();
  };

  const onGemsTab = () => {
    track({
      userJourney: "Gems",
      screen: "PassportDashboard",
      control: "SideMenu",
      controlType: "Link",
      action: "Pressed",
    });
    onItemClick();
  };

  const onLogout = () => {
    onItemClick();
    setLoggingOut(true);
    logout();
  };

  const shouldDisplayBadge = useCallback(
    (key: string) => {
      if (
        preferencesData &&
        activation0Experiment !== ActivationVariants.control
      ) {
        const { hide_activation_badges: hideActivationBadges } =
          preferencesData;
        return hideActivationBadges[key as keyof HideActivationBadges] !== true;
      }
    },
    [preferencesData, activation0Experiment],
  );

  return (
    <Box
      domRef={domRef}
      sx={{
        w: "100%",
        flexShrink: 0,
        maxw: sidebarWidth,
        h: "100%",
        top: [null, null, "0px"],
        pos: "sticky",
        d: "flex",
        flexDirection: "column",
        transitionProperty: "transform",
        transitionDuration: "base.animation.fast.cssDuration",
        transitionTimingFunction: "base.animation.fast.cssEasing",
        overflow: "auto",
        bradtr: "base.borderRadius.x8",
        bradbr: "base.borderRadius.x8",
        zIndex: 2,
        background: "base.color.translucent.standard.150",
      }}
      rc={<aside />}
    >
      <Stack
        sx={{
          minh: "100%",
          px: "base.spacing.x4",
          pt: "base.spacing.x4",
        }}
        gap="base.spacing.x1"
      >
        {menuItemsFiltered.map((item) => (
          <Fragment key={item.key}>
            {item.visible ? (
              <>
                <MenuItem
                  testId={`menu-item__${item.label}`}
                  size="xSmall"
                  sx={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    "&.selected": {
                      backgroundColor:
                        "var(--on-dark-base-color-translucent-emphasis-500, rgba(243, 243, 243, 0.20))",
                    },
                  }}
                  selected={router.pathname === item.path}
                  {...(item.path === "/logout"
                    ? { onClick: onLogout }
                    : {
                        rc: (
                          <Link
                            href={`${item.path}`}
                            target={
                              item.linkProps?.isExternal ? "_blank" : undefined
                            }
                            referrerPolicy={
                              item.linkProps?.isTrusted
                                ? "origin"
                                : item.linkProps?.isExternal
                                  ? "no-referrer"
                                  : undefined
                            }
                            rel={
                              item.linkProps?.isTrusted
                                ? "external"
                                : item.linkProps?.isExternal
                                  ? "noreferrer"
                                  : undefined
                            }
                          />
                        ),
                        onClick: item.key === "daily_gems" ? onGemsTab : onItem,
                      })}
                >
                  <MenuItem.Label>{t(item.label)}</MenuItem.Label>
                  <MenuItem.Icon
                    testId={`menu-item__${item.icon}`}
                    {...(isDualVariantIcon(item.icon)
                      ? { icon: item.icon, variant: item.iconVariant }
                      : {
                          icon: item.icon,
                        })}
                  />

                  {item.badge ? (
                    item.key === "daily_gems" ? (
                      <>
                        {/* Only show Gems if logged into Passport */}
                        {isLoggedIn && (
                          <MenuItem.Badge
                            variant={item.badge.variant}
                            isAnimated={item.badge.isAnimated}
                            {...(data
                              ? {
                                  badgeContent: item?.badge?.badgeContent?.(
                                    data.gems,
                                  ),
                                  testId: "menu-item__badge-gems",
                                }
                              : { testId: "menu-item__badge-dot" })}
                          />
                        )}
                      </>
                    ) : (
                      shouldDisplayBadge(item.key) && (
                        <MenuItem.Badge
                          testId={"menu-item__badge-dot"}
                          variant={item.badge.variant}
                          isAnimated={item.badge.isAnimated}
                        />
                      )
                    )
                  ) : null}
                  {item.linkProps?.isExternal ? (
                    <MenuItem.IntentIcon
                      icon="JumpTo"
                      sx={{ fill: "base.color.text.body.primary" }}
                    />
                  ) : null}
                </MenuItem>
                {item.divider && (
                  <Divider size="xSmall" sx={{ my: "base.spacing.x6" }} />
                )}
              </>
            ) : null}
          </Fragment>
        ))}

        <LegalLinks
          containerSx={{
            mt: "auto",
            px: "base.spacing.x2",
            py: "base.spacing.x10",
          }}
        />
      </Stack>
      <LoadingModal loading={loggingOut} title={t("logging_out")} />
    </Box>
  );
}
