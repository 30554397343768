import type { DeeplyNestedSx, SxProps } from "@biom3/react";

import { WalletTypes } from "@/types";
import { safeTestId } from "@/utils/util";

type WalletIconProps = {
  sx: SxProps;
  alt: string;
  defaultImagePath: string;
  imagePath: string;
  testId: string;
};

const walletIconProps = (type?: string): WalletIconProps => {
  const defaultImagePath = "/wallets/logos/default.svg";
  let imagePath: string;
  let alt = "";
  let sx: DeeplyNestedSx = {
    padding: "base.spacing.x2",
    width: "48px",
    height: "48px",
    background: "base.color.translucent.inverse.700",
  };

  const params = {
    testId: "undefined-wallet",
    sx,
    alt,
    defaultImagePath,
    imagePath: defaultImagePath,
  };

  if (!type) {
    return params;
  }

  alt = type;
  switch (type.toLocaleLowerCase()) {
    case WalletTypes.METAMASK:
      imagePath = "metamask.svg";
      break;
    case WalletTypes.OKX:
      imagePath = "okx.svg";
      sx = { ...sx, backgroundColor: "white" };
      break;
    case WalletTypes.COINBASE:
      imagePath = "coinbase.svg";
      break;
    case WalletTypes.WALLET_CONNECT:
      imagePath = "wallet_connect.svg";
      break;
    default:
      imagePath = "default.svg";
      break;
  }

  return Object.assign({}, params, {
    testId: `wallet-icon-${safeTestId(type)}`,
    defaultImagePath: "/wallets/logos/default.svg",
    imagePath: `/wallets/logos/${imagePath}`,
    alt,
    sx,
  });
};

export { walletIconProps };
