import { useTheme, useWindowSizeStore } from "@biom3/react";

import type { Breakpoints } from "@biom3/design-tokens";
import {
  MAX_CONTAINER_WIDTH,
  PADDING_X,
  TILE_DIMENSIONS,
  TILE_GAP,
} from "./constants";
import type { TileType } from "./types";

export function getContainerPaddingX(
  currentWidth: number,
  breakpoints: Breakpoints,
): number {
  if (currentWidth < breakpoints.medium) {
    // remove px from the string. so if the string is 10px, it will return 10
    return Number(PADDING_X.default.replace("px", ""));
  }
  if (currentWidth < breakpoints.large) {
    return Number(PADDING_X.medium.replace("px", ""));
  }
  if (currentWidth < breakpoints.xxLarge) {
    return Number(PADDING_X.large.replace("px", ""));
  }
  return Number(PADDING_X.xxLarge.replace("px", ""));
}

// returns the width of the gutter which is the space between the content width and the viewport width
// returns the width of only one side gutter
export function getGutterWidth(
  currentViewportWidth: number,
  windowWidth: number,
  breakpoints: Breakpoints,
) {
  // we use the window innerWidth that includes scrollbar width too in order to get the paddingX
  // since paddingX would be applied based on breakpoints which would account for scrollbar width
  const paddingX = getContainerPaddingX(windowWidth, breakpoints);
  // special case for >1300px and <1536px
  if (currentViewportWidth >= MAX_CONTAINER_WIDTH) {
    const totalMargin = currentViewportWidth - MAX_CONTAINER_WIDTH;
    return totalMargin / 2 + paddingX;
  }

  return paddingX;
}

function getTileGap(viewportWidth: number, breakpoints: Breakpoints): number {
  return viewportWidth <= breakpoints.large
    ? TILE_GAP.belowLarge
    : TILE_GAP.largeAndAbove;
}

function getDynamicEqualWidth(
  contentWidth: number,
  viewportWidth: number,
  breakpoints: Breakpoints,
  tilesInContentView: number,
): number {
  const totalGap =
    getTileGap(viewportWidth, breakpoints) * (tilesInContentView - 1);
  const totalWidth = contentWidth - totalGap;
  return totalWidth / tilesInContentView;
}

export function getTileDimensions(
  tileType: TileType,
  viewportWidth: number | null,
  breakpoints: Breakpoints,
): { width: string; tileGap: string } {
  if (!viewportWidth) {
    return {
      width: "100%",
      tileGap: "base.spacing.x2",
    };
  }

  const tileGap = `${getTileGap(viewportWidth, breakpoints)}px`;

  if (tileType === "default") {
    const tileDimensions = TILE_DIMENSIONS[tileType];
    return {
      width:
        viewportWidth <= breakpoints.large
          ? tileDimensions.widthTillLarge
          : "280px",
      tileGap,
    };
  }

  if (tileType === "featureQuest") {
    const tileDimensions = TILE_DIMENSIONS[tileType];
    return {
      width: tileDimensions.widthTillLarge,
      tileGap,
    };
  }

  const tileDimensions = TILE_DIMENSIONS[tileType];
  return {
    width:
      viewportWidth <= breakpoints.large
        ? tileDimensions.widthTillLarge
        : "430px",
    tileGap,
  };
}

export function useTileDimensions(tileType: TileType) {
  const { base } = useTheme();
  const { state: windowWidth } = useWindowSizeStore((store) => store.width);

  const { width, tileGap } = getTileDimensions(
    tileType,
    windowWidth,
    base.breakpoint,
  );
  return {
    tileWidth: width,
    tileGap,
  };
}
