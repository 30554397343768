import { LoadingOverlay } from "@biom3/react";

type Props = {
  loading: boolean;
  title: string;
};

export const LoadingModal = ({ loading, title }: Props) => (
  <LoadingOverlay visible={loading}>
    <LoadingOverlay.Content sx={{ alignItems: "center" }}>
      <LoadingOverlay.Content.LoopingText text={[title]} />
    </LoadingOverlay.Content>
  </LoadingOverlay>
);
