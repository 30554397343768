import { useConnect } from "wagmi";
import { injected, walletConnect } from "wagmi/connectors";

import { WalletTypes } from "@/types";

export const useValidConnectors = () => {
  const { connectors } = useConnect();

  const validConnectors = connectors
    .filter((connector) => {
      // Check if the connector is of type 'injected' and exclude certain types
      const isInjectedConnector =
        connector.type === injected.type &&
        connector.id !== WalletTypes.PASSPORT_RDNS &&
        connector.id !== WalletTypes.INJECTED;

      // Check if the connector is of type 'walletConnect'
      const isWalletConnectConnector = connector.type === walletConnect.type;

      // Return true if the connector is either a valid injected connector or a walletConnect connector
      return isInjectedConnector || isWalletConnectConnector;
    })
    // Sort connectors to prioritize injected wallets over walletConnect wallets
    .sort((a, b) => {
      if (a.type === injected.type && b.type !== injected.type) return -1;
      if (a.type !== injected.type && b.type === injected.type) return 1;
      return 0;
    });

  const onlyWalletConnectAvailable =
    validConnectors.length === 1 &&
    validConnectors[0].type === walletConnect.type;

  return { validConnectors, onlyWalletConnectAvailable };
};
