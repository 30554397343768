import {
  type DashboardAnalyticsJourneyType,
  type DashboardAnalyticsScreenType,
  useAnalytics,
  usePassportProvider,
} from "@/context";
import { notifyError } from "@/utils/monitoring";
import { useEffect, useState } from "react";

export type LoginState = "pending" | "loggingIn" | "ready" | "authenticated";

const USER_TRIGGERED_ERRORS = [
  "User did not authorize the request",
  "Popup closed by user",
];

const analyticsProps = {
  screen: "Login" as DashboardAnalyticsScreenType,
  userJourney: "Profile" as DashboardAnalyticsJourneyType,
};

export const usePassportLogin = () => {
  const { track } = useAnalytics();
  const [loggingIn, setLoggingIn] = useState(false);
  const { triggerLogin, passportState } = usePassportProvider();

  let state: LoginState = "pending";
  if (passportState.ready) state = "ready";
  if (loggingIn) state = "loggingIn";
  if (passportState.authenticated) state = "authenticated";

  useEffect(() => {
    if (state === "authenticated") {
      track({
        ...analyticsProps,
        control: "Screen",
        controlType: "Effect",
        action: "Succeeded",
      });
    }
  }, [state, track]);

  const login = async () => {
    try {
      track({
        ...analyticsProps,
        action: "Request",
        control: "Click",
        controlType: "Button",
      });
      track({ ...analyticsProps, action: "Started" });
      setLoggingIn(true);
      await triggerLogin();
    } catch (error) {
      track({
        ...analyticsProps,
        action: "Failed",
        control: "Click",
        controlType: "Button",
      });

      const err = error as Error;
      if (!err.message || !USER_TRIGGERED_ERRORS.includes(err.message)) {
        notifyError(error, "login");
      }
      track({ ...analyticsProps, action: "Failed" });
    } finally {
      setLoggingIn(false);
    }
  };

  return { loading: loggingIn, login };
};
